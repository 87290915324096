import { Dropdown } from 'antd';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactFlow, {
    Controls,
    MarkerType,
    ReactFlowProvider,
    addEdge,
    getConnectedEdges,
    getIncomers,
    getOutgoers,
    useEdgesState,
    useNodesInitialized,
    useNodesState,
    useReactFlow,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { Card, Container } from 'reactstrap';
import ViewDetailsModal from '../views';
import Breadcrumbs from '../../../components/breadcrumb/Breadcrumb';
import { showError } from '../../../helpers/common';
import { getAllDatasources } from '../../../plugins/datasource/slices/datasource';
import { processFlow } from '../../../services';
import { getAllRoles } from '../../../store/slices/role';
import { DEFAULT_LEFT, DEFAULT_NODES, DEFAULT_TOP, PRO_OPTIONS, SCROLL_DEFAULT_PADDING } from './constant';
import { edgeTypes } from './edges';
import useAutoLayout from './hooks/useAutoLayout';
import { nodeTypes } from './nodes';
import PublishOptions from './publish';
import Sidebar from './sidebar';
import {
    fetchProcessById,
    reset,
    resetAttributes,
    saveFlow,
    setRfInstance,
    setSelectedEdge,
    setSelectedNode,
} from './slices/process-details';
import './styles.css';
import { AttributeDetailsModal } from '../attributes';

const defaultEdgeOptions = {
    type: 'workflow',
    markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 30,
        height: 30,
    },
    pathOptions: { offset: 5 },
};

export const addNodeToParent = (n, parentNode, nodePosition) => {
    n.parentNode = parentNode.id;
    // n.position = nodePosition;
    return n;
};

const moveNodeToOldPosition = (n, selectedNode) => {
    if (!selectedNode) {
        n.position = { x: 0, y: 0 };
        return n;
    }
    n.position = selectedNode.position;
    return n;
};

const ProcessDetails = (props) => {
    document.title = ' Process Details';

    const { t } = useTranslation();
    const location = useLocation();
    const processId = location?.state?.processId;
    // let { id } = useParams();
    const [nodes, setNodes, onNodesChange] = useNodesState(DEFAULT_NODES);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [attributeDetailsModal, setAttributeDetailsModal] = useState(false);
    const [viewDetailsModal, setViewDetailsModal] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        process: processDetail,
        selectedNode,
        isInteractionDisabled,
    } = useSelector((state) => state.processDetails);

    useEffect(() => {
        dispatch(fetchProcessById(processId));
        dispatch(getAllDatasources());
        dispatch(getAllRoles());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, processId]);

    useEffect(() => {
        return () => dispatch(reset());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, props.nextPage]);

    const nodesInitialized = useNodesInitialized();
    const { fitView, getIntersectingNodes, screenToFlowPosition } = useReactFlow();
    const draggedNodeRef = useRef(null);

    useAutoLayout();

    const onConnect = useCallback(
        (params) => {
            setEdges((eds) => addEdge({ ...params }, eds));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [setEdges]
    );

    const updateEdgeData = useCallback(
        (edge, isHovered) => {
            const edgeId = edge.id;
            // Updates edge
            setEdges((prevElements) =>
                prevElements.map((element) =>
                    element.id === edgeId
                        ? {
                              ...element,
                              data: {
                                  ...element.data,
                                  isHovered,
                              },
                          }
                        : element
                )
            );
        },
        [setEdges]
    );

    const onEdgeMouseEnter = useCallback(
        (event, edge) => {
            updateEdgeData(edge, true);
        },
        [updateEdgeData]
    );

    const onEdgeMouseLeave = useCallback(
        (event, edge) => {
            updateEdgeData(edge, false);
        },
        [updateEdgeData]
    );

    const onNodesDelete = useCallback(
        (deleted) => {
            setEdges(
                deleted.reduce((acc, node) => {
                    const incomers = getIncomers(node, nodes, edges);
                    const outgoers = getOutgoers(node, nodes, edges);
                    const connectedEdges = getConnectedEdges([node], edges);

                    const remainingEdges = acc.filter((edge) => !connectedEdges.includes(edge));

                    const createdEdges = incomers.flatMap(({ id: source }) =>
                        outgoers.map(({ id: target }) => ({ id: `${source}->${target}`, source, target }))
                    );

                    return [...remainingEdges, ...createdEdges];
                }, edges)
            );
            setTimeout(() => {
                dispatch(saveFlow());
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [nodes, edges]
    );

    const fetchProcessFlow = async (id) => {
        try {
            const res = await processFlow.getProcessFlow(id);
            const { nodes: processNodes, edges: processEdges } = res?.data || { nodes: [], edges: [] };
            setNodes(processNodes?.length ? processNodes : DEFAULT_NODES);
            setEdges(processEdges);
        } catch (err) {
            showError(err?.message);
        }
    };

    useEffect(() => {
        if (processId) {
            var body = document.body;
            body.setAttribute('data-sidebar-size', 'sm');
            fetchProcessFlow(processId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [processId]);

    const onNodeClick = useCallback(
        (event, node) => {
            dispatch(setSelectedNode(node));
        },
        [dispatch]
    );

    const onPaneClick = useCallback(() => {
        dispatch(setSelectedNode(null));
        dispatch(setSelectedEdge(null));
    }, [dispatch]);

    const onInit = useCallback(
        (instance) => {
            dispatch(setRfInstance(instance));
        },
        [dispatch]
    );

    useEffect(() => {
        if (nodesInitialized) {
            fitView({
                duration: 400,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nodesInitialized]);

    const handleCancel = () => {
        setAttributeDetailsModal(false);
        dispatch(resetAttributes());
    };

    useEffect(() => {
        if (selectedNode) {
            setTimeout(() => {
                fitView({
                    nodes: [{ id: selectedNode.id }],
                    maxZoom: 0.8,
                    duration: 400,
                });
            }, 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNode]);

    const onNodeDragStart = useCallback((_, node) => {
        draggedNodeRef.current = node;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNodeDrag = useCallback(
        (_, node) => {
            const intersections = getIntersectingNodes(node, false)
                .filter((n) => n.type === 'lane')
                .map((n) => n.id);

            setNodes((ns) =>
                ns.map((n) => ({
                    ...n,
                    className: intersections.includes(n.id) ? 'highlight' : '',
                }))
            );
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setNodes]
    );

    const onDragStop = useCallback(
        (event, node) => {
            const intersections = getIntersectingNodes(node, false).filter((n) => n.type === 'lane');
            const droppedNode = intersections?.length ? intersections[0] : '';

            setNodes((ns) =>
                ns.map((n) => {
                    delete n.className;
                    if (!droppedNode && n.id === node.id) {
                        const update = moveNodeToOldPosition(n, draggedNodeRef.current);
                        update.draggingOver = true;
                        draggedNodeRef.current = null;
                        return { ...update };
                    }
                    if (droppedNode && n.id === node.id && n.parentNode !== droppedNode.id) {
                        const position = screenToFlowPosition({
                            x: event.clientX,
                            y: event.clientY,
                        });
                        const nodePosition = {
                            x: position.x - droppedNode.position.x - 50,
                            y: position.y - droppedNode.position.y - 50,
                        };
                        const update = addNodeToParent(n, droppedNode, nodePosition);
                        update.draggingOver = true;
                        return { ...update };
                    }
                    return { ...n };
                })
            );

            setTimeout(() => {
                dispatch(saveFlow());
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setNodes]
    );

    const translateExtent = useMemo(() => {
        const chartContainer = document.querySelector('.react-flow');
        const chartRect = chartContainer?.getBoundingClientRect();

        if (!nodes?.length)
            return [
                [DEFAULT_TOP, DEFAULT_LEFT],
                [chartRect.width, chartRect.height],
            ];
        // TODO: since in one corner case few elemets are going outside pool so we are considering all the
        // elements but in ideal case we should just conside the pools here.
        // const pools = nodes.filter((node) => node.type === 'pool');
        const pools = nodes;

        const minX = Math.min(...pools.map((node) => node.position.x));
        const minY = Math.min(...pools.map((node) => node.position.y));

        let maxX = null;
        let maxY = null;

        if (pools?.length) {
            maxX = Math.max(...pools.map((node) => node.position.x + (node.style.width || 0)));
            maxY = Math.max(...pools.map((node) => node.position.y + (node.style.height || 0)));
        }

        maxX = isNaN(maxX) ? chartRect.width : maxX;
        maxY = isNaN(maxY) ? chartRect.height : maxY;

        return [
            [minX - SCROLL_DEFAULT_PADDING, minY - SCROLL_DEFAULT_PADDING],
            [maxX + SCROLL_DEFAULT_PADDING, maxY + SCROLL_DEFAULT_PADDING],
        ];
    }, [nodes]);

    const items = [
        {
            label: 'Views',
            key: 'views',
        },
    ];

    const menuItems = {
        items,
        onClick: () => {
            setViewDetailsModal(true);
        },
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title={`Process: ${processDetail.name || ''}`}
                        breadcrumbItem={`Process: ${processDetail.name || ''}`}
                    >
                        <PublishOptions />
                        <Dropdown.Button
                            style={{ marginLeft: 5, marginRight: 5, width: 'auto' }}
                            onClick={() => setAttributeDetailsModal(true)}
                            menu={menuItems}
                        >
                            {t('action.attributes')}
                        </Dropdown.Button>
                    </Breadcrumbs>
                    <Card>
                        <div className="dnd-flow" style={{ height: 'calc(100vh - 220px)', width: '100%' }}>
                            <ReactFlow
                                nodes={nodes}
                                edges={edges}
                                proOptions={PRO_OPTIONS}
                                nodeTypes={nodeTypes}
                                edgeTypes={edgeTypes}
                                onInit={onInit}
                                onNodesChange={onNodesChange}
                                onEdgesChange={onEdgesChange}
                                defaultEdgeOptions={defaultEdgeOptions}
                                onConnect={!isInteractionDisabled ? onConnect : () => false}
                                onEdgeMouseEnter={!isInteractionDisabled ? onEdgeMouseEnter : () => false}
                                onEdgeMouseLeave={!isInteractionDisabled ? onEdgeMouseLeave : () => false}
                                onNodesDelete={onNodesDelete}
                                deleteKeyCode={null}
                                onNodeClick={onNodeClick}
                                onPaneClick={onPaneClick}
                                nodeDragThreshold={1}
                                onNodeDrag={onNodeDrag}
                                onNodeDragStart={onNodeDragStart}
                                onNodeDragStop={onDragStop}
                                fitView
                                minZoom={0}
                                translateExtent={translateExtent}
                                // disable graph in STAGING/LIVE
                                edgesUpdatable={!isInteractionDisabled}
                                edgesFocusable={!isInteractionDisabled}
                                nodesDraggable={!isInteractionDisabled}
                                nodesConnectable={!isInteractionDisabled}
                                nodesFocusable={!isInteractionDisabled}
                            >
                                <Controls />
                            </ReactFlow>
                            <Sidebar />
                        </div>
                    </Card>
                </Container>
            </div>
            <AttributeDetailsModal showModal={attributeDetailsModal} handleCancel={() => handleCancel()} />
            {viewDetailsModal && (
                <ViewDetailsModal
                    showModal={viewDetailsModal}
                    handleCancel={() => {
                        setViewDetailsModal(false);
                    }}
                />
            )}
        </React.Fragment>
    );
};

const ProcessDetailsWrapper = () => {
    return (
        <ReactFlowProvider>
            <ProcessDetails />
        </ReactFlowProvider>
    );
};

export default ProcessDetailsWrapper;
