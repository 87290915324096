import { PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Select, Table } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const { Column, ColumnGroup } = Table;

export const AttributeList = ({ form }) => {
    const { t } = useTranslation();
    const { attributes } = useSelector((state) => state.processDetails);
    const [attributeOptions, setAttributeOptions] = useState([]);

    useEffect(() => {
        const parsedOptions = attributes?.reduce((acc, obj) => {
            const groupLabel = obj['attributeGroupName'] || 'Others';
            const optionGroup = acc.find((o) => o.label === groupLabel);
            const option = {
                label: obj['name'],
                value: obj['id'],
            };
            if (!optionGroup) {
                acc.push({
                    label: groupLabel,
                    options: [option],
                });
            } else {
                optionGroup.options.push(option);
            }
            return acc;
        }, []);
        parsedOptions.push(
            ...parsedOptions.splice(
                parsedOptions.findIndex((v) => v.label === 'Others'),
                1
            )
        );
        setAttributeOptions(parsedOptions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attributes]);

    return (
        <Form.List name="views_attributes">
            {(fields, { add, remove }) => (
                <>
                    <Table
                        dataSource={form?.getFieldValue('views_attributes')}
                        rowKey="key"
                        className="table-top-align"
                        bordered
                    >
                        <Column
                            key="attribute"
                            title="Attribute"
                            render={(text, record, index) => (
                                <Form.Item
                                    name={[index, 'attributeId']}
                                    fieldKey={[index, 'attributeId']}
                                    rules={[
                                        { required: true, message: t('views.attribute-required') },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (
                                                    !value ||
                                                    getFieldValue('views_attributes').filter(
                                                        (d) => d.attributeId === value
                                                    ).length === 1
                                                ) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('Duplicate values are not allowed.'));
                                            },
                                        }),
                                    ]}
                                >
                                    <Select style={{ width: 200 }} options={attributeOptions} />
                                </Form.Item>
                            )}
                        />
                        <ColumnGroup title="Visibility">
                            <Column
                                title="Desktop"
                                dataIndex="isVisibleDesktop"
                                key="isVisibleDesktop"
                                render={(text, record, index) => (
                                    <Form.Item name={[index, 'isVisibleDesktop']} valuePropName="checked">
                                        <Checkbox />
                                    </Form.Item>
                                )}
                            />
                            <Column
                                title="Mobile"
                                dataIndex="isVisibleMobile"
                                key="isVisibleMobile"
                                render={(text, record, index) => (
                                    <Form.Item name={[index, 'isVisibleMobile']} valuePropName="checked">
                                        <Checkbox />
                                    </Form.Item>
                                )}
                            />
                            <Column
                                title="Sticky in Group"
                                dataIndex="isStickyInGroup"
                                key="isStickyInGroup"
                                render={(text, record, index) => (
                                    <Form.Item name={[index, 'isStickyInGroup']} valuePropName="checked">
                                        <Checkbox />
                                    </Form.Item>
                                )}
                            />
                        </ColumnGroup>
                        <Column
                            render={(text, record, index) => {
                                if (fields.length > 1) {
                                    return (
                                        <Form.Item>
                                            <i
                                                className="fas fa-trash fa-danger"
                                                id="deletetooltip"
                                                title="Delete"
                                                onClick={() => {
                                                    remove(index);
                                                    form.setFieldsValue({
                                                        ...form.getFieldsValue(),
                                                        addresses: form
                                                            .getFieldValue('views_attributes')
                                                            .map((record, index) => {
                                                                return {
                                                                    ...record,
                                                                    key: index + 1,
                                                                };
                                                            }),
                                                    });
                                                }}
                                            />
                                        </Form.Item>
                                    );
                                } else return null;
                            }}
                        />
                    </Table>
                    <Form.Item>
                        <Button
                            type="dashed"
                            onClick={() => {
                                add({
                                    key: form?.getFieldValue('views_attributes').length + 1,
                                });
                            }}
                            style={{ width: '100%' }}
                        >
                            <PlusOutlined />
                            Add
                        </Button>
                    </Form.Item>
                </>
            )}
        </Form.List>
    );
};
