import { useState } from 'react';
import { Checkbox } from 'antd';

export const CheckboxField = ({ fieldInfo, form, initialValue }) => {
    const [value, setValue] = useState(initialValue || false);

    const handleChange = (e) => {
        setValue(e.target.checked);

        // If condition denotes that it is used for handling action sub componenet in node form
        if (fieldInfo?.actionKey) {
            form.setFieldValue(['actions', fieldInfo?.actionKey, fieldInfo?.name], e.target.checked);
            form.validateFields([['actions', fieldInfo?.actionKey, fieldInfo?.name]]);
        } else if (fieldInfo?.visibleKey) {
            form.setFieldValue(['visibility', fieldInfo?.visibleKey, fieldInfo?.name], e.target.checked);
            form.validateFields([['visibility', fieldInfo?.visibleKey, fieldInfo?.name]]);
        } else {
            form.setFieldValue(fieldInfo?.name, e.target.checked);
            form.validateFields([fieldInfo?.name]);
        }
    }

    return <Checkbox checked={value} onChange={handleChange} disabled={fieldInfo?.disabled} />;
}