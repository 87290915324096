import { useDispatch } from 'react-redux';
import { useReactFlow, useStoreApi } from 'reactflow';
import { DEFAULT_LANE_HEIGHT, DEFAULT_LANE_WIDTH, getDefaultPoolAndLane } from '../constant';
import { saveFlow } from '../slices/process-details';
import { fixPoolPosition } from '../utils';

function useAddLane(id) {
    const { setNodes, getNode, getNodes } = useReactFlow();
    const store = useStoreApi();
    const dispatch = useDispatch();

    const onClick = () => {
        // we need the parent node object for getting its position
        const parentNode = getNode(id);

        if (!parentNode) {
            return;
        }

        const lastLane = Array.from(store.getState().nodeInternals.values())
            .filter((n) => n.parentNode === id)
            .pop();

        let position = { x: 50, y: 0 };
        let width = DEFAULT_LANE_WIDTH;
        if (lastLane) {
            position = { x: lastLane.position.x, y: lastLane.position.y + DEFAULT_LANE_HEIGHT };
            width = lastLane.style.width;
        }

        const { poolAndLane } = getDefaultPoolAndLane();
        const newLane = poolAndLane[1];
        newLane.parentNode = id;
        newLane.position = position;
        newLane.style.width = width;

        let nodes = getNodes();
        const node = nodes.find((n) => n.id === id);
        node.style = {
            ...node.style,
            height: node.style.height + DEFAULT_LANE_HEIGHT,
        };
        nodes = nodes.concat(newLane)
        fixPoolPosition(nodes);
        setNodes(nodes);
        setTimeout(() => {
            dispatch(saveFlow());
        });
    };
    return onClick;
}

export default useAddLane;
