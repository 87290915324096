import { useReactFlow, useStoreApi } from 'reactflow';
import { v4 as uuidv4 } from 'uuid';
import { nodeClassMapping } from '../plugins';
import { useDispatch } from 'react-redux';
import { saveFlow, setSelectedNode } from '../slices/process-details';

export function useNodeClick(id, newNodeNodeType, callback = () => false) {
    const { getNode, setNodes, setEdges } = useReactFlow();
    const store = useStoreApi();
    const dispatch = useDispatch();

    const onClick = () => {
        // we need the parent node object for getting its position
        const parentNode = getNode(id);

        if (!parentNode) {
            return;
        }

        const newNodeId = uuidv4();

        // populate default edge data this is useful for edges going out of gateway nodes
        const { nodeType } = parentNode?.data;
        let defaultEdgeData = {};
        if (nodeClassMapping[nodeType]) {
            const classMapping = new nodeClassMapping[nodeType]();
            defaultEdgeData = classMapping.getDefaultEdgeData();
        }

        let defaultNodeData = {};
        if (nodeClassMapping[newNodeNodeType]) {
            const classMapping = new nodeClassMapping[newNodeNodeType]();
            defaultNodeData = classMapping.getDefaultNodeData();
        }

        const newNode = {
            id: newNodeId,
            data: { nodeType: newNodeNodeType, ...defaultNodeData },
            style: { minWidth: 80, minHeight: 80 },
            position: { x: 0, y: 0 },
            selected: true,
            type: defaultNodeData.type,
            parentNode: parentNode.parentNode
        };

        const newEdge = {
            id: `${parentNode.id}=>${newNodeId}`,
            source: parentNode.id,
            target: newNodeId,
            data: { ...defaultEdgeData },
            type: 'workflow',
        };

        setNodes((nds) => nds.concat(newNode));
        setEdges((eds) => eds.concat(newEdge));

        setTimeout(() => {
            const { addSelectedNodes } = store.getState();
            addSelectedNodes([newNodeId]);
            dispatch(setSelectedNode(newNode));
            dispatch(saveFlow());
        });
        callback();
    };

    return onClick;
}
