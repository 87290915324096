import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import Breadcrumbs from '../../components/breadcrumb/Breadcrumb';
import { Card, Collapse, Select, Table, theme } from 'antd';
import { PROCESS_ENVIRONMENTS } from '../../constants/constants';
import { myOperations } from '../../services';
import { showError } from '../../helpers/common';
import './style.css';

const getProcessEnvironments = (t) => {
    const environmentOptions = [];
    for (const [key, value] of Object.entries(PROCESS_ENVIRONMENTS)) {
        environmentOptions.push({ value: key, label: t(`process.${value}`) });
    }
    return environmentOptions;
};

const columns = [
    {
        title: 'Instance',
        dataIndex: 'instance',
        key: 'instance',
        render: (value, row, index) => {
            const obj = {
                children: value,
                props: {
                    colSpan: 1,
                    rowSpan: row.rowSpan,
                },
            };
            return obj;
        },
    },
    {
        title: 'Node',
        dataIndex: 'node',
        key: 'node',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
    },
    {
        title: 'Responsible',
        dataIndex: 'responsible',
        key: 'responsible',
    },
];

const getItems = (myOperations = [], panelStyle = {}) => {
    return myOperations.map((op) => ({
        key: op.id,
        label: op.name,
        children: <Table dataSource={op.tasks} columns={columns} bordered />,
        style: panelStyle,
    }));
};

const MyOperations = () => {
    const { t } = useTranslation();
    const { token } = theme.useToken();
    document.title = t('my-operations.title');
    const [environment, setEnvironment] = useState(PROCESS_ENVIRONMENTS.DESIGNER);
    const [myTasksList, setMyTasksList] = useState([]);

    const environmentOptions = getProcessEnvironments(t);

    const getMyOperations = async () => {
        try {
            const res = await myOperations.getMyOperations(environment);
            setMyTasksList(res?.data);
        } catch (err) {
            showError(err?.message);
        }
    };

    useEffect(() => {
        getMyOperations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [environment]);

    const onChange = (key) => {
        console.log(key);
    };

    const panelStyle = {
        marginBottom: 24,
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        border: '1px solid #d9d9d9',
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={t('my-operations.title')} breadcrumbItem={t('my-operations.title')}>
                        <Select
                            value={environment}
                            style={{ width: 120 }}
                            options={environmentOptions}
                            onChange={(value) => setEnvironment(value)}
                        />
                    </Breadcrumbs>
                </Container>
                <Card style={{ height: 'calc(100vh - 220px)', overflowY: 'auto' }} className="my-operations-container">
                    {myTasksList?.length > 0 ? (
                        <Collapse
                            items={getItems(myTasksList, panelStyle)}
                            onChange={onChange}
                            bordered={false}
                            style={{ background: token.colorBgContainer }}
                        />
                    ) : (
                        <>No operation(s)</>
                    )}
                </Card>
            </div>
        </React.Fragment>
    );
};

export default MyOperations;
