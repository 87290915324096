import React from 'react';
import { Form } from 'antd';
import { SelectField } from '../select/index';

const OrField = ({ fieldInfo, form, initialValues }) => {
    const getField = (fInfo) => {
        if (fInfo?.type === 'select') {
            return (
                <SelectField
                    orFieldsInfo={fieldInfo}
                    fieldInfo={fInfo}
                    form={form}
                    initialValue={initialValues?.[fInfo?.name]}
                />
            );
        }
    };

    const getFieldRules = (field) => {
        return [
            ...(field?.rules || []),
            {
                message: `${fieldInfo.fields.map((f) => f.label).join(' | ')} is required!`,
                validator: (_, value) => {
                    const values = form.getFieldsValue();
                    const valuePresent = fieldInfo.fields.some((field) => {
                        const val = values[field.name];
                        if (Array.isArray(val)) {
                            return val.length ? true : false;
                        }
                        return val;
                    });
                    return valuePresent ? Promise.resolve() : Promise.reject('error here');
                },
            },
        ];
    };

    return fieldInfo?.fields?.map((field) => {
        return (
            <>
                <Form.Item
                    key={field?.name}
                    name={field?.name}
                    label={field?.label}
                    messageVariables={{ label: field?.label }}
                    rules={getFieldRules()}
                >
                    {getField(field)}
                </Form.Item>
            </>
        );
    });
};

export default OrField;
