import BaseNode from "../BaseNode";
import { gatewayResourceLoaders } from "../utils/GenericFunctions";

class ComplexNode extends BaseNode {
    constructor() {
        super();
        this.displayName = 'Complex Gateway';
        this.description = '';
        this.name = 'complex-gateway';
        this.icon = '';
        this.color = '#ADAD30';
        this.type = 'shape';
        this.nodeShape = 'diamond';
        this.methods = {
            loadOptions: gatewayResourceLoaders,
        };
    }

    getDefaultEdgeData() {
        return {
            edgeCondition: {
                condition: 'condition',
                operator: '',
                attribute: '',
                value: '',
            }
        }
    }

    getNodeAttributes() {
        const defaultAttributes = this.getDefaultAttributes();
        return [
            ...defaultAttributes,
            {
                displayName: 'Attribute',
                name: 'attribute',
                description: 'Attribute',
                type: 'select',
                default: '',
                required: true,
                groupOptions: true, // add this key if we want to group the options
                groupOptionsKey: 'attributeGroupName', // key to extract the group name
                ungroupedOptionsKey: 'Others', // group name for the data which doesn't have any value for groupOptionsKey 
                
                // if options are fetched from an external call then pass the below key
                // and define the method 'loadOptionsMethod' under methods.loadOptions
                typeOptions: {
                    loadOptionsMethod: 'getAttributes',
                    // loadOptionsDependsOn: ['tagId'],
                },
            },
        ];
    }

    getDefaultNodeData() {
        return {
            name: this.displayName,
            type: this.type,
        }
    }
};

export { ComplexNode as default };