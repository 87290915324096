import { getIncomers, getOutgoers } from 'reactflow';
import store from '../../../../../store';
import { NODE_TYPES } from '../constant';

export const getPreviousNodes = (nodeId) => {
    const state = store.getState();
    const { processDetails } = state;
    const { rfInstance } = processDetails;
    const node = rfInstance.getNode(nodeId);
    const nodes = rfInstance.getNodes();
    const edges = rfInstance.getEdges();
    if (node) {
        return getIncomers(node, nodes, edges);
    }
    return [];
};

export const getNextNodes = (nodeId) => {
    const state = store.getState();
    const { processDetails } = state;
    const { rfInstance } = processDetails;
    if (rfInstance) {
        const node = rfInstance.getNode(nodeId);
        const nodes = rfInstance.getNodes();
        const edges = rfInstance.getEdges();
        if (node) {
            return getOutgoers(node, nodes, edges);
        }
    }
    return [];
};

export const isPrevNodeStartNode = (nodeId) => {
    const prevNodes = getPreviousNodes(nodeId);
    return prevNodes.find(node => node.data.nodeType === NODE_TYPES.START_NODE) ? true : false;
};

export const gatewayResourceLoaders = {
    getAttributes() {
        const state = store.getState();
        const { processDetails } = state;
        return processDetails?.attributes || [];
    },
};

export const eventResourceLoaders = {
    getForms() {
        const state = store.getState();
        const { processDetails } = state;
        return processDetails?.forms || [];
    },
    getRoles() {
        const state = store.getState();
        const { role } = state;
        return role?.roles || [];
    },
};

export const formEditingResourceLoaders = {};

export const conditionalRenderingLoaders = {
    isCancelBehaviourVisible(id) {
        return isPrevNodeStartNode(id);
    },
};
