import ActivityNode from '../../ActivityNode';
import { DashboardVisibility } from '../../components/DashboardVisiblity';
import { FormSelector } from '../../components/FormSelector';
import { conditionalRenderingLoaders, formEditingResourceLoaders } from '../../utils/GenericFunctions';
import { CancelBehaviour } from './CancelBehaviour';
import { ShowForm } from './ShowForm';
import { SHOW_FORM_OPTIONS } from './constant';

class FormEditingNode extends ActivityNode {
    constructor() {
        super();
        this.displayName = 'Form Editing';
        this.description = '';
        this.name = 'form-editing';
        this.icon = 'fab fa-wpforms';
        this.type = 'activity';
        this.nodeType = 'form-editing';
        this.methods = {
            loadOptions: {...formEditingResourceLoaders, ...this.methods.loadOptions},
            renderingOption: conditionalRenderingLoaders
        };
    }

    getNodeAttributes() {
        const defaultAttributes = this.getDefaultAttributes();
        return [
            ...defaultAttributes,
            {
                displayName: 'node-details.form',
                name: 'form',
                description: 'node-details.form',
                type: 'custom-component',
                required: true,
                component: <FormSelector />,
                section: '',
                tab: ''
            },
            {
                displayName: 'node-details.dashboard-settings',
                name: 'dashboard-settings',
                description: 'node-details.dashboard-settings',
                type: 'custom-component',
                component: <DashboardVisibility />
            },
            {
                displayName: 'node-details.cancel-behaviour',
                name: 'cancel-behaviour',
                description: 'node-details.cancel-behaviour',
                type: 'custom-component',
                component: <CancelBehaviour />,
                isRenderedConditionally: true,
                conditionalRenderingMethod: 'isCancelBehaviourVisible',
            },
            {
                displayName: 'node-details.show-form',
                name: 'show-form',
                description: 'node-details.show-form',
                type: 'custom-component',
                required: true,
                component: <ShowForm />,
            },
        ];
    }

    getDefaultNodeData() {
        return {
            name: this.displayName,
            type: this.type,
            'dashboard-settings': {
                showOnDashboard: true
            },
            'show-form': {
                mode: SHOW_FORM_OPTIONS.TASK_LIST
            }
        }
    }
}

export { FormEditingNode as default };
