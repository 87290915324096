class BaseNode {
    displayName = '';
    description = '';
    name = '';
    color = '#EA9C41';
    icon = null;
    type = '';
    methods = {};
    hideSourceHandle = false;
    hideTargetHandle = false;
    nodeShape = '';

    // eslint-disable-next-line no-useless-constructor
    constructor() {}

    getDefaultEdgeData() {
        return {};
    }

    getDefaultAttributes() {
        return [
            {
                displayName: 'node-details.name',
                name: 'name',
                description: 'node-details.name',
                type: 'text',
                default: '',
                required: true,
                section: '', // use translation keys here
                tab: '' // use translation keys here
            },
        ];
    }

    getNodeAttributes() {
        return this.getDefaultAttributes();
    }

    getDefaultNodeData() {
        return {
            name: '',
            type: 'activity',
        }
    }
}

export { BaseNode as default };
