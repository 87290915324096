import { Button } from 'antd';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BaseEdge, EdgeLabelRenderer, Position, getSmoothStepPath, useReactFlow } from 'reactflow';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import useEdgeClick from '../../hooks/useEdgeClick';
import ProcessStepToolbar from '../../nodes/toolbar/nodes';
import { saveFlow } from '../../slices/process-details';
import { extractOperatorAndValue } from '../../utils';
import { ConditionModal } from '../condition-modal/ConditionModal';

function EdgeLabel(props) {
    return (
        <div
            style={{
                position: 'absolute',
                background: 'transparent',
                color: 'black',
                fontSize: 12,
                fontWeight: 700,
                pointerEvents: 'all',
                padding: 10,
                ...props.styles,
            }}
            className="nodrag nopan edge-label workflowEdge"
        >
            {props.children}
        </div>
    );
}

export const WorkflowEdge = ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    data,
    markerEnd,
}) => {
    const { getEdge, getNode, setEdges } = useReactFlow();
    const [edgeLabel, setEdgeLabel] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
    const [showConditionModal, setShowConditionModal] = useState(false);
    const [gatewayAttribute, setGatewayAttribute] = useState(null);
    const [gatewayAttributeObj, setGatewayAttributeObj] = useState(null);

    const { attributes, isInteractionDisabled } = useSelector((state) => state.processDetails);
    const dispatch = useDispatch();
    const edgeCondition = data?.edgeCondition;

    const [edgePath, labelX, labelY] = getSmoothStepPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    const getEdgeCondition = (edgeCndtn = edgeCondition, gatewayAttrObj = gatewayAttributeObj) => {
        let edgeLabel = '';
        const {
            condition,
            operator,
            attribute: edgeAttribute,
            value,
        } = edgeCndtn || { condition: '', operator: '', attribute: '', value: '' };
        if (condition === 'default') {
            edgeLabel = 'Default';
        } else {
            edgeLabel = `${operator}`;
            if (edgeAttribute) {
                const attribute = attributes?.find((a) => a.id === edgeAttribute);
                if (attribute) {
                    edgeLabel += `${attribute?.name}`;
                }
            }
            if (!['select', 'checkbox'].includes(gatewayAttrObj?.type)) {
                if (value) {
                    edgeLabel += `${value}`;
                }
            } else {
                if (value) {
                    edgeLabel += Array.isArray(value) ? `\n${value.join(', ')}` : `\n${value.split().join(',')}`;
                }
            }
        }

        setEdgeLabel(edgeLabel);
    };

    useEffect(() => {
        const edge = getEdge(id);
        const source = edge?.source;
        let node = null;
        let gatewayAttr = null;
        let gatewayAttrObj = null;
        if (source) {
            node = getNode(source);
            gatewayAttr = node?.data?.attribute;
            gatewayAttrObj = attributes?.find((a) => a.id === gatewayAttr);
            setGatewayAttribute(gatewayAttr);
            setGatewayAttributeObj(gatewayAttrObj);
        }
        if (edgeCondition) {
            getEdgeCondition(edgeCondition, gatewayAttrObj);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, attributes]);

    const updateEdgeData = useCallback(
        (updatedData) => {
            setEdges((prevElements) =>
                prevElements.map((element) =>
                    element.id === id
                        ? {
                              ...element,
                              data: {
                                  ...element.data,
                                  ...updatedData,
                              },
                          }
                        : element
                )
            );
            setTimeout(() => {
                dispatch(saveFlow());
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [setEdges]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const deboundEdgeDataUpdate = useCallback(
        debounce((data) => {
            updateEdgeData({ edgeCondition: data });
        }, 1000),
        []
    );

    const updateEdgeCondition = useCallback((conditionText) => {
        if (edgeCondition) {
            edgeCondition.attribute = null;
            let { operator, value } = extractOperatorAndValue({ operator: '', value: conditionText });
            if (value && !operator) {
                operator = '=';
                edgeCondition.operator = '=';
            } else {
                edgeCondition.operator = operator;
            }
            edgeCondition.value = value.trim();

            let label = `${operator}${value}`;
            setEdgeLabel(label);
            deboundEdgeDataUpdate(edgeCondition);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOk = (data) => {
        const updatedData = {
            edgeCondition: data,
        };
        getEdgeCondition(data);
        updateEdgeData({ ...updatedData });
        setShowConditionModal(!showConditionModal);
    };

    const onEdgeClick = () => {
        setShowConditionModal(true);
    };

    const getCondition = () => {
        if (!['select', 'checkbox'].includes(gatewayAttributeObj?.type)) {
            return (
                <>
                    <input
                        type="text"
                        className="node-label"
                        placeholder="Condition??"
                        value={edgeLabel}
                        onChange={(e) => updateEdgeCondition(e.target.value)}
                        style={{ maxWidth: '70px' }}
                        disabled={isInteractionDisabled}
                    />
                    <i
                        className={`fas fa-cog ${isInteractionDisabled ? 'fa-disabled' : ''} mx-2`}
                        onClick={onEdgeClick}
                    ></i>
                </>
            );
        }
        return (
            <>
                {edgeLabel ? (
                    <ReactMarkdown
                        children={edgeLabel.replace(/\n/gi, '\n &nbsp; \n')}
                        remarkPlugins={[remarkBreaks]}
                    ></ReactMarkdown>
                ) : (
                    <div onClick={onEdgeClick}>Click to add condition</div>
                )}
                <i
                    className={`fas fa-cog ${isInteractionDisabled ? 'fa-disabled' : ''} mx-2`}
                    onClick={onEdgeClick}
                ></i>
            </>
        );
    };

    return (
        <>
            <BaseEdge id={id} path={edgePath} markerEnd={markerEnd} />
            <EdgeLabelRenderer>
                {edgeCondition && (
                    <EdgeLabel
                        styles={{
                            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                            padding: '5px',
                            background: '#ffcc00',
                            borderRadius: 5,
                            zIndex: 12,
                        }}
                    >
                        <div className="d-flex edge-label-container">{getCondition()}</div>
                    </EdgeLabel>
                )}
                {!isInteractionDisabled && data?.isHovered && (
                    <EdgeLabel styles={{ transform: `translate(-100%, -50%) translate(${targetX}px,${targetY}px)` }}>
                        <Button
                            shape="circle"
                            type="primary"
                            size="small"
                            style={{ zIndex: 14 }}
                            onClick={() => setIsVisible(!isVisible)}
                            icon={<i className="fas fa-plus" style={{ padding: 0 }}></i>}
                        ></Button>
                    </EdgeLabel>
                )}
                <ProcessStepToolbar
                    edgeId={id}
                    isVisible={!isInteractionDisabled && isVisible}
                    hideToolbar={() => setIsVisible(!isVisible)}
                    position={Position.Top}
                    onClickHandler={useEdgeClick}
                />
            </EdgeLabelRenderer>
            {showConditionModal && (
                <ConditionModal
                    showModal={showConditionModal}
                    edgeData={edgeCondition}
                    handleOk={handleOk}
                    handleCancel={() => setShowConditionModal(!showConditionModal)}
                    gatewayAttribute={gatewayAttribute}
                />
            )}
        </>
    );
};
