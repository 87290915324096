import request from './request';

const getAllTask = (rfNodeId, processId) => request.post(`/tasks/${processId}/all`, { rfNodeId });
const getLastTask = (rfNodeId, processId) => request.get(`/tasks/${rfNodeId}/${processId}/last`);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getAllTask,
    getLastTask,
};
