/* eslint-disable import/no-anonymous-default-export */
import request from './request';

const getAllRoles = () => request.get('roles');
const getRole = (id) => request.get(`roles/${id}`);
const addRole = (payload) => request.post('roles', payload);
const updateRole = (id, payload) => request.put(`roles/${id}`, payload);
const deleteRole = (id) => request.delete(`roles/${id}`);

export default {
    getAllRoles,
    getRole,
    addRole,
    updateRole,
    deleteRole
};
