import { v4 as uuidv4 } from 'uuid';
import { NODE_TYPES } from './plugins/constant';
import { nodeClassMapping } from './plugins';

export const POOL_BOTTOM_PADDING = 40;
export const DEFAULT_PADDING = 50;

export const DEFAULT_TOP = -100;
export const DEFAULT_LEFT = -100;
export const SCROLL_DEFAULT_PADDING = 100;

export const DEFAULT_LANE_WIDTH = 1500;
export const DEFAULT_LANE_HEIGHT = 420;

export const DEFAULT_POOL_WIDTH = DEFAULT_LANE_WIDTH + DEFAULT_PADDING;
export const DEFAULT_POOL_HEIGHT = DEFAULT_LANE_HEIGHT + POOL_BOTTOM_PADDING;

export const getDefaultPoolAndLane = () => {
    const defaultPoolUUID = uuidv4();
    const defaultLaneUUID = uuidv4();
    const poolAndLane = [
        {
            id: defaultPoolUUID,
            type: 'pool',
            position: { x: 0, y: 0 },
            style: {
                width: DEFAULT_POOL_WIDTH,
                height: DEFAULT_POOL_HEIGHT,
            },
            data: {
                nodeType: 'pool',
                name: 'Pool',
            },
            draggable: false,
        },
        {
            id: defaultLaneUUID,
            type: 'lane',
            position: { x: 50, y: 0 },
            parentNode: defaultPoolUUID,
            extent: 'parent',
            draggable: false,
            style: {
                width: DEFAULT_LANE_WIDTH,
                height: DEFAULT_LANE_HEIGHT,
            },
            data: {
                nodeType: 'lane',
                name: 'Lane',
            },
        },
    ];
    return {
        poolAndLane,
        laneUUID: defaultLaneUUID,
    };
};

const { poolAndLane, laneUUID } = getDefaultPoolAndLane();

const classMapping = new nodeClassMapping[NODE_TYPES.START_NODE]();
const defaultNodeData = classMapping.getDefaultNodeData();

export const DEFAULT_NODES = [
    ...poolAndLane,
    {
        id: uuidv4(),
        type: 'shape',
        position: { x: 0, y: 0 },
        style: { width: 80, height: 80 },
        data: { ...defaultNodeData },
        parentNode: laneUUID,
    },
];

export const PRO_OPTIONS = { account: 'paid-pro', hideAttribution: true };
