import authReducer from './slices/auth';
import messageReducer from './slices/message';
import layoutReducer from './slices/layout';
import roleReducer from './slices/role';
import processReducer from './slices/process';
import userReducer from './slices/user';
import formsReducer from './slices/forms';
import tenantReducer from './slices/tenant';
import processFlowReducer from './slices/process-flow';
import datasourceReducer from '../plugins/datasource/slices/datasource';
import processDetailsSliceReducer from '../pages/process/details/slices/process-details';

const reducer = {
    auth: authReducer,
    message: messageReducer,
    layout: layoutReducer,
    role: roleReducer,
    process: processReducer,
    user: userReducer,
    forms: formsReducer,
    tenant: tenantReducer,
    processFlow: processFlowReducer,
    datasource: datasourceReducer,
    processDetails: processDetailsSliceReducer
};

export default reducer;
