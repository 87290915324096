import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { user } from '../../services';
import { showError } from '../../helpers/common';

const initialState = {
    users: [],
    loading: false,
    error: '',
};

export const createUser = createAsyncThunk('users/create', async ({ payload, toggle }, { rejectWithValue }) => {
    try {
        const res = await user.addUser(payload);
        toggle();
        return res.data;
    } catch (error) {
        const message = error.message || error.toString();
        showError(message);
        return rejectWithValue(message);
    }
});

export const getAllUsers = createAsyncThunk('users/all', async (_, { rejectWithValue }) => {
    try {
        const res = await user.getAllUsers();
        return res.data;
    } catch (error) {
        const message = error.message || error.toString();
        showError(message);
        return rejectWithValue(message);
    }
});

export const deleteUser = createAsyncThunk('users/delete', async ({ id }, { rejectWithValue }) => {
    try {
        await user.deleteUser(id);
        return true;
    } catch (error) {
        const message = error.message || error.toString();
        showError(message);
        return rejectWithValue(message);
    }
});

export const updateUser = createAsyncThunk('users/update', async ({ id, payload, toggle }, { rejectWithValue }) => {
    try {
        await user.updateUser(id, payload);
        toggle();
        return payload;
    } catch (error) {
        const message = error.message || error.toString();
        showError(message);
        return rejectWithValue(message);
    }
});

const userSlice = createSlice({
    name: 'user',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(createUser.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(createUser.fulfilled, (state, action) => {
                state.loading = false;
                state.users.unshift(action.payload);
            })
            .addCase(createUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(getAllUsers.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getAllUsers.fulfilled, (state, action) => {
                state.loading = false;
                state.users = action.payload;
            })
            .addCase(getAllUsers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(deleteUser.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(deleteUser.fulfilled, (state, action) => {
                state.loading = false;
                const {
                    arg: { id },
                } = action.meta;
                if (id) {
                    state.users = state.users.filter((item) => item.id !== id);
                }
            })
            .addCase(deleteUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(updateUser.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.loading = false;
                const {
                    arg: { id },
                } = action.meta;
                if (id) {
                    state.users = state.users.map((item) => (item.id === id ? { ...item, ...action.payload } : item));
                }
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            });
    },
});

const { reducer } = userSlice;
export default reducer;
