import { createSlice } from '@reduxjs/toolkit';

import {
    layoutTypes,
    layoutModeTypes,
    layoutWidthTypes,
    topBarThemeTypes,
    sidebarSizeTypes,
    leftSideBarThemeTypes,
} from '../../constants/layout';

const initialState = {
    layoutType: layoutTypes.VERTICAL,
    layoutModeType: layoutModeTypes.LIGHT,
    layoutWidth: layoutWidthTypes.FLUID,
    leftSideBarTheme: leftSideBarThemeTypes.COLORED,
    topbarTheme: topBarThemeTypes.LIGHT,
    sidebarSizeType: sidebarSizeTypes.DEFAULT,
    isPreloader: false,
    showRightSidebar: false,
    isMobile: false,
    showSidebar: true,
    leftMenu: false,
};

const messageSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setMessage: (state, action) => {
            return { message: action.payload };
        },
        clearMessage: () => {
            return { message: '' };
        },
    },
});

const { reducer, actions } = messageSlice;

export const { setMessage, clearMessage } = actions;
export default reducer;
