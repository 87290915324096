import { ConfigProvider, Button, theme } from 'antd';
const { useToken } = theme;

const ColorButton = ({children, override, ...props}) => {
    const { token, algorithm } = useToken();
    const overrideHover = override ? `${override}Hover` : '';
    const overrideColor = token[override] || override;
    const overrideHoverColor = token[overrideHover] || overrideHover;
    const modifiedTheme = {
        token: {...token, colorPrimary: overrideColor, colorPrimaryHover: overrideHoverColor},
        algorithm: algorithm,
    };

    return (
        <ConfigProvider theme={modifiedTheme} >
            <Button {...props}>{children}</Button>
        </ConfigProvider>
    );
};

export default ColorButton;