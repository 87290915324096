import { Button, Checkbox, Form, Table } from 'antd';
import React from 'react';
import { SelectField } from '../fields/select/index';
import { InputField } from '../fields/input/index';
import { AddSelectField } from '../fields/add-select';
import { CheckboxField } from '../fields/checkbox';
import { OrField } from '../fields';
import ExcelField from '../fields/excel';
import DatabaseReader from '../fields/db-reader';
import DatabaseWriter from '../fields/db-writer';
import RestApi from "../fields/rest-api";

const tdThStyle = {
    border: '1px solid #dddddd',
    textAlign: 'center',
    padding: '8',
};

const FormMaker = (
    {
        form,
        formInfo,
        initialValues,
        fields = [],
        actions = {},
        onFinish,
        onFinishFailed,
        onAction = () => {},
        visibility = {},
    },
    formMakerRef
) => {
    const getField = (fieldInfo) => {
        if (['text', 'password', 'number'].includes(fieldInfo?.type)) {
            let ini = initialValues?.[fieldInfo?.name];
            if (fieldInfo?.actionKey?.toString()) {
                ini = initialValues?.['actions']?.[fieldInfo?.actionKey]?.[fieldInfo?.name];
            }
            if (fieldInfo?.visibleKey?.toString()) {
                ini = initialValues?.['visibility']?.[fieldInfo?.visibleKey]?.[fieldInfo?.name];
            }
            return <InputField form={form} fieldInfo={fieldInfo} initialValue={ini} type={fieldInfo.type} />;
        }

        if (fieldInfo?.type === 'db-reader-form')
            return (
                <DatabaseReader form={form} formInfo={formInfo} fieldInfo={fieldInfo} initialValues={initialValues} />
            );

        if (fieldInfo?.type === 'db-writer-form')
            return (
                <DatabaseWriter form={form} formInfo={formInfo} fieldInfo={fieldInfo} initialValues={initialValues} />
            );

        if (fieldInfo?.type === 'rest-api-form')
            return (
                <RestApi form={form} formInfo={formInfo} fieldInfo={fieldInfo} initialValues={initialValues} />
            );

        if (fieldInfo?.type === 'api-node')
            return (
                <RestApi
                    form={form}
                    formInfo={formInfo}
                    fieldInfo={fieldInfo}
                    initialValues={initialValues}
                />
            );

        if (fieldInfo?.type === 'excel')
            return (
                <ExcelField
                    form={form}
                    formInfo={formInfo}
                    fieldInfo={fieldInfo}
                    initialValue={initialValues?.[fieldInfo?.name]}
                />
            );

        if (fieldInfo?.type === 'table') {
            return (
                <div>
                    <Table columns={fieldInfo?.columns} dataSource={fieldInfo?.data} pagination={false} />
                </div>
            );
        }

        if (fieldInfo?.type === 'select') {
            let ini = initialValues?.[fieldInfo?.name];
            if (fieldInfo?.actionKey?.toString()) {
                ini = initialValues?.['actions']?.[fieldInfo?.actionKey]?.[fieldInfo?.name];
            }
            if (fieldInfo?.buttonKey) {
                return (
                    <div style={{ display: 'flex' }}>
                        <SelectField
                            ref={formMakerRef}
                            fieldInfo={fieldInfo}
                            form={form}
                            initialValue={ini}
                            formInfo={formInfo}
                            optionGroupKey={fieldInfo?.optionGroupKey}
                        />
                        <Button type="primary" style={{ marginLeft: 5 }} onClick={() => onAction(fieldInfo?.buttonKey)}>
                            {fieldInfo?.buttonLabel}
                        </Button>
                    </div>
                );
            }
            return (
                <SelectField
                    fieldInfo={fieldInfo}
                    form={form}
                    initialValue={initialValues?.[fieldInfo?.name]}
                    formInfo={formInfo}
                    optionGroupKey={fieldInfo?.optionGroupKey}
                />
            );
        }

        if (fieldInfo?.type === 'add-select') {
            let ini = initialValues?.[fieldInfo?.name];
            if (fieldInfo?.actionKey) {
                ini = initialValues?.['actions']?.[fieldInfo?.actionKey]?.[fieldInfo?.name];
            }
            if (fieldInfo?.visibleKey) {
                ini = initialValues?.['visibility']?.[fieldInfo?.visibleKey]?.[fieldInfo?.name];
            }
            return <AddSelectField fieldInfo={fieldInfo} form={form} initialValue={ini} formInfo={formInfo} />;
        }

        if (fieldInfo?.type === 'checkbox') {
            let ini = initialValues?.[fieldInfo?.name] || false;
            if (fieldInfo?.actionKey) {
                ini = initialValues?.['actions']?.[fieldInfo?.actionKey]?.[fieldInfo?.name];
            }

            if (fieldInfo?.visibleKey) {
                ini = initialValues?.['visibility']?.[fieldInfo?.visibleKey]?.[fieldInfo?.name];
            }

            return <CheckboxField fieldInfo={fieldInfo} form={form} initialValue={ini} />;
        }

        if (fieldInfo.type === 'or-field') {
            return <OrField fieldInfo={fieldInfo} form={form} initialValues={initialValues} />;
        }

        if (fieldInfo?.type === 'operations') {
            return (
                <div>
                    {fieldInfo?.icons.map((icon) => {
                        return (
                            <i
                                className={icon?.className}
                                style={{ cursor: 'pointer' }}
                                id={icon?.key}
                                onClick={() => onAction(icon?.key)}
                                key={icon?.key}
                            />
                        );
                    })}
                </div>
            );
        }
    };

    const getActionTableField = (ac) => {
        if (ac?.showTableColumn) {
            if (ac?.type === 'static-column') {
                return <div>{ac?.label}</div>;
            }
            if (ac?.type === 'checkbox') {
                return <Checkbox checked={form.getFieldValue(ac?.dataValuePath)} disabled />;
            }
            if (ac?.type === 'operations') {
                return (
                    <Form.Item key={ac?.name} name="actions" style={{ margin: 0, padding: 0 }}>
                        <div>
                            {ac?.icons.map((icon) => {
                                return (
                                    <i
                                        className={icon?.className}
                                        style={{ cursor: 'pointer' }}
                                        id={icon?.key}
                                        key={icon?.key}
                                        onClick={() => onAction(icon?.key)}
                                    />
                                );
                            })}
                        </div>
                    </Form.Item>
                );
            }
            return ac?.showField ? (
                <Form.Item
                    key={ac?.name}
                    valuePropName={ac?.type === 'checkbox' ? 'checked' : 'value'}
                    name={['actions', ac?.actionKey, ac?.name]}
                    // label={ac?.label}
                    messageVariables={{ label: ac?.label }}
                    required={ac?.required || false}
                    rules={ac?.rules || []}
                    style={{ margin: 0, padding: 0 }}
                >
                    <div style={{ margin: '10px' }}>{getField({ ...ac })}</div>
                </Form.Item>
            ) : (
                <div>{form.getFieldValue(ac?.dataValuePath)?.toString() || '-'}</div>
            );
        }
    };

    return (
        <Form form={form} onFinish={onFinish} initialValues={initialValues} onFinishFailed={onFinishFailed}>
            {fields?.map((field) => {
                if (field?.type === 'label') getField(field);
                return (
                    <Form.Item
                        key={field?.name}
                        name={field?.name}
                        label={field?.label}
                        messageVariables={{ label: field?.label }}
                        required={field?.required || false}
                        rules={field?.rules || []}
                    >
                        {getField(field)}
                    </Form.Item>
                );
            })}

            {Object.keys(visibility).length > 0 && (
                <div>
                    <h5 style={{ marginTop: 30 }}>Visibility</h5>
                    {Object.entries(visibility).map(([key, value], index) => (
                        <>
                            {value?.map((ac) => {
                                if (ac?.type === 'label') getField(ac);
                                return (
                                    <Form.Item
                                        key={ac?.name}
                                        valuePropName={ac?.type === 'checkbox' ? 'checked' : 'value'}
                                        name={['visibility', key, ac?.name]}
                                        label={ac?.label}
                                        messageVariables={{ label: ac?.label }}
                                        required={ac?.required || false}
                                        rules={ac?.rules || []}
                                        style={{ marginBottom: 10 }}
                                    >
                                        <div style={{ width: 200 }}>{getField({ ...ac, visibleKey: key })}</div>
                                    </Form.Item>
                                );
                            })}
                        </>
                    ))}
                </div>
            )}

            {Object.keys(actions).length > 0 && (
                <div>
                    <h5 style={{ marginTop: 30 }}>Actions</h5>
                    <table
                        style={{
                            fontFamily: 'arial, sans-serif',
                            borderCollapse: 'collapse',
                            width: '100%',
                            textAlign: 'center',
                        }}
                    >
                        <tbody>
                            {Object.entries(actions).map(([key, value], index) => (
                                <>
                                    {index === 0 && (
                                        <tr>
                                            {value.map((acc, index) => {
                                                return (
                                                    acc?.showTableColumn && (
                                                        <th key={index} style={tdThStyle}>
                                                            {acc?.tableHeader}
                                                        </th>
                                                    )
                                                );
                                            })}
                                        </tr>
                                    )}
                                    <tr>
                                        {value?.map((ac, index) => {
                                            return (
                                                !ac?.hideOnTable && (
                                                    <td key={index} style={tdThStyle}>
                                                        {getActionTableField({ ...ac, actionKey: key })}
                                                    </td>
                                                )
                                            );
                                        })}
                                    </tr>
                                </>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {actions?.length > 0 && (
                <div>
                    <h5 style={{ marginTop: 30 }}>Actions</h5>
                    <div style={{ border: '2px solid lightgrey', borderRadius: 5, marginTop: 20 }}></div>
                </div>
            )}
        </Form>
    );
};

export default React.forwardRef(FormMaker);
