import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, Input, Row, UncontrolledTooltip } from 'reactstrap';
import Breadcrumbs from '../../components/breadcrumb/Breadcrumb';
import { DeleteModal } from '../../components/delete-modal/DeleteModal';
import Grid from '../../components/table/ag-grid';
import { createForm, deleteForm, getAllForms, updateForm } from '../../store/slices/forms';
import { AddForm } from './addForm';
import './forms.scss';

function FormularPage() {
    const { t } = useTranslation();
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedForm, setSelectedForm] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [isPreview, setIsPreview] = useState(false);
    const [modal, setModal] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [formData, setFormData] = useState({});

    // validation
    const onSubmit = (values, validation) => {
        const payload = {
            name: values.name,
            fieldData: formData,
        };
        if (isEdit && selectedForm?.id) {
            // update form
            dispatch(updateForm({ id: selectedForm?.id, payload }));
            validation.resetForm();
        } else {
            // save new form
            dispatch(createForm({ payload }));
            validation.resetForm();
        }
        toggle();
    };

    const dispatch = useDispatch();
    const forms = useSelector((state) => state.forms.forms);

    useEffect(() => {
        dispatch(getAllForms());
    }, [dispatch]);

    const handleDeleteForm = () => {
        if (selectedForm?.id) {
            dispatch(deleteForm({ id: selectedForm.id }));
            setDeleteModal(false);
        }
    };

    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);
            setSelectedForm(null);
        } else {
            setModal(true);
        }
    }, [modal]);

    const handleFormClick = useCallback(
        (arg) => {
            const isEditMode = arg ? true : false;
            setSelectedForm(arg);
            setIsEdit(isEditMode);
            setIsPreview(false);
            toggle();
        },
        [toggle]
    );

    const onClickDelete = (role) => {
        setSelectedForm(role);
        setDeleteModal(true);
    };

    const previewForm = useCallback(
        (arg) => {
            setSelectedForm(arg);
            setIsPreview(true);
            setIsEdit(true);
            toggle();
        },
        [toggle]
    );

    /* ---- form data handling ---- */
    const handleFormSubmit = (data) => {
        setFormData(data);
    };

    const [columnDefs] = useState([
        {
            headerName: t('forms.name'),
            field: 'name',
        },
        {
            headerName: t('action.action'),
            cellRenderer: (props) => {
                const { data } = props;
                return (
                    <div className="d-flex ag-action-cell">
                        <p onClick={() => handleFormClick(data)} className="text-primary">
                            <i className="fas fa-edit" id="edittooltip" />
                            <UncontrolledTooltip placement="top" target="edittooltip">
                                {t('action.edit')}
                            </UncontrolledTooltip>
                        </p>
                        <p onClick={() => previewForm(data)} className="mx-2 text-primary">
                            <i className="far fa-calendar-check" id="previewtooltip" />
                            <UncontrolledTooltip placement="top" target="previewtooltip">
                                {t('action.preview')}
                            </UncontrolledTooltip>
                        </p>
                        <p onClick={() => onClickDelete(data)} className="mx-2 text-danger">
                            <i className="fas fa-trash" id="deletetooltip" />
                            <UncontrolledTooltip placement="top" target="deletetooltip">
                                {t('action.delete')}
                            </UncontrolledTooltip>
                        </p>
                    </div>
                );
            },
        },
    ]);

    return (
        <React.Fragment>
            <DeleteModal
                showModal={deleteModal}
                handleOk={handleDeleteForm}
                handleCancel={() => setDeleteModal(false)}
            />

            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title={t('forms.forms')} breadcrumbItem={t('forms.forms')}>
                        <>
                            <Input
                                type="text"
                                className="form-control bg-light border-light rounded w-50"
                                placeholder={`${t('action.search')}...`}
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                            <Button color="primary" onClick={() => handleFormClick()}>
                                {t('forms.add-form')}
                            </Button>
                        </>
                    </Breadcrumbs>

                    <Card>
                        <Row>
                            <Col xs={12}>
                                <Grid columnDefs={columnDefs} rowData={forms} quickFilterText={searchText} />
                            </Col>
                        </Row>
                    </Card>

                    <AddForm
                        showModal={modal}
                        toggle={toggle}
                        isPreview={isPreview}
                        isEdit={isEdit}
                        onSubmit={onSubmit}
                        selectedForm={selectedForm}
                        handleFormSubmit={handleFormSubmit}
                    />
                </div>
            </div>
        </React.Fragment>
    );
}

export default FormularPage;
