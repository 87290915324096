import BaseNode from './BaseNode';
import { eventResourceLoaders } from './utils/GenericFunctions';

class EventNode extends BaseNode {
    // eslint-disable-next-line no-useless-constructor
    constructor() {
        super();
        this.displayName = 'Event';
        this.description = '';
        this.name = 'event';
        this.color = '#63A817';
        this.icon = '';
        this.type = 'circle';
        this.methods = {
            loadOptions: eventResourceLoaders,
        };
    }

    getDefaultAttributes() {
        const defaultAttributes = super.getDefaultAttributes();
        return [
            ...defaultAttributes,
            {
                displayName: 'node-details.role',
                name: 'role',
                description: 'node-details.role',
                type: 'select',
                mode: 'multiple',
                default: '',
                required: true,
                typeOptions: {
                    loadOptionsMethod: 'getRoles',
                },
                section: '',
                tab: '',
            },
        ];
    }
}
export { EventNode as default };
