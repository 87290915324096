import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Select, Table } from 'antd';
import { useFormik } from 'formik';
import React, { useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Input, Row } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import { useStateWithRef } from '../../helpers/useStateWithRef';
import { createForm, updateForm } from '../process/details/slices/process-details';
import CustomReactFormBuilder from './customreactformbuilder';
import i18n from '../../i18n';
import { isPrevNodeStartNode } from '../process/details/plugins/utils/GenericFunctions';

const DEFAULT_BUTTON_ACTIONS = [
    {
        value: 'save',
        label: i18n.t('action.save-continue'),
    },
    {
        value: 'cancel',
        label: i18n.t('action.cancel'),
    },
];

const EXTENDED_BUTTON_OPTIONS = [
    ...DEFAULT_BUTTON_ACTIONS,
    {
        value: 'update',
        label: i18n.t('action.save'),
    },
];

export const AddForm = ({
    defaultFormName = '',
    nodeId = null,
    showModal = false,
    selectedForm = null,
    toggle = () => false,
    onSubmit = () => false,
    isEdit = false,
    isPreview = false,
}) => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({});

    const dispatch = useDispatch();
    const { process, attributes, forms } = useSelector((state) => state.processDetails);
    const isStartNode = isPrevNodeStartNode(nodeId);
    const [buttonActions] = useState(isStartNode ? DEFAULT_BUTTON_ACTIONS : EXTENDED_BUTTON_OPTIONS);
    const { dataSources } = useSelector((state) => state.datasource);

    const userSelectedForm = forms.find((f) => f.id === selectedForm);
    const [isEditing, setIsEditing] = useState(false);

    const toggleEdit = () => {
        setIsEditing(!isEditing);
    };

    const keyMapping = {
        number: 'NumberInput',
        text: 'TextInput',
        date: 'DatePicker',
        datetime: 'DatePicker',
        select: 'Dropdown',
        textarea: 'TextArea',
        checkbox: 'Checkboxes',
    };

    const layoutOptions = [
        { key: 'TwoColumnRow', name: t('forms.two-column-row'), icon: 'fas fa-columns', group_name: 'Layout' },
        { key: 'ThreeColumnRow', name: t('forms.three-column-row'), icon: 'fas fa-columns', group_name: 'Layout' },
        {
            group_name: 'Layout',
            key: 'FourColumnRow',
            element: 'MultiColumnRow',
            canHaveAnswer: false,
            name: t('forms.four-column-row'),
            label: '',
            icon: 'fas fa-columns',
            field_name: 'four_col_row_',
            col_count: 4,
            class_name: 'col-md-3',
        },
        {
            group_name: 'Layout',
            key: 'FiveColumnRow',
            element: 'MultiColumnRow',
            canHaveAnswer: false,
            name: t('forms.five-column-row'),
            label: '',
            icon: 'fas fa-columns',
            field_name: 'five_col_row_',
            col_count: 5,
            class_name: 'col',
        },
        {
            group_name: 'Layout',
            key: 'SixColumnRow',
            element: 'MultiColumnRow',
            canHaveAnswer: false,
            name: t('forms.six-column-row'),
            label: '',
            icon: 'fas fa-columns',
            field_name: 'six_col_row_',
            col_count: 6,
            class_name: 'col-md-2',
        },
    ];

    const defaultElements = [
        { key: 'TextInput', icon: 'fas fa-font', name: 'Eingabefeld', group_name: t('forms.elements') },
        { key: 'EmailInput', icon: 'fas fa-envelope', name: 'Email', group_name: t('forms.elements') },
        { key: 'PhoneNumber', icon: 'fas fa-phone', name: 'Telefonnr', group_name: t('forms.elements') },
        { key: 'NumberInput', icon: 'fas fa-plus', name: 'Eingabefeld numerisch', group_name: t('forms.elements') },
        { key: 'TextArea', icon: 'fas fa-text-height', group_name: t('forms.elements') },
        { key: 'Dropdown', icon: 'fas fa-caret-square-down', group_name: t('forms.elements') },
        { key: 'Signature', icon: 'fas fa-pen-square', group_name: t('forms.elements') },
        { key: 'Checkboxes', icon: 'fas fa-check-square', group_name: t('forms.elements') },
        { key: 'DatePicker', icon: 'fas fa-calendar-alt', group_name: t('forms.elements') },
        { key: 'RadioButtons', icon: 'fas fa-dot-circle', group_name: t('forms.elements') },
        { key: 'Image', icon: 'fas fa-image', group_name: t('forms.elements') },
        //{ key: 'Rating', icon: 'fas fa-star', group_name: t('forms.elements'), },
        //{ key: 'Tags', icon: 'fas fa-tags', group_name: t('forms.elements'), },
        { key: 'Camera', icon: 'fas fa-camera', name: 'Kamera', group_name: t('forms.elements') },
        { key: 'FileUpload', icon: 'fas fa-file', group_name: t('forms.elements') },
        //{ key: 'Range', icon: 'fas fa-sliders-h', group_name: t('forms.elements'), },
    ];

    Yup.addMethod(Yup.string, 'isExistt', function (errorMessage) {
        return this.test(`test-isExist`, errorMessage, function (value) {
            return !(
                forms?.findIndex((form) => form.name === value && (selectedForm ? selectedForm === form.id : false)) >=
                0
            )
                ? errorMessage
                : '';
        });
    });

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            name: userSelectedForm?.name || defaultFormName,
            fieldData: userSelectedForm?.fieldData || '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required(t('error-message.role-name-required')),
        }),
        onSubmit: async (values) => {
            const payload = {
                name: values.name,
                fieldData: formData,
                actions: JSON.stringify(buttonsRef.current || []),
                processId: process.id,
            };

            let data;
            if (selectedForm) {
                payload.fieldData = !Object.keys(formData)?.length ? values.fieldData : formData;
                data = await dispatch(updateForm({ id: selectedForm, payload }));
            } else {
                data = await dispatch(createForm(payload));
            }
            const { form } = data?.payload;
            onSubmit(form?.id);
        },
    });

    const handleFormInit = () => {
        if (userSelectedForm) {
            return JSON.parse(userSelectedForm.fieldData);
        }
        return [];
    };

    const getIcon = (fieldType) => {
        let icon = '';
        if (fieldType === 'text') {
            icon = 'fas fa-font';
        } else if (fieldType === 'select') {
            icon = 'far fa-caret-square-down';
        } else if (fieldType === 'number') {
            icon = 'fas fa-plus';
        } else if (fieldType === 'checkbox') {
            icon = 'fas fa-check-square';
        } else if (fieldType === 'textarea') {
            icon = 'fas fa-text-height';
        } else if (fieldType === 'date' || fieldType === 'datetime') {
            icon = 'far fa-calendar-alt';
        } else {
            icon = 'fas fa-text-height';
        }
        return icon;
    };

    const getFormattedAttributes = () => {
        return [
            ...defaultElements,
            ...attributes?.map((att) => {
                const formattedAttr = {
                    ...att,
                    group_name: t('forms.processattributes') + ': ' + (att?.attributeGroupName || 'Others'),
                    name: att.name,
                    field_name: `${att.id}_`,
                    label: att.name,
                    component: keyMapping[att.type],
                    element: keyMapping[att.type],
                    icon: getIcon(att.type),
                };
                if (att.type === 'checkbox') {
                    formattedAttr.options = [
                        {
                            text: 'Yes',
                            value: 1,
                        },
                    ];
                }
                if (att.type === 'select') {
                    let options = [];

                    if (att.selectDataSource?.dataSourceType === 'staticList') {
                        options = att.selectDataSource?.option?.map((option) => ({
                            text: option.label,
                            value: option.value,
                        }));
                    } else if (att.selectDataSource?.dataSourceType === 'selectDS') {
                        const { label, value, dataSourceName } = att.selectDataSource;
                        const dataSourceObj = dataSources.find((ds) => ds.id === dataSourceName);
                        if (dataSourceObj) {
                            options = dataSourceObj.tableRows.map((row) => ({
                                text: row['column-' + label],
                                value: row['column-' + value],
                            }));
                        }
                    }
                    formattedAttr.options = options;
                }
                return formattedAttr;
            }),
            ...layoutOptions,
        ];
    };

    const getFooter = () => {
        const fButtons = [];
        if (!isPreview) {
            fButtons.push(
                <Button key="saveButton" type="primary" onClick={() => validation.handleSubmit()}>
                    {t('action.save')}
                </Button>
            );
        }
        return fButtons;
    };

    /**
     * action button handling ------------------------------------------------------------------------------------------
     */
    const getFormActions = () => {
        if (userSelectedForm) {
            return JSON.parse(userSelectedForm.actions);
        }
        return [
            {
                id: `button_0`,
                label: t('action.cancel'),
                color: '#DC3545',
                textColor: '#ffffff',
                action: 'cancel',
                attributes: [],
            },
            {
                id: `button_1`,
                label: t('action.done'),
                color: '#28A745',
                textColor: '#ffffff',
                action: 'save',
                attributes: [],
            },
        ];
    };
    const [, setButtons, buttonsRef] = useStateWithRef(getFormActions() || []);
    const [, setSelectedButton, selectedButtonRef] = useStateWithRef(null);
    const rootRef = useRef(null);

    // dialog to confirm deleting a button
    const confirmDelete = () => {
        Modal.confirm({
            title: t('forms.confirm-delete-button'),
            icon: <ExclamationCircleOutlined />,
            content: t('forms.hint-delete-button'),
            okText: t('action.delete'),
            okType: 'danger',
            cancelText: t('action.abort'),
            onOk() {
                removeButton();
            },
            onCancel() {},
        });
    };

    // remove an action button
    const removeButton = () => {
        if (selectedButtonRef?.current && selectedButtonRef?.current?.deleteable) {
            const updatedButtons = buttonsRef.current.filter((button) => button.id !== selectedButtonRef.current.id);

            // remove button from buttonlist
            buttonsRef.current = updatedButtons;

            // set selected button to next in line
            if (updatedButtons.length > 0) {
                selectedButtonRef.current = updatedButtons[updatedButtons.length - 1];
            } else {
                selectedButtonRef.current = null;
            }

            // re-render
            renderEditForm(selectedButtonRef.current, true);
            toggleEditFormDisplay(true);
        }
    };

    // add a new action button
    const addNewButton = () => {
        // assign new button properties
        const newButton = {
            id: `button_${buttonsRef.current.length + 1}`,
            label: `Button ${buttonsRef.current.length + 1}`,
            color: '#ffffff',
            action: '',
            attributes: [],
            deleteable: true,
        };
        // store button
        selectedButtonRef.current = newButton;
        setButtons([newButton, ...buttonsRef.current]);

        // render the edit form
        renderEditForm(selectedButtonRef.current, true);
        toggleEditFormDisplay(true);
    };

    // render all the action buttons
    const renderButtons = () => (
        <>
            {buttonsRef.current.map((button) => (
                <Button
                    key={button.id}
                    style={{
                        backgroundColor: button.color,
                        color: button.textColor || 'black',
                    }}
                    className={
                        selectedButtonRef.current && selectedButtonRef.current.id === button.id
                            ? 'highlighted-button'
                            : ''
                    }
                    onClick={() => handleButtonClick(button)}
                >
                    {button.label}
                </Button>
            ))}
        </>
    );

    // handle click on action button to open edit form with its properties
    const handleButtonClick = (button) => {
        if (!button) return;

        if (button.id !== selectedButtonRef?.current?.id) {
            selectedButtonRef.current = button;

            // render the edit form
            renderEditForm(button, true);
        }

        // ensure button edit form is shown
        toggleEditFormDisplay();
    };

    // render the button edit form
    const renderEditForm = (button, addAttributes = false) => {
        // on add new or edit button add plus row in table
        if (addAttributes) {
            const entry = [...button.attributes, { id: uuidv4(), key: 'new', isNew: true }];
            setTableData(entry);
        }

        // select correct container and render it
        const extendedFormContainer = document.querySelector('.edit-extended-form');
        if (extendedFormContainer) {
            if (!rootRef.current) {
                rootRef.current = createRoot(extendedFormContainer);
            }
            rootRef.current.render(<ButtonEditForm />);
        }
    };

    // update a buttons properties (not attributes)
    const updateButtonProperty = (propertyName, newValue, buttonId) => {
        //selectedButtonRef.current = null;
        const updatedButtons = buttonsRef.current.map((button) => {
            if (button.id === buttonId) {
                return { ...button, [propertyName]: newValue };
            }
            return button;
        });

        // update button reference
        setButtons(updatedButtons);

        // re-set the current button
        const updatedSelectedButton = buttonsRef.current.find((button) => button.id === buttonId);
        if (updatedSelectedButton && selectedButtonRef.current !== updatedSelectedButton) {
            selectedButtonRef.current = updatedSelectedButton;
            renderEditForm(selectedButtonRef.current, false);
        }
    };

    // the buttons edit form
    const ButtonEditForm = () => {
        return (
            <div className="button-edit-form" key={Math.random}>
                <div style={{ marginBottom: '10px' }}>
                    <label>Button Label</label>
                    <input
                        type="text"
                        value={selectedButtonRef.current?.label || ''}
                        onChange={(e) => updateButtonProperty('label', e.target.value, selectedButtonRef.current.id)}
                    />
                </div>
                <div style={{ marginBottom: '10px' }}>
                    <label>Button Color</label>
                    <input
                        type="color"
                        value={selectedButtonRef.current?.color || '#ffffff'}
                        onChange={(e) => updateButtonProperty('color', e.target.value, selectedButtonRef.current.id)}
                    />
                </div>
                <div style={{ marginBottom: '10px' }}>
                    <label>Text Color</label>
                    <input
                        type="color"
                        value={selectedButtonRef.current?.textColor || '#ffffff'}
                        onChange={(e) =>
                            updateButtonProperty('textColor', e.target.value, selectedButtonRef.current.id)
                        }
                    />
                </div>

                <div style={{ marginBottom: '10px' }}>
                    <label>{t('action.action')}</label>
                    <Select
                        value={selectedButtonRef.current?.action || ''}
                        style={{ width: 160 }}
                        onChange={(value) => updateButtonProperty('action', value, selectedButtonRef.current.id)}
                        options={buttonActions}
                    />
                </div>

                <Table
                    columns={actionTableColumns}
                    dataSource={tableDataRef?.current}
                    pagination={false}
                    rowKey={(record) => record.id}
                />

                {selectedButtonRef.current?.deleteable && (
                    <Button
                        icon={<DeleteOutlined />}
                        onClick={confirmDelete}
                        style={{ marginTop: '20px', padding: 0, backgroundColor: 'red' }}
                    >
                        {t('forms.delete-button')}
                    </Button>
                )}
            </div>
        );
    };

    // toggle the edit form that is displayed (form element or action button)
    const toggleEditFormDisplay = (show = true) => {
        const editForm = document.querySelector('.edit-form');
        if (editForm) editForm.style.display = show ? 'none' : 'block';

        const editExtendedForm = document.querySelector('.edit-extended-form');
        if (editExtendedForm) editExtendedForm.style.display = show ? 'block' : 'none';
    };

    /**
     * action / table handling -----------------------------------------------------------------------------------------
     *
     * action:  [{name: '', label: '', action: '', attribute: [], exitNode: ''}]
     */
    const actionTableColumns = [
        {
            title: 'Attribute',
            dataIndex: 'attribute',
            onCell: (record) => (record.isNew ? { children: null, colSpan: 0 } : ''),
            render: (text, record) =>
                !record.isNew ? (
                    <Select
                        defaultValue={text}
                        style={{ width: '100%' }}
                        onChange={(value) => handleSave(record.key, 'attribute', value)}
                    >
                        {attributes.map((attr) => (
                            <Select.Option key={attr.id} value={attr.id}>
                                {attr.name}
                            </Select.Option>
                        ))}
                    </Select>
                ) : (
                    ''
                ),
        },
        {
            title: 'Value',
            dataIndex: 'value',
            onCell: (record) => (record.isNew ? { colSpan: 3 } : ''),
            render: (text, record) => {
                if (record.isNew) {
                    return (
                        <div
                            style={{
                                margin: 'auto',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Button
                                style={{ padding: '0' }}
                                icon={<PlusOutlined />}
                                onClick={() => handleAddNewRow()}
                            />
                        </div>
                    );
                } else {
                    return (
                        <Input
                            type={record.type}
                            defaultValue={text}
                            onBlur={(e) => handleSave(record.key, 'value', e.target.value)}
                        />
                    );
                }
            },
        },
        {
            title: '',
            dataIndex: 'delete',
            onCell: (record) => (record.isNew ? { children: null, colSpan: 0 } : ''),
            render: (_, record) =>
                !record.isNew && (
                    <Button
                        style={{ padding: '0' }}
                        icon={<DeleteOutlined />}
                        onClick={() => handleDeleteRow(record.key)}
                    />
                ),
        },
    ];

    const [, setTableData, tableDataRef] = useStateWithRef([]);

    // add table row
    const handleAddNewRow = () => {
        if (!selectedButtonRef.current) return;

        // get existing attributes and filter isnew entry/button
        const existingAttributes = selectedButtonRef.current.attributes || [];
        const filteredAttributes = existingAttributes.filter((attr) => !attr.isNew);

        // generate new entry
        const newEntry = { id: uuidv4(), key: uuidv4(), attribute: '', value: '', type: '', isNew: false };
        const updatedAttributes = [...filteredAttributes, newEntry];

        // update selected button
        selectedButtonRef.current.attributes = updatedAttributes;

        // update table data
        const displayAttributes = [...updatedAttributes, { id: 'new', key: 'new', isNew: true }];
        setTableData(displayAttributes);

        // re-render edit form without adding + button
        renderEditForm(selectedButtonRef.current, false);
    };

    // delete table row
    const handleDeleteRow = (key) => {
        if (!selectedButtonRef.current) return;

        // update selected button and table data
        const updatedAttributes = (selectedButtonRef.current.attributes || []).filter((item) => item.key !== key);
        selectedButtonRef.current.attributes = updatedAttributes;
        setTableData(updatedAttributes);

        // update buttons
        const updatedButtons = buttonsRef.current.map((button) =>
            button.id === selectedButtonRef.current.id ? { ...button, attributes: updatedAttributes } : button
        );
        setButtons(updatedButtons);

        // re-render edit form and re-add + button
        renderEditForm(selectedButtonRef.current, true);
    };

    // save changed field values
    const handleSave = (key, field, value) => {
        // get existing attributes from table
        const existingAttributes = tableDataRef.current || [];
        const filteredAttributes = existingAttributes.filter((attr) => !attr.isNew); // filter isnew entry/button

        // set updated attributes
        const updatedAttributes = filteredAttributes.map((item) => {
            if (item.key === key) {
                if (field === 'attribute') {
                    const processAttrib = attributes.find((attribute) => attribute.id === value);
                    return { ...item, attribute: value, type: processAttrib?.type };
                } else {
                    return { ...item, [field]: value };
                }
            }
            return item;
        });

        // update selected button
        selectedButtonRef.current.attributes = updatedAttributes;

        // update button data
        if (selectedButtonRef.current) {
            const updatedButtons = buttonsRef.current.map((button) => {
                if (button.id === selectedButtonRef.current.id) {
                    return { ...button, attributes: updatedAttributes };
                }
                return button;
            });
            setButtons(updatedButtons);
        }

        // set table data and render
        //setTableData([...updatedAttributes]);
        tableDataRef.current = updatedAttributes;

        // re-render edit form and re-add + button
        renderEditForm(selectedButtonRef.current, true);
    };

    /**
     * title handling --------------------------------------------------------------------------------------------------
     */
    const EditableTitle = () => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                <h3 style={{ margin: 0, marginRight: '10px' }}>
                    {isPreview ? t('action.preview') : !!isEdit ? t('forms.edit-form') : t('forms.add-form')}
                </h3>
                {isEditing ? (
                    <>
                        <Input
                            name="name"
                            type="text"
                            placeholder={t('forms.name')}
                            onChange={validation.handleChange}
                            onBlur={(event) => {
                                validation.handleBlur(event);
                                toggleEdit(); // toggle edit mode off when input loses focus
                            }}
                            value={validation.values.name}
                            autoFocus // automatically focus the input
                            style={{ width: '50%' }} // set the input field width to half
                        />
                    </>
                ) : (
                    <span onClick={toggleEdit}>{validation.values.name || t('no title')}</span>
                )}
                {!isEditing && <EditOutlined onClick={toggleEdit} style={{ cursor: 'pointer', marginLeft: '10px' }} />}
                {validation.touched.name && validation.errors.name && (
                    <div style={{ color: 'red', marginLeft: '10px' }}> *{t('forms.name-required')}</div>
                )}
            </div>
        </div>
    );

    /**
     * rendering -------------------------------------------------------------------------------------------------------
     */
    return (
        <Modal
            key={Math.random}
            title={<EditableTitle />}
            maskClosable={false}
            open={showModal}
            okText="Save"
            onOk={() => validation.handleSubmit()}
            onCancel={toggle}
            width={'90vw'}
            styles={{
                position: 'relative',
                height: '85vh',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
            }}
            centered
            footer={getFooter()}
            destroyOnClose
        >
            <div className={`modal-content-wrapper`} style={{ display: 'flex', height: '100%' }}>
                <Form
                    className="full-height-form"
                    style={{ flex: 1, height: '100%' }}
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                >
                    <Row style={{ flex: 1, height: '100%' }}>
                        <Col className="col-12" style={{ flex: 1, height: '100%' }}>
                            <CustomReactFormBuilder
                                toolbarItems={getFormattedAttributes()}
                                formSubmitHandler={(data) => setFormData(data)}
                                formInitHandler={handleFormInit}
                                style={{ flex: 1 }}
                                key={Math.random}
                            />
                        </Col>
                    </Row>
                </Form>

                <div
                    className="buttonlist"
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: '10px',
                        width: 'calc(100% - 372px)',
                    }}
                >
                    <div>
                        <Button onClick={addNewButton}>+</Button>
                        {renderButtons()}
                    </div>
                </div>
            </div>
        </Modal>
    );
};
