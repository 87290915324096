import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Col, Select, Row, Radio, Space, Table, Button, Input, Form } from 'antd';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import clone from 'rfdc/default';

const DATASOURCE_OPTIONS = {
    selectDS: 'datasource.choose-datasource',
    staticList: 'datasource.static-list',
};

export default function DataSourceSelectionModal({ show, dataSourceInfo = {}, onSave = () => false, onCancel = () => false}) {
    const { t } = useTranslation();
    const { datasources } = useSelector((state) => state.processDetails);
    const { dataSourceType, name, label, value, option } = clone(dataSourceInfo);
    const [formData, setFormData] = useState({
        dataSourceType: dataSourceType || '',
        name: name || '',
        label: label || '',
        value: value || '',
        option: option || [],
    });

    const [error, setError] = useState({
        dataSourceType: '',
        name: '',
        label: '',
        value: '',
        option: '',
    });

    const [headerOptions, setHeaderOptions] = useState([]);

    useEffect(() => {
        if (formData?.name) {
            const sDataSource = datasources.find((ds) => ds.id === formData?.name);
            setHeaderOptions(
                sDataSource?.tableHeaders?.map((ho) => {
                    return { ...ho, label: ho.value, value: ho.key };
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.name]);

    const onSelectDataSource = (val) => {
        if (!val) {
            setHeaderOptions([]);
        }
        setFormData({ ...formData, name: val, label: '', value: '', option: '' });
    };

    const validateError = () => {
        let err = {};
        if (!formData?.dataSourceType) {
            err.dataSourceType = t('datasource.datasource-type-required');
        }
        if (formData?.dataSourceType === 'selectDS') {
            if (!formData?.name) {
                err.name = 'Name required!';
            }
            if (!formData?.label) {
                err.label = 'Label required!';
            }
            if (!formData?.value) {
                err.value = 'Value required!';
            }
        } else if (formData?.dataSourceType === 'staticList') {
            let opts = formData?.option || [];
            if (!opts.length) {
                err.option = 'Option required!';
                return err;
            }
            opts = opts.map((opt) => {
                const getFieldError = (fKey, fLabel) => {
                    if (!opt[fKey]) {
                        return `${fLabel} is required!!`;
                    }
                    if (opt[fKey]) {
                        const duplicateIndex = opts?.findIndex((op) => op[fKey] === opt[fKey] && op.id !== opt.id);
                        return duplicateIndex > -1 ? `${fLabel} already exist!` : '';
                    }
                };
                return {
                    ...opt,
                    labelError: getFieldError('label', 'Label'),
                    valueError: getFieldError('value', 'Value'),
                };
            });
            const hasError = opts.findIndex((opt) => opt.labelError || opt.valueError);
            if (hasError > -1) {
                err.option = 'Option Invalid';
            }
            setFormData({ ...formData, option: opts });
        }
        return err;
    };

    const validate = () => {
        const error = validateError() || {};
        setError(error);
        if (!Object.keys(error).length) {
            let data = { ...formData };
            if ((formData?.option || []).length) {
                data.option = formData?.option?.map((opt) => {
                    const tempOpt = { ...opt };
                    delete tempOpt?.labelError;
                    delete tempOpt?.valueError;
                    return tempOpt;
                });
            }
            onSave({ ...formData });
        }
    };

    const onDeleteOption = (record) => {
        setFormData({
            ...formData,
            option: [...formData?.option.filter((opt) => opt.id !== record.id)],
        });
    };

    const onOptionChange = (id, fieldKey, value) => {
        const fIndex = formData?.option.findIndex((opt) => opt.id === id);
        const lOptionList = [...formData?.option];
        lOptionList[fIndex][fieldKey] = value;
        setFormData({
            ...formData,
            option: [...lOptionList],
        });
        const error = validateError() || {};
        setError({ ...error });
    };

    const columns = [
        {
            title: 'Label',
            dataIndex: 'label',
            key: 'label',
            render: (_, record) => (
                <Form.Item>
                    <div style={{ display: 'grid' }}>
                        <Input
                            value={record.label}
                            disabled={record?.disabled}
                            style={{ width: 180 }}
                            placeholder="Enter Label"
                            onChange={(e) => {
                                onOptionChange(record.id, 'label', e.target.value);
                            }}
                        />
                        {record?.labelError ? <span style={{ color: 'red' }}>{record?.labelError}</span> : ''}
                    </div>
                </Form.Item>
            ),
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            render: (_, record) => (
                <Form.Item>
                    <div style={{ display: 'grid' }}>
                        <Input
                            value={record.value}
                            disabled={record?.disabled}
                            style={{ width: 180 }}
                            placeholder="Enter Value"
                            onChange={(e) => {
                                onOptionChange(record.id, 'value', e.target.value);
                            }}
                        />
                        {record?.valueError ? <span style={{ color: 'red' }}>{record?.valueError}</span> : ''}
                    </div>
                </Form.Item>
            ),
        },
        {
            title: 'Action',
            render: (_, record) => {
                return (
                    <div className="d-flex ag-action-cell">
                        <p className="mx-2 text-danger" onClick={() => onDeleteOption(record)}>
                            <i className="fas fa-trash" id="deletetooltip" title="Delete" />
                        </p>
                    </div>
                );
            },
        },
    ];

    return (
        <Modal title={t('datasource.datasource')} onOk={validate} width={620} onCancel={onCancel} open={show}>
            <Row>
                <Col className="col-12">
                    <div className="d-flex flex-row">
                        <Radio.Group
                            name="excelHeaderAttribute"
                            className="mb-2"
                            onChange={(event) => {
                                setError({
                                    name: '',
                                    label: '',
                                    value: '',
                                    option: '',
                                });
                                setFormData({
                                    ...formData,
                                    dataSourceType: event.target.value,
                                    name: '',
                                    label: '',
                                    value: '',
                                    option: [],
                                });
                            }}
                            value={formData?.dataSourceType}
                        >
                            <Space direction="vertical">
                                {Object.keys(DATASOURCE_OPTIONS).map((a) => (
                                    <Radio value={a} key={a}>
                                        {t(`${DATASOURCE_OPTIONS[a]}`)}
                                    </Radio>
                                ))}
                            </Space>
                        </Radio.Group>
                    </div>
                    <span style={{ color: '#ff4d4f', fontSize: 14 }}>{error?.dataSourceType}</span>
                </Col>
            </Row>
            {formData?.dataSourceType === 'selectDS' && (
                <>
                    <Row>
                        <Col className="col-6">
                            <div className="mb-3">
                                <div className="form-label">
                                    {t('datasource.name')}
                                    <sup>*</sup>
                                </div>
                                <Select
                                    style={{ width: '100%' }}
                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto', zIndex: '100000' }}
                                    name="name"
                                    onChange={onSelectDataSource}
                                    value={formData?.name || ''}
                                    options={datasources.map((ds) => {
                                        return { label: ds.name, value: ds.id };
                                    })}
                                />
                                <span style={{ color: '#ff4d4f', fontSize: 14 }}>{error?.name}</span>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-6">
                            <div className="mb-3">
                                <div className="form-label">
                                    {t('datasource.label')}
                                    <sup>*</sup>
                                </div>
                                <Select
                                    style={{ width: '100%' }}
                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto', zIndex: '100000' }}
                                    name="label"
                                    onChange={(val) => setFormData({ ...formData, label: val })}
                                    value={formData?.label || ''}
                                    options={headerOptions}
                                />
                                <span style={{ color: '#ff4d4f', fontSize: 14 }}>{error?.label}</span>
                            </div>
                        </Col>
                        <Col className="col-6">
                            <div className="mb-3 mx-3">
                                <div className="form-label">
                                    {t('datasource.value')}
                                    <sup>*</sup>
                                </div>
                                <Select
                                    style={{ width: '100%' }}
                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto', zIndex: '100000' }}
                                    name="value"
                                    onChange={(val) => setFormData({ ...formData, value: val })}
                                    value={formData?.value || ''}
                                    options={headerOptions}
                                />
                                <span style={{ color: '#ff4d4f', fontSize: 14 }}>{error?.value}</span>
                            </div>
                        </Col>
                    </Row>
                </>
            )}
            {formData?.dataSourceType === 'staticList' && (
                <>
                    <Row>
                        <Col className="col-12 mb-2 mt-2 d-flex align-items-center justify-content-between">
                            <div className="form-label">
                                {t('datasource.options')}
                                <sup>*</sup>
                            </div>
                            <Button
                                style={{ paddingInline: 10, display: 'flex', marginLeft: 10 }}
                                onClick={() => {
                                    setFormData({
                                        ...formData,
                                        option: [
                                            ...formData?.option,
                                            {
                                                label: '',
                                                value: '',
                                                id: uuidv4(),
                                            },
                                        ],
                                    });
                                }}
                            >
                                <AddOutlinedIcon />
                                <div style={{ marginLeft: 2, fontSize: 15 }}>Add Option</div>
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-12">
                            <Table columns={columns} dataSource={formData?.option} bordered />
                            <span style={{ color: '#ff4d4f', fontSize: 14 }}>{error?.option}</span>
                        </Col>
                    </Row>
                </>
            )}
        </Modal>
    );
}
