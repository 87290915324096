import i18n from '../../../i18n';
import store from '../../../store';
import { AttributeList } from './AttributeList';

const isDuplicateView = (viewName, viewId = null) => {
    const state = store.getState();
    const { processDetails } = state;
    const { views } = processDetails ?? [];
    return views.find((v) => v.name === viewName && v.id !== viewId);
};

class Views {
    methods = {};

    // eslint-disable-next-line no-useless-constructor
    constructor() {}

    getDefaultAttributes() {
        return [
            {
                displayName: 'views.name',
                name: 'name',
                description: 'views.name',
                type: 'inline-edit',
                default: '',
                required: true,
                section: '', // use translation keys here
                tab: '', // use translation keys here
                rules: [
                    {
                        required: true,
                        message: i18n.t('views.name-required'),
                    },
                    (formInstance) => ({
                        validator(_, value) {
                            const id = formInstance.getFieldValue('id');
                            if (!isDuplicateView(value, id)) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('Duplicate view name.'));
                        },
                    }),
                ],
            },
            {
                displayName: 'views.views_attributes',
                name: 'views_attributes',
                description: 'views.views_attributes',
                isFormsList: true,
                component: <AttributeList />,
            },
        ];
    }
}
export { Views as default };
