import React from 'react';
import { Navigate } from 'react-router-dom';

// Dashboard
import Dashboard from '../pages/Dashboard/index';

import Pages404 from '../pages/Utility/pages-404';
import Pages500 from '../pages/Utility/pages-500';
import PagesComingsoon from '../pages/Utility/pages-comingsoon';
import PagesMaintenance from '../pages/Utility/pages-maintenance';


// Authentication related pages
import ForgetPwd from '../pages/Authentication/ForgetPassword';
import Login from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';

// Profile
import UserProfile from '../pages/Authentication/user-profile';
import FormsPage from '../pages/forms/forms';
import ProcessPage from '../pages/process/process';
import RolePage from '../pages/roles/role';
import TenantPage from '../pages/tenant/tenant';
import UserPage from '../pages/user/user';
import DatasourcePage from '../plugins/datasource/pages/datasource';

import ProcessDetailsWrapper from '../pages/process/details';
import MyOperations from '../pages/my-operations';

const userRoutes = [
    { path: '/dashboard', component: <Dashboard /> },
    { path: '/my-operations', component: <MyOperations /> },

    // //profile
    { path: '/profile', component: <UserProfile /> },

    { path: '/role', component: <RolePage /> },
    { path: '/process', component: <ProcessPage /> },
    { path: '/forms', component: <FormsPage /> },
    { path: '/user', component: <UserPage /> },
    { path: '/tenant', component: <TenantPage /> },
    { path: '/process/details', component: <ProcessDetailsWrapper /> },
    { path: '/datasources', component: <DatasourcePage /> },
    // this route should be at the end of all other routes
    {
        path: '/',
        exact: true,
        component: <Navigate to="/dashboard" />,
    },
    { path: '*', component: <Navigate to="/dashboard" /> },
];

const authRoutes = [
    { path: '/logout', component: <Logout /> },
    { path: '/login', component: <Login /> },
    { path: '/forgot-password', component: <ForgetPwd /> },

    { path: '/pages-maintenance', component: <PagesMaintenance /> },
    { path: '/pages-comingsoon', component: <PagesComingsoon /> },
    { path: '/pages-404', component: <Pages404 /> },
    { path: '/pages-500', component: <Pages500 /> },
];

export { authRoutes, userRoutes };

