import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { forms } from '../../services';

const initialState = {
    forms: [],
    loading: false,
    error: '',
};

export const createForm = createAsyncThunk('forms/create', async ({ payload }, { rejectWithValue }) => {
    try {
        const res = await forms.addForm(payload);
        return res.data;
    } catch (error) {
        const message = error.message || error.toString();
        return rejectWithValue(message);
    }
});

export const getAllForms = createAsyncThunk('forms/all', async (_, { rejectWithValue }) => {
    try {
        const res = await forms.getAllForms();
        return res.data;
    } catch (error) {
        const message = error.message || error.toString();
        return rejectWithValue(message);
    }
});

export const deleteForm = createAsyncThunk('forms/delete', async ({ id }, { rejectWithValue }) => {
    try {
        await forms.deleteForm(id);
        return true;
    } catch (error) {
        const message = error.message || error.toString();
        return rejectWithValue(message);
    }
});

export const updateForm = createAsyncThunk('forms/update', async ({ id, payload }, { rejectWithValue }) => {
    try {
        await forms.updateForm(id, payload);
        return payload;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

const formSlice = createSlice({
    name: 'forms',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(createForm.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(createForm.fulfilled, (state, action) => {
                state.loading = false;
                state.forms.unshift(action.payload);
            })
            .addCase(createForm.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(getAllForms.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getAllForms.fulfilled, (state, action) => {
                state.loading = false;
                state.forms = action.payload;
            })
            .addCase(getAllForms.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(deleteForm.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(deleteForm.fulfilled, (state, action) => {
                state.loading = false;
                const {
                    arg: { id },
                } = action.meta;
                if (id) {
                    state.forms = state.forms.filter((item) => item.id !== id);
                }
            })
            .addCase(deleteForm.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(updateForm.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(updateForm.fulfilled, (state, action) => {
                state.loading = false;
                const {
                    arg: { id },
                } = action.meta;
                if (id) {
                    state.forms = state.forms.map((item) => (item.id === id ? { ...item, ...action.payload } : item));
                }
            })
            .addCase(updateForm.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            });
    },
});

const { reducer } = formSlice;
export default reducer;
