import React, { useState } from 'react';
import { Table } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { deleteView } from '../details/slices/process-details';
import { showError } from '../../../helpers/common';
import { DeleteModal } from '../../../components/delete-modal/DeleteModal';

const ViewTable = ({ setShowModal, setSelectedRecord }) => {
    const dispatch = useDispatch();
    const { views } = useSelector((state) => state.processDetails);
    const [deleteModal, setDeleteModal] = useState(false);
    const [viewId, setViewId] = useState(null);

    const handleDelete = async () => {
        try {
            dispatch(deleteView(viewId));
            setDeleteModal(false);
        } catch (err) {
            showError(err);
        }
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <>
                    <i
                        className="fas fa-edit mx-2"
                        id="edittooltip"
                        title="Edit"
                        onClick={() => {
                            setSelectedRecord(record);
                            setShowModal(true);
                        }}
                    />
                    <i
                        className="fas fa-trash fa-danger"
                        id="deletetooltip"
                        title="Delete"
                        onClick={() => {
                            setViewId(record.id);
                            setDeleteModal(true);
                        }}
                    />
                </>
            ),
        },
    ];
    return (
        <>
            <Table dataSource={views} columns={columns} />
            {deleteModal && (
                <DeleteModal
                    showModal={deleteModal}
                    handleOk={handleDelete}
                    handleCancel={() => {
                        setDeleteModal(false);
                    }}
                />
            )}
        </>
    );
};

export default ViewTable;
