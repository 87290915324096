import React, { createContext, useEffect, useState } from 'react';
import { Select, Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PROCESS_ENVIRONMENTS } from '../../../../constants/constants';
import { publishProcess, setSelectedMode } from '../slices/process-details';
import { useNavigate } from 'react-router-dom';

const PublishContext = createContext(null);

const config = {
    title: 'Publish Process',
    content: (
        <>
            <PublishContext.Consumer>
                {(message) => message}
            </PublishContext.Consumer>
        </>
    ),
};

const PublishOptions = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [modal, contextHolder] = Modal.useModal();

    const { process: processDetails, selectedMode, processVersions } = useSelector((state) => state.processDetails);
    const [environmentOptions, setEnvironmentOptions] = useState([]);

    const getEnvtOptions = () => {
        const envtOptions = [];
        for (const [key, value] of Object.entries(PROCESS_ENVIRONMENTS)) {
            let label = t(`process.${value}`);
            let isDisabled = false;
            const processData = processVersions?.find((version) => version.area === key);
            const id = processData?.id ?? null;
            const version = processData?.version ?? '';
            if (!version) {
                isDisabled = true;
            }
            label = `${label} ${version ? `(v${version})` : ''}`
            envtOptions.push({ value: key, label, disabled: isDisabled, id });
        }
        setEnvironmentOptions(envtOptions);
    };

    useEffect(() => {
        if (processVersions?.length) {
            getEnvtOptions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [processVersions]);

    const isDisabled = selectedMode === PROCESS_ENVIRONMENTS.LIVE;
    const buttonLabel = selectedMode === PROCESS_ENVIRONMENTS.DESIGNER ? t('process.publish-staging') : t('process.publish-live');
    const publishMessage = selectedMode === PROCESS_ENVIRONMENTS.DESIGNER ? t('process.publish-message-staging') : t('process.publish-message-live');
 
    return (
        <PublishContext.Provider value={publishMessage}>
            {selectedMode !== PROCESS_ENVIRONMENTS.DESIGNER ? <span className='text-danger mx-4'>{t('process.read-only-mode')}</span> : <></>}
            <Select
                options={environmentOptions}
                style={{ marginRight: 5, width: 160 }}
                value={selectedMode}
                onSelect={(value, option) => {
                    dispatch(setSelectedMode(value));
                    navigate(`/process/details`, { state: { processId: option.id }, replace: true });
                }}
            />

            <Button
                disabled={isDisabled}
                onClick={async () => {
                    const confirmed = await modal.confirm(config);
                    if (confirmed) {
                        dispatch(publishProcess(processDetails.id));
                    }
                }}
            >
                {buttonLabel}
            </Button>
            {contextHolder}
        </PublishContext.Provider>
    );
};

export default PublishOptions;
