import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

export const DeleteModal = ({ showModal = false, handleOk = () => false, handleCancel = () => false }) => {
    const { t } = useTranslation();

    return (
        <Modal
            title={t('delete-modal.title')}
            open={showModal}
            onOk={handleOk}
            onCancel={handleCancel}
            okText={t('action.delete')}
            cancelText={t('action.cancel')}
            okType="danger"
            destroyOnClose
            maskClosable={false}
            centered
        >
            {t('delete-modal.description')}
        </Modal>
    );
};
