import { Form, Input, Select, Table, Button } from 'antd';
import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';

const ATTRIBUTE_TYPE_OPTIONS = [
    { value: 'checkbox', label: 'Checkbox' },
    { value: 'date', label: 'Date' },
    { value: 'datetime', label: 'Date Time' },
    { value: 'number', label: 'Number' },
    { value: 'text', label: 'Text' },
    { value: 'textarea', label: 'Textarea' },
    { value: 'select', label: 'Select' },
];

const EditableTable = ({ form, selectedAttributes, dataSourceList, onChange, headerNameEditable, onAction, processAttributeGroups }) => {
    const getDataSourceText = (record) => {
        if (record?.type !== 'select') {
            return <></>;
        }
        const { selectDataSource } = record;

        switch (selectDataSource?.dataSourceType) {
            case 'staticList':
                return (
                    <div>
                        <div>
                            <b>Type:</b> Static List
                        </div>
                        <div>
                            <b>Options:</b> {record?.selectDataSource?.option?.map((op) => op.label).join(', ')}
                        </div>
                    </div>
                );
            case 'selectDS':
                const dataSource = dataSourceList?.find((ds) => ds?.id === record?.selectDataSource?.name);
                if (!dataSource) {
                    return <></>;
                }
                const label =
                    dataSource?.tableHeaders?.find((th) => th?.key === record?.selectDataSource?.label)?.value || '';
                const value =
                    dataSource?.tableHeaders?.find((th) => th?.key === record?.selectDataSource?.value)?.value || '';
                return (
                    <div>
                        <div>
                            <b>Datasource:</b> {dataSource?.name}
                        </div>
                        <div>
                            <b>Label:</b> {label} <b>Value:</b> {value}
                        </div>
                    </div>
                );
            default:
                return <></>;
        }
    };

    const columns = [
        {
            title: 'Attribute Group',
            dataIndex: 'attributeGroupId',
            render: (_, record) => {
                return (
                    <Form.Item>
                        <Select
                            placeholder="Select Attribute Group"
                            defaultValue={record?.attributeGroupId}
                            style={{ width: 180 }}
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto', zIndex: '100000' }}
                            disabled={record?.disabled}
                            onChange={(value) => onChange(record?.id, 'attributeGroupId', value)}
                            options={processAttributeGroups.map((aGroup) => {
                                return {
                                    label: aGroup?.name,
                                    value: aGroup?.id
                                }
                            })}
                            allowClear
                        />
                        {record?.error?.attributeGroupId ? <span style={{ color: 'red' }}>{record?.error?.attributeGroupId}</span> : ''}
                    </Form.Item>
                );
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => (
                <Form.Item>
                    <Input
                        value={record.name}
                        disabled={record?.disabled}
                        style={{ width: 180 }}
                        placeholder="Enter Attribute Name"
                        onChange={(e) => {
                            onChange(record.id, 'name', e.target.value);
                        }}
                    />
                    {record?.error?.name ? <span style={{ color: 'red' }}>{record?.error?.name}</span> : ''}
                </Form.Item>
            ),
        },
        {
            title: 'Type',
            dataIndex: 'type',
            render: (_, record) => {
                return (
                    <Form.Item>
                        <Select
                            placeholder="Select Attribute Type"
                            defaultValue={record.type}
                            style={{ width: 180 }}
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto', zIndex: '100000' }}
                            disabled={record?.disabled}
                            onChange={(value) => onChange(record.id, 'type', value)}
                            options={ATTRIBUTE_TYPE_OPTIONS}
                        />
                        {record?.error?.type ? <span style={{ color: 'red' }}>{record?.error?.type}</span> : ''}
                    </Form.Item>
                );
            },
        },
        {
            title: 'Options',
            dataIndex: 'attributeOptions',
            render: (_, record) => {
                return record.type === 'select' ? (
                    <Form.Item>
                        <Button disabled={record?.disabled} onClick={() => onAction('chooseDataSource', record)}>
                            {Object.keys(record?.selectDataSource || {}).length
                                ? 'Edit Data Source'
                                : 'Choose Data Source'}
                        </Button>
                        <br />
                        {Object.keys(record?.selectDataSource || {}).length ? getDataSourceText(record) : ''}
                        {record?.error?.dataSourceInfo ? (
                            <span style={{ color: 'red' }}>{record?.error?.dataSourceInfo}</span>
                        ) : (
                            ''
                        )}
                    </Form.Item>
                ) : (
                    <Form.Item
                        rules={[{ required: record.type === 'select', message: 'Attribute Options is required' }]}
                    >
                        <Input
                            style={{ width: 180 }}
                            disabled={record.type !== 'select'}
                            onChange={(event) => onChange(record.id, 'attributeOptions', event.target.value)}
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: 'Comments',
            dataIndex: 'comments',
            key: 'comments',
            render: (_, record) => (
                <Form.Item>
                    <Input
                        placeholder="Enter Comments"
                        value={record?.comments}
                        disabled={record?.disabled}
                        style={{ width: 180 }}
                        onChange={(e) => onChange(record.id, 'comments', e.target.value)}
                    />
                </Form.Item>
            ),
        },
        {
            title: 'Action',
            render: (_, record) => {
                return <DeleteOutlined style={{ paddingBottom: 40 }} onClick={() => onAction('delete', record)} />;
            },
        },
    ];

    return (
        <Table
            dataSource={selectedAttributes}
            columns={columns}
            bordered
            rowClassName={() => 'editable-row'}
            style={{ width: '100%' }}
        />
    );
};

export default EditableTable;
