const SOCKET_RESPONSE = {
    CONNECT: 'connect',
    ERROR: 'error',
    DISCONNECT: 'disconnect',
    JOIN_ROOM: 'join-room',
    CONNECT_ERROR: 'connect_error',
    REFRESH_VIEW: 'refresh_view',
    SHOW_POPUP: 'show_popup',
};

export default SOCKET_RESPONSE;