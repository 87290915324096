import { nodeClassMapping } from '.';
import BaseNode from './BaseNode';
import { NODE_TYPES } from './constant';
import { eventResourceLoaders } from './utils/GenericFunctions';

class ActivityNode extends BaseNode {
    // eslint-disable-next-line no-useless-constructor
    constructor() {
        super();
        this.displayName = 'Activity';
        this.type = 'activity';
        this.methods = {
            loadOptions: eventResourceLoaders,
        };
    }

    getDefaultAttributes() {
        const defaultAttributes = super.getDefaultAttributes();
        return [
            ...defaultAttributes,
            {
                displayName: 'node-details.role',
                name: 'role',
                description: 'node-details.role',
                type: 'select',
                mode: 'multiple',
                default: '',
                required: true,
                typeOptions: {
                    loadOptionsMethod: 'getRoles',
                },
                section: '',
                tab: '',
            },
        ];
    }

    getDefaultNodeData() {
        const classMapping = new nodeClassMapping[NODE_TYPES.SIMPLE_TASK]();
        const defaultNodeData = classMapping.getDefaultNodeData();
        return defaultNodeData;
    }
}
export { ActivityNode as default };
