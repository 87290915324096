import { useState, useRef, useCallback } from "react";

export const useStateWithRef = (initialState) => {
    const [state, _setState] = useState(initialState);
    const ref = useRef(state);
    const setState = useCallback(
        (newState) => {
            if (typeof newState === 'function') {
                _setState(prevState => {
                    const computedState = newState(prevState);
                    ref.current = computedState;
                    return computedState;
                });
            } else {
                ref.current = newState;
                _setState(newState);
            }
        },
        []
    );
    return [state, setState, ref];
};