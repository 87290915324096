import { Button, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '../../components/table/ag-grid';
import { getFormattedDate, showError } from '../../helpers/common';
import { dashboard, forms, tasks } from '../../services/index';
import FormRenderer from './form-renderer';
import { getDefaultAttributes, getTaskTableAttributes } from './utils';
import { NODE_TYPES } from '../process/details/plugins/constant';

const DEFAULT_COLUMNS = [
    {
        headerName: 'Date',
        cellRenderer: (props) => {
            const { data } = props;
            return getFormattedDate(data?.createdAt, true);
        },
    },
];

const DashboardTable = ({ processId, nodeInfo }) => {
    const [taskList, setTaskList] = useState([]);
    const [processAttributes, setProcessAttributes] = useState([]);
    const [showNewTaskModal, setShowNewTaskModal] = useState(false);
    const { t } = useTranslation();
    const [formData, setFormData] = useState('');
    const [selectedRecord, setSelectedRecord] = useState([]);
    const [viewAttributes, setViewAttributes] = useState(null);

    const tableGroupName = nodeInfo?.rfNodes?.map((n) => n.name).join(', ');
    const rfNodeIds = nodeInfo?.rfNodes?.map(n => n.rfNodeId);

    const getTasks = async () => {
        try {
            const res = await tasks.getAllTask(rfNodeIds, processId);
            const {
                taskList: processTasks,
                processAttributes: processAttributeList,
                viewAttributes: viewAttributesList,
            } = res;
            setTaskList(processTasks);
            setProcessAttributes(processAttributeList);
            setViewAttributes(viewAttributesList);
        } catch (err) {
            showError(err?.message);
        }
    };

    useEffect(() => {
        getTasks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDashboardAction = async (payload) => {
        await dashboard.handleDashboardActions(payload);
        await getTasks();
    };

    const onTableAction = async (rec, formId) => {
        if (formId) {
            setSelectedRecord(rec);
            let res = await forms.getFormData(formId);
            setFormData(res?.data);
            setShowNewTaskModal(true);
        }
    };

    const saveSimpleTask = async (subTaskId) => {
        onDashboardAction({
            actionKey: 'save',
            actionData: { subTaskId, processId },
        });
    }

    const getActionColumns = () => {
        return [
            {
                headerName: t('action.action'),
                cellRenderer: (props) => {
                    const { data } = props;
                    const nodeForm = nodeInfo?.rfNodes?.find((node) => node.rfNodeId === data.rfNodeId);
                    return (
                        <div style={{ display: 'flex' }}>
                            {nodeInfo.nodeType === NODE_TYPES.SIMPLE_TASK && (
                                <Button style={{ marginRight: '10px' }} type="primary" onClick={() => saveSimpleTask(data.id)}>
                                    {nodeInfo.action || t('action.done')}
                                </Button>
                            )}
                            {nodeForm?.form && (
                                <Button
                                    style={{ marginRight: '10px' }}
                                    type="primary"
                                    onClick={() => onTableAction(data, nodeForm.form)}
                                >
                                    {t('dashboard.show-form')}
                                </Button>
                            )}
                        </div>
                    );
                },
            },
        ];
    };

    const getTableHeaders = () => {
        const dynamicColumn = getTaskTableAttributes(processAttributes, viewAttributes);
        const actionColumn = getActionColumns();

        return [...DEFAULT_COLUMNS, ...dynamicColumn, ...actionColumn];
    };

    const onSubmit = (actionButton, data = []) => {
        if (actionButton?.action === 'cancel') {
            setShowNewTaskModal(false);
            setFormData('');
            return;
        }
        if (actionButton?.action) {
            const nAttr = {};
            data?.forEach((row) => {
                nAttr[row?.name?.split('_')[0]] = row?.value;
            });
            const attr = { ...getDefaultAttributes(actionButton) };
            onDashboardAction({
                actionKey: actionButton?.action,
                actionData: { subTaskId: selectedRecord?.id, attributes: { ...attr, ...nAttr }, processId },
            });
            setShowNewTaskModal(false);
            setFormData('');
        }
    };

    return (
        <div>
            <h5>{tableGroupName}</h5>
            <Grid columnDefs={getTableHeaders()} rowData={taskList ?? []} />
            {showNewTaskModal ? (
                <Modal
                    title={formData?.name ?? t('dashboard.dashboard')}
                    width={620}
                    open={showNewTaskModal}
                    footer={null}
                    onCancel={() => setShowNewTaskModal(false)}
                    destroyOnClose
                >
                    <FormRenderer onSubmit={onSubmit} formData={formData} formValues={selectedRecord} />
                </Modal>
            ) : (
                ''
            )}
        </div>
    );
};

export default DashboardTable;
