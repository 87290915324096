import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from './message';

import { auth } from '../../services';

const user = JSON.parse(localStorage.getItem('authUser'));

export const login = createAsyncThunk('auth/login', async ({ username, password, navigate }, thunkAPI) => {
    try {
        const data = await auth.signIn({
            username,
            password,
        });
        const user = data?.data;
        navigate('/dashboard');
        localStorage.setItem('authUser', JSON.stringify(user));
        return { user };
    } catch (error) {
        const message = error.message || error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
    }
});

export const logout = createAsyncThunk('auth/logout', async ({ navigate }, thunkAPI) => {
    try {
        await auth.logout();
        navigate('/login');
        localStorage.removeItem('authUser');
        return true;
    } catch (error) {
        const message = error.message || error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
    }
});

const initialState = user ? { isLoggedIn: true, user } : { isLoggedIn: false, user: null };

const authSlice = createSlice({
    name: 'auth',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(login.fulfilled, (state, action) => {
                state.isLoggedIn = true;
                state.user = action.payload.user;
            })
            .addCase(login.rejected, (state, action) => {
                state.isLoggedIn = false;
                state.user = null;
            })
            .addCase(logout.fulfilled, (state, action) => {
                state.isLoggedIn = false;
                state.user = null;
            });
    },
});

const { reducer } = authSlice;
export default reducer;
