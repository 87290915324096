import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createUser, deleteUser, getAllUsers, updateUser } from '../../store/slices/user';
import Breadcrumbs from '../../components/breadcrumb/Breadcrumb';
import {
    Button,
    Card,
    Col,
    Form,
    FormFeedback,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Spinner,
    UncontrolledTooltip,
} from 'reactstrap';
import Grid from '../../components/table/ag-grid';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { getAllRoles } from '../../store/slices/role';
import { getAllTenants } from '../../store/slices/tenant';
import { DeleteModal } from '../../components/delete-modal/DeleteModal';

require('yup-phone');

function UserPage() {
    const { t } = useTranslation();
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [modal, setModal] = useState(false);
    const [searchText, setSearchText] = useState('');

    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            firstName: selectedUser?.firstName || '',
            lastName: selectedUser?.lastName || '',
            userName: selectedUser?.userName || '',
            password: selectedUser?.password || '',
            email: selectedUser?.email || '',
            contactNumber: selectedUser?.contactNumber || '',
            role: selectedUser?.role?.id || '',
            tenant: selectedUser?.tenant?.id || '',
        },
        validationSchema: Yup.object({
            firstName: Yup.string().required(t('error-message.first-name-required')),
            lastName: Yup.string().required(t('error-message.last-name-required')),
            userName: Yup.string().required(t('error-message.username-required')),
            password: Yup.string().when('isEdit', {
                is: (isEdit) => false,
                then: Yup.string()
                    .required(t('error-message.password-required'))
                    .matches(
                        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                        t('error-message.password-validation')
                    ),
            }),
            email: Yup.string().email(t('error-message.invalid-email')).required(t('error-message.email-required')),
            role: Yup.string().required(t('error-message.role-required')),
            tenant: Yup.string().required(t('error-message.tenant-required')),
            // contactNumber: Yup.string().phone(t('error-message.contact-number-invalid'))
        }),
        onSubmit: (values) => {
            const payload = {
                firstName: values.firstName,
                lastName: values.lastName,
                userName: values.userName,
                email: values.email,
                roleId: values.role,
                contactNumber: values.contactNumber,
                tenantId: values.tenant,
            };
            if (values.password) {
                payload.password = values.password;
            }
            if (isEdit && selectedUser?.id) {
                // update user
                dispatch(updateUser({ id: selectedUser?.id, payload, toggle }));
            } else {
                // save new user
                dispatch(createUser({ payload, toggle }));
            }
        },
    });

    const dispatch = useDispatch();
    const { users, loading } = useSelector((state) => state.user);
    const roles = useSelector((state) => state.role.roles);
    const tenants = useSelector((state) => state.tenant.tenants);

    useEffect(() => {
        dispatch(getAllRoles());
        dispatch(getAllTenants());
        dispatch(getAllUsers());
    }, [dispatch]);

    const handleDeleteUser = () => {
        if (selectedUser?.id) {
            dispatch(deleteUser({ id: selectedUser.id }));
            setDeleteModal(false);
        }
    };

    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);
            setSelectedUser(null);
        } else {
            setModal(true);
        }
        validation.resetForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modal]);

    const handleUserClick = useCallback(
        (arg) => {
            const isEditMode = arg ? true : false;
            setSelectedUser(arg);
            setIsEdit(isEditMode);
            toggle();
        },
        [toggle]
    );

    const onClickDelete = (role) => {
        setSelectedUser(role);
        setDeleteModal(true);
    };

    const [columnDefs] = useState([
        {
            headerName: t('user.since'),
            cellRenderer: (props) => {
                const { data } = props;
                return moment(data.createdAt).format('DD.MM.YYYY');
            },
        },
        {
            headerName: t('user.username'),
            field: 'userName',
        },
        {
            headerName: `${t('user.last-name')}, ${t('user.first-name')}`,
            field: 'fullName',
        },
        {
            headerName: t('user.contact-data'),
            cellRenderer: (props) => {
                const { data } = props;
                let contactData = '';
                if (data.contactNumber) {
                    contactData += `${data.contactNumber} `;
                }
                if (data.email) {
                    contactData += `${data.email}`;
                }
                return contactData;
            },
        },
        {
            headerName: t('user.role'),
            field: 'role.name',
        },
        {
            headerName: t('user.tenant'),
            field: 'tenant.name',
        },
        {
            headerName: t('action.action'),
            cellRenderer: (props) => {
                const { data } = props;
                return (
                    <div className="d-flex ag-action-cell">
                        <p onClick={() => handleUserClick(data)} className="text-primary">
                            <i className="fas fa-edit" id="edittooltip" />
                            <UncontrolledTooltip placement="top" target="edittooltip">
                                {t('action.edit')}
                            </UncontrolledTooltip>
                        </p>
                        <p onClick={() => onClickDelete(data)} className="mx-2 text-danger">
                            <i className="fas fa-trash" id="deletetooltip" />
                            <UncontrolledTooltip placement="top" target="deletetooltip">
                                {t('action.delete')}
                            </UncontrolledTooltip>
                        </p>
                    </div>
                );
            },
        },
    ]);

    const handleDeleteCancel = () => {
        setDeleteModal(false);
    };

    return (
        <React.Fragment>
            <DeleteModal
                showModal={deleteModal}
                handleOk={handleDeleteUser}
                handleCancel={handleDeleteCancel}
            />

            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title={t('user.user')} breadcrumbItem={t('user.user')}>
                        <>
                            <Input
                                type="text"
                                className="form-control bg-light border-light rounded w-50"
                                placeholder={`${t('action.search')}...`}
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                            <Button color="primary" onClick={() => handleUserClick()}>
                                {t('user.add-user')}
                            </Button>
                        </>
                    </Breadcrumbs>
                    <Card>
                        <Row>
                            <Col xs={12}>
                                <Grid columnDefs={columnDefs} rowData={users} quickFilterText={searchText} />
                            </Col>
                        </Row>
                    </Card>
                    <Modal isOpen={modal} toggle={toggle} autoFocus={false}>
                        <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? t('user.edit-user') : t('user.add-user')}
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <Row>
                                    <Col className="col-6">
                                        <div className="mb-3">
                                            <Label className="form-label">
                                                {t('user.first-name')}
                                                <sup>*</sup>
                                            </Label>
                                            <Input
                                                name="firstName"
                                                type="text"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.firstName || ''}
                                                invalid={
                                                    validation.touched.firstName && validation.errors.firstName
                                                        ? true
                                                        : false
                                                }
                                                autoFocus
                                            />
                                            {validation.touched.firstName && validation.errors.firstName ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.firstName}
                                                </FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col className="col-6">
                                        <div className="mb-3">
                                            <Label className="form-label">
                                                {t('user.last-name')}
                                                <sup>*</sup>
                                            </Label>
                                            <Input
                                                name="lastName"
                                                type="text"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.lastName || ''}
                                                invalid={
                                                    validation.touched.lastName && validation.errors.lastName
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {validation.touched.lastName && validation.errors.lastName ? (
                                                <FormFeedback type="invalid">{validation.errors.lastName}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-6">
                                        <div className="mb-3">
                                            <Label className="form-label">
                                                {t('user.username')}
                                                <sup>*</sup>
                                            </Label>
                                            <Input
                                                name="userName"
                                                type="text"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                autoComplete="new-password"
                                                value={validation.values.userName || ''}
                                                invalid={
                                                    validation.touched.userName && validation.errors.userName
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {validation.touched.userName && validation.errors.userName ? (
                                                <FormFeedback type="invalid">{validation.errors.userName}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col className="col-6">
                                        <div className="mb-3">
                                            <Label className="form-label">
                                                {t('user.password')}
                                                <sup>*</sup>
                                            </Label>
                                            <Input
                                                name="password"
                                                type="password"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                autoComplete="new-password"
                                                value={validation.values.password || ''}
                                                invalid={
                                                    validation.touched.password && validation.errors.password
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {validation.touched.password && validation.errors.password ? (
                                                <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-6">
                                        <div className="mb-3">
                                            <Label className="form-label">
                                                {t('user.email')}
                                                <sup>*</sup>
                                            </Label>
                                            <Input
                                                name="email"
                                                type="email"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.email || ''}
                                                invalid={
                                                    validation.touched.email && validation.errors.email ? true : false
                                                }
                                            />
                                            {validation.touched.email && validation.errors.email ? (
                                                <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col className="col-6">
                                        <div className="mb-3">
                                            <Label className="form-label">{t('user.phone')}</Label>
                                            <Input
                                                name="contactNumber"
                                                type="text"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.contactNumber || ''}
                                                invalid={
                                                    validation.touched.contactNumber && validation.errors.contactNumber
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {validation.touched.contactNumber && validation.errors.contactNumber ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.contactNumber}
                                                </FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-6">
                                        <div className="mb-3">
                                            <Label className="form-label">
                                                {t('user.role')}
                                                <sup>*</sup>
                                            </Label>
                                            <Input
                                                type="select"
                                                className="form-control form-select"
                                                name="role"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.role || ''}
                                                invalid={
                                                    validation.touched.role && validation.errors.role ? true : false
                                                }
                                            >
                                                <option value="">--select--</option>
                                                {roles.map((role) => (
                                                    <option value={role.id} key={role.id}>
                                                        {role.name}
                                                    </option>
                                                ))}
                                            </Input>
                                            {validation.touched.role && validation.errors.role ? (
                                                <FormFeedback type="invalid">{validation.errors.role}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col className="col-6">
                                        <div className="mb-3">
                                            <Label className="form-label">
                                                {t('user.tenant')}
                                                <sup>*</sup>
                                            </Label>
                                            <Input
                                                type="select"
                                                className="form-control form-select"
                                                name="tenant"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.tenant || ''}
                                                invalid={
                                                    validation.touched.tenant && validation.errors.tenant ? true : false
                                                }
                                            >
                                                <option value="">--select--</option>
                                                {tenants.map((tenant) => (
                                                    <option value={tenant.id} key={tenant.id}>
                                                        {tenant.name}
                                                    </option>
                                                ))}
                                            </Input>
                                            {validation.touched.tenant && validation.errors.tenant ? (
                                                <FormFeedback type="invalid">{validation.errors.tenant}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <Button type="submit" color="success" disabled={loading}>
                                                {!loading && t('action.save')}
                                                {loading && <Spinner size="sm"></Spinner>}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
        </React.Fragment>
    );
}

export default UserPage;
