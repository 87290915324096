import React, { useState } from 'react';
import { Form, Input, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import LanguageDropdown from './language-dropdown/LanguageDropdown';
import ProfileMenu from './profile-menu/ProfileMenu';
import logoSm from '../../assets/images/logo-sm.png';
import logoDark from '../../assets/images/logo-dark.png';
import logoLight from '../../assets/images/logo-light.png';

const Header = () => {
    const [search, setsearch] = useState(false);

    function tToggle() {
        var body = document.body;
        var windowSize = document.documentElement.clientWidth;

        body.classList.toggle('vertical-collpsed');
        body.classList.toggle('sidebar-enable');
        if (windowSize > 991) {
            body.getAttribute('data-sidebar-size') === 'sm' && windowSize > 991
                ? body.setAttribute('data-sidebar-size', 'lg')
                : body.setAttribute('data-sidebar-size', 'sm');
        }
    }
    return (
        <React.Fragment>
            <header id="page-topbar">
                <div className="navbar-header">
                    <div className="d-flex">
                        <div className="navbar-brand-box">
                            <Link to="/" className="logo logo-dark">
                                <span className="logo-sm">
                                    <img src={logoSm} alt="" height="22" />
                                </span>
                                <span className="logo-lg">
                                    <img src={logoDark} alt="" height="20" />
                                </span>
                            </Link>

                            <Link to="/" className="logo logo-light">
                                <span className="logo-sm">
                                    <img src={logoSm} alt="" height="22" />
                                </span>
                                <span className="logo-lg">
                                    <img src={logoLight} alt="" height="20" />
                                </span>
                            </Link>
                        </div>

                        <button
                            type="button"
                            onClick={() => {
                                tToggle();
                            }}
                            className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
                            id="vertical-menu-btn"
                        >
                            <i className="fa fa-fw fa-bars" />
                        </button>
                    </div>

                    <div className="d-flex">
                        <Dropdown
                            className="d-inline-block d-lg-none ms-2"
                            onClick={() => {
                                setsearch(!search);
                            }}
                            type="button"
                        >
                            <DropdownToggle
                                className="btn header-item noti-icon waves-effect"
                                id="page-header-search-dropdown"
                                tag="button"
                            >
                                {' '}
                                <i className="uil-search" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                                <Form className="p-3">
                                    <div className="form-group m-0">
                                        <div className="input-group">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search ..."
                                                aria-label="Recipient's username"
                                            />
                                            <div className="input-group-append">
                                                <Button className="btn btn-primary" type="submit">
                                                    <i className="mdi mdi-magnify"></i>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </DropdownMenu>
                        </Dropdown>

                        <LanguageDropdown />

                        <ProfileMenu />
                    </div>
                </div>
            </header>
        </React.Fragment>
    );
};

export default Header;
