/* eslint-disable import/no-anonymous-default-export */
import request from './request';

const getDashboardData = (mode) => request.get(`dashboard/${mode}`);
const handleDashboardActions = (payload) => request.post('dashboard', payload);

export default {
    getDashboardData,
    handleDashboardActions,
};
