import { NodeResizer, useReactFlow } from 'reactflow';
import NodeLabel from '../../node-edge-label/label';
import ActionsToolbar from '../toolbar/actions';

function Lane({ id, selected, data }) {
    const { deleteElements } = useReactFlow();

    const onNodeAction = (action) => {
        if (action === 'delete') {
            deleteElements({ nodes: [{ id }] });
        }
    };

    return (
        <>
            <ActionsToolbar nodeId={id} onNodeAction={onNodeAction} />
            <NodeLabel placeholder={'Lane'} name={data?.name} id={id} className="lane-label" />
            <NodeResizer color="#ff0071" isVisible={selected} shouldResize={() => false} />
        </>
    );
}

export default Lane;
