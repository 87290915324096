import BaseNode from "../BaseNode";

class PoolNode extends BaseNode {
    constructor() {
        super();
        this.displayName = 'Pool';
        this.description = '';
        this.name = 'pool';
    }
}

export { PoolNode as default };