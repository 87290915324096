import { read, utils } from 'xlsx';
import { v4 as uuidv4 } from 'uuid';

export const readExcelHeaders = (data) => {
    const headers = {};
    const workbook = read(data, { type: 'file' });
    for (const sheetName of workbook.SheetNames) {
        const worksheet = workbook.Sheets[sheetName];
        const options = { header: 1 };
        const sheetData = utils.sheet_to_json(worksheet, options);
        const sheetHeaders = [...new Set(sheetData.shift())];
        
        const header = sheetHeaders.map((name) => ({
            id: uuidv4(),
            name: name?.toLowerCase().split(' ').join('_'),
            isChecked: false,
        }));
        headers[sheetName] = header;
    }
    return headers;
};
