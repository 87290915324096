import React, { useEffect, useState } from 'react';
import { Input, Form, Select, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { datasource } from '../services';
import { useDatasourceContext } from '../modules/datasource-context';

const { Option } = Select;

const Step1 = ({ setStateType }) => {
    const { t } = useTranslation();
    const { state, dispatch } = useDatasourceContext();
    const [dataSourceOptions, setDataSourceOptions] = useState([]);
    const [form] = Form.useForm();

    // fetch available datasources for select field
    useEffect(() => {
        const fetchDataSources = async () => {
            try {
                const dataSourcesResponse = await datasource.getAllAvailableDatasources();
                dispatch({ type: 'SET_DATABASES', databases: dataSourcesResponse });
                const datasources = Object.keys(dataSourcesResponse).map((database, index) => ({
                    id: database,
                }));
                setDataSourceOptions(datasources);
            } catch (error) {}
        };

        fetchDataSources();
    }, [dispatch]);

    useEffect(() => {
        const { name, type } = state.formData.step1;

        form.setFieldsValue({
            name: name,
            type: type,
        });
    }, [form, state]);

    const onFinish = (values) => {
        dispatch({ type: 'UPDATE_DATA', step: 'step1', data: values });
        dispatch({ type: 'NEXT_STEP' });
    };

    const handlee = (arg) => {
        setStateType(arg);
    };

    return (
        <Form form={form} name="step1" onFinish={onFinish}>
            <Form.Item label={t('datasource.name')} name="name" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item label="Type" name="type" rules={[{ required: true }]}>
                <Select onChange={handlee}>
                    {dataSourceOptions.map((option) => (
                        <Option key={option.id} value={option.id}>
                            {option.id}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button type="primary" htmlType="submit">
                    Next
                </Button>
            </div>
        </Form>
    );
};

export default Step1;
