import { Button, Tabs } from 'antd';
import { useEffect, useState } from 'react';
import DashboardTable from './dashboard-table';
import DashboardForm from './dashboard-form';
import { NODE_TYPES } from '../process/details/plugins/constant';
import { SHOW_FORM_OPTIONS } from '../process/details/plugins/activity/form-editing/constant';

export const NodesTab = ({ process = null, onDashboardAction = () => false }) => {
    const [tabItemsArray, setTabItemsArray] = useState([]);
    const [rootArray, setRootArray] = useState([]);
    const [activeKey, setActiveKey] = useState('');

    const getFormEditingContent = (element) => {
        if (element?.['show-form']?.mode === SHOW_FORM_OPTIONS.DASHBOARD_FORM) {
            return <DashboardForm processId={process.id} nodeInfo={element} />;
        } else if (element?.['show-form']?.mode === SHOW_FORM_OPTIONS.TASK_LIST) {
            return <DashboardTable processId={process.id} nodeInfo={element} />;
        }
        return <></>;
    };

    const initializeTabInfo = () => {
        const ltabItemsArray = [];
        const lrootArray = [];
        const result = process?.nodes || [];
        if (result?.length) {
            result?.forEach((element) => {
                const tabName = element?.['dashboard-settings']?.tabName;
                const existingTab = ltabItemsArray.find((item) => item.key === tabName);
                let children = null;
                if (element?.nodeType === NODE_TYPES.START_NODE) {
                    children = (
                        <Button
                            style={{ marginBottom: '10px' }}
                            onClick={() => {
                                onDashboardAction({
                                    actionKey: 'create-task',
                                    actionData: { processId: process.id, rfNodeId: element?.rfNodeId },
                                });
                            }}
                        >
                            {element?.['dashboard-button-label'] || element?.name}
                        </Button>
                    );
                }
                if ([NODE_TYPES.FORM_EDITING].includes(element?.nodeType)) {
                    children = getFormEditingContent(element);
                }
                if ([NODE_TYPES.SIMPLE_TASK].includes(element?.nodeType)) {
                    children = <DashboardTable processId={process.id} nodeInfo={element} />;
                }

                if (tabName) {
                    if (existingTab) {
                        existingTab.children.push(children);
                    } else {
                        ltabItemsArray?.push({
                            key: tabName,
                            label: tabName,
                            children: [children],
                        });
                    }
                } else {
                    lrootArray.push(children);
                }
            });
        }
        setTabItemsArray([...ltabItemsArray]);
        setRootArray([...lrootArray]);
        if (ltabItemsArray?.length) {
            setActiveKey(ltabItemsArray[0]?.key);
        }
    };

    useEffect(() => {
        if (process) {
            initializeTabInfo();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [process]);

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
                <Tabs
                    activeKey={activeKey}
                    items={tabItemsArray}
                    onChange={(aKey) => {
                        setActiveKey(aKey);
                    }}
                    destroyInactiveTabPane
                />
            </div>
            <div>{rootArray}</div>
        </div>
    );
};
