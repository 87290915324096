import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { showError } from '../../helpers/common';
import { process, processFlow } from '../../services';

import { addEdge, applyEdgeChanges, applyNodeChanges } from 'reactflow';

const initialState = {
    selectedProcess: {},
    reactFlowInstance: null,
    newNodeData: {},
    attributes: [],
    openSidebar: false,
    loading: false,
    error: '',

    nodes: [],
    edges: [],
    viewPort: {},
};

export const getProcessFlow = createAsyncThunk('process-flow/get', async (processId, { rejectWithValue }) => {
    try {
        const res = await processFlow.getProcessFlow(processId);
        return res.data;
    } catch (error) {
        const message = error.message || error.toString();
        showError(message);
        return rejectWithValue(message);
    }
});

export const updateProcessFlow = createAsyncThunk(
    'process-flow/update',
    async ({id, payload}, { rejectWithValue }) => {
        try {
            await processFlow.updateProcessFlow(id, payload);
            return true;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const getProcessAttributeList = createAsyncThunk('process-attribute/get', async (processId, { rejectWithValue }) => {
    try {
        const res = await process.getProcessAttributeList(processId);
        return res.data;
    } catch (error) {
        const message = error.message || error.toString();
        showError(message);
        return rejectWithValue(message);
    }
});

export const createAttributes = createAsyncThunk('attributes/create', async ({id, payload}, { rejectWithValue }) => {
    try {
        const res = await process.addAttributes(id, payload);
        return res.data;
    } catch (error) {
        const message = error.message || error.toString();
        showError(message);
        return rejectWithValue(message);
    }
});

const processFlowSlice = createSlice({
    name: 'process-flow',
    initialState,
    reducers: {
        showSidebar: (state, action) => {
            state.openSidebar = true;
            state.newNodeData = action.payload;
        },
        hideSidebar: (state) => {
            state.openSidebar = false;
        },
        onNodeAdd: (state, action) => {
            state.nodes = state.nodes.concat(action.payload);
            state.openSidebar = true;
        },
        onNodesUpdated: (state, action) => {
            applyNodeChanges(action.payload, state.nodes);
            state.openSidebar = false;
        },
        onEdgesChange: (state, action) => {
            state.edges = applyEdgeChanges(action.payload, state.edges);
        },
        onConnect: (state, action) => {
            state.edges = addEdge(action.payload, state.edges);
        },
        setReactFlowInstance: (state, action) => {
            state.reactFlowInstance = action.payload;
        },
        onSaveFormState: (state, action) => {
            state.newNodeData = {
                ...state.newNodeData,
                ...action.payload,
            };
            state.openSidebar = false;
        },
        setProcess: (state, action) => {
            state.selectedProcess = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getProcessFlow.pending, (state, action) => {
            state.loading = true;
        }).addCase(getProcessFlow.fulfilled, (state, action) => {
            state.loading = false;
            const { nodes, edges, viewPort } = action.payload;
            state.nodes = nodes;
            state.edges = edges;
            state.viewPort = viewPort;
        }).addCase(getProcessFlow.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        }).addCase(updateProcessFlow.pending,(state, action) => {
            state.loading = true;
        }).addCase(updateProcessFlow.fulfilled, (state, action) => {
            state.loading = false;
            const { nodes, edges } = action.payload;
            state.nodes = nodes;
            state.edges = edges;
        }).addCase(updateProcessFlow.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        }).addCase(getProcessAttributeList.pending, (state, action) => {
            state.loading = true;
        }).addCase(getProcessAttributeList.fulfilled, (state, action) => {
            state.loading = false;
            state.attributes = action.payload;
        }).addCase(getProcessAttributeList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        })
    }
});

export const {
    showSidebar,
    hideSidebar,
    onNodeAdd,
    onEdgesChange,
    onNodesUpdated,
    onConnect,
    setReactFlowInstance,
    onSaveFormState,
    setProcess,
} = processFlowSlice.actions;
const { reducer } = processFlowSlice;
export default reducer;
