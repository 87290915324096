import React, { useState } from 'react';
import { Modal, Space, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import AddAttributeGroupModal from '../attribute-groups';
import AttributeTable from './attributeTable';
import AttributeModal from './attributeModal';
import { useSelector } from 'react-redux';

export const AttributeDetailsModal = ({ showModal = false, handleCancel }) => {
    const [showAddAttributeGroup, setShowAddAttributeGroup] = useState(false);
    const [showAttributeModal, setShowAttributeModal] = useState(false);
    const { t } = useTranslation();
    const { isInteractionDisabled } = useSelector((state) => state.processDetails);

    return (
        <Modal
            width={'70vw'}
            title={t('action.attributes')}
            open={showModal}
            onCancel={handleCancel}
            destroyOnClose
            footer={
                <Space>
                    <Button type="default" onClick={handleCancel}>
                        {t('action.cancel')}
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => setShowAddAttributeGroup(true)}
                        disabled={isInteractionDisabled}
                    >
                        Add Attribute Group
                    </Button>
                    <Button type="primary" onClick={() => setShowAttributeModal(true)} disabled={isInteractionDisabled}>
                        Add Attributes
                    </Button>
                </Space>
            }
            centered
        >
            <AttributeTable onChange={() => console.log('onChange')} onAction={() => console.log('onAction')} />

            <AddAttributeGroupModal
                showAddAttributeGroup={showAddAttributeGroup}
                setShowAddAttributeGroup={setShowAddAttributeGroup}
                editMode={false}
            />

            <AttributeModal showAddAttribute={showAttributeModal} setShowAddAttribute={setShowAttributeModal} />
        </Modal>
    );
};
