import { Button, Form, Input, Select, Table } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteModal } from '../../../components/delete-modal/DeleteModal';
import {
    deleteAttribute,
    deleteAttributeGroup,
    toggleAttributeEditMode,
    updateAttribute,
    updateAttributeData,
    toggleAttributeGroupEditMode,
    updateAttributeGroupData,
    updateAttributeGroup,
} from '../details/slices/process-details';
import { showError } from '../../../helpers/common';
import AddAttributeGroupModal from '../attribute-groups';
import DataSourceSelectionModal from '../../../components/fields/excel/DataSourceSelectionModal';

const ATTRIBUTE_TYPE_OPTIONS = [
    { value: 'checkbox', label: 'Checkbox' },
    { value: 'date', label: 'Date' },
    { value: 'datetime', label: 'Date Time' },
    { value: 'number', label: 'Number' },
    { value: 'text', label: 'Text' },
    { value: 'textarea', label: 'Textarea' },
    { value: 'select', label: 'Select' },
];

const AttributeTable = ({ onChange }) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedAttributeGroupId, setSelectedAttributeGroupId] = useState(null);
    const [selectedAttributeId, setSelectedAttributeId] = useState(null);
    const [showAddAttributeGroup, setShowAddAttributeGroup] = useState(false);
    const [attributeData, setAttributeData] = useState({});
    const [showEditDataSourceModal, setShowEditDataSourceModal] = useState(false);
    const dispatch = useDispatch();
    const { attributes, attributeGroups, dataSources, process, isInteractionDisabled } = useSelector((state) => state.processDetails);

    const handleDeleteAttributeGroup = (id) => {
        setShowDeleteModal(true);
        setSelectedAttributeGroupId(id);
    };

    const handleDeleteAttribute = (id) => {
        setShowDeleteModal(true);
        setSelectedAttributeId(id);
    };

    const cancelDeleteModal = () => {
        setShowDeleteModal(false);
        setSelectedAttributeGroupId(null);
    };

    const onAction = async (actionType, record) => {
        try {
            if (actionType === 'updateAttribute') {
                if (record.error && Object.keys(record.error).length) {
                    return;
                }
                const payload = {
                    id: record?.id,
                    name: record?.name,
                    type: record?.type,
                    comments: record?.comments,
                    processId: process.id,
                };
                if (record?.selectDataSource) {
                    if (record?.selectDataSource?.dataSourceType === 'staticList') {
                        payload.selectDataSource = {
                            dataSourceType: record?.selectDataSource?.dataSourceType,
                            option: record?.selectDataSource?.option,
                        };
                    }
                    if (record?.selectDataSource?.dataSourceType === 'selectDS') {
                        payload.selectDataSource = {
                            dataSourceType: record?.selectDataSource?.dataSourceType,
                            dataSourceName: record?.selectDataSource?.name,
                            label: record?.selectDataSource?.label,
                            value: record?.selectDataSource?.value,
                        };
                    }
                }
                dispatch(updateAttribute({ id: record.id, payload }));
            }
            if (actionType === 'updateAttributeGroup') {
                const payload = {
                    name: record?.name,
                    processId: process.id,
                };
                dispatch(updateAttributeGroup({ id: record.id, payload }));
            }
            if (actionType === 'chooseDataSource') {
                setAttributeData(record);
                setShowEditDataSourceModal(true);
            }
        } catch (err) {
            showError(err?.message);
        }
    };

    const handleDelete = async () => {
        try {
            if (selectedAttributeGroupId) {
                await dispatch(deleteAttributeGroup(selectedAttributeGroupId));
            }
            if (selectedAttributeId) {
                await dispatch(deleteAttribute(selectedAttributeId));
            }
            setShowDeleteModal(false);
        } catch (error) {}
    };

    const getColumnContent = (record) => {
        const dSource = dataSources?.find(
            (ds) => ds?.id === (record?.selectDataSource?.name || record?.selectDataSource?.dataSourceName)
        );
        const label = dSource?.tableHeaders?.find((th) => th?.key === record?.selectDataSource?.label)?.value || '';
        const value = dSource?.tableHeaders?.find((th) => th?.key === record?.selectDataSource?.value)?.value || '';

        if (record?.selectDataSource?.dataSourceType === 'staticList') {
            return (
                <div>
                    <div>
                        <b>Type:</b> Static List
                    </div>
                    <div>
                        <b>Options:</b> {record?.selectDataSource?.option?.map((op) => op.label).join(', ')}
                    </div>
                </div>
            );
        }
        if (record?.selectDataSource?.dataSourceType === 'selectDS') {
            return (
                <div>
                    <div>Datasource: {dSource?.name}</div>
                    <div>
                        Label: {label} Value: {value}
                    </div>
                </div>
            );
        }
    };

    const getSelectContent = (record) => {
        if (record.type === 'select') {
            return (
                <Form.Item>
                    <Button disabled={record?.disabled} onClick={() => onAction('chooseDataSource', record)}>
                        {Object.values(record?.selectDataSource || {}).some((re) => re)
                            ? 'Edit Data Source'
                            : 'Choose Data Source'}
                    </Button>
                    {Object.keys(record?.selectDataSource || {}).length ? getColumnContent(record) : ''}
                    {record?.error?.selectDataSource ? (
                        <span style={{ color: 'red' }}>{record?.error?.selectDataSource}</span>
                    ) : (
                        ''
                    )}
                </Form.Item>
            );
        } else {
            return (
                <Form.Item rules={[{ required: record.type === 'select', message: 'Attribute Options is required' }]}>
                    <Input
                        style={{ width: 180 }}
                        disabled={record.type !== 'select'}
                        onChange={(event) => onChange(record.id, 'attributeOptions', event.target.value)}
                    />
                </Form.Item>
            );
        }
    };

    const handleEditAttributeGroup = (record) => {
        setShowAddAttributeGroup(true);
        setAttributeData(record);
    };

    const baseColumns = [
        {
            title: 'Attribute Group',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => {
                return record?.editMode ? (
                    <Form.Item>
                        <Input
                            value={record.name}
                            disabled={record?.disabled}
                            style={{ width: 180 }}
                            placeholder="Enter Attribute Group Name"
                            onChange={(e) => {
                                const payload = {
                                    id: record.id,
                                    key: 'name',
                                    value: e.target.value,
                                };
                                dispatch(updateAttributeGroupData(payload));
                            }}
                        />
                        {record?.error?.name ? <span style={{ color: 'red' }}>{record?.error?.name}</span> : ''}
                    </Form.Item>
                ) : (
                    <span>{record?.name}</span>
                );
            },
        },
        {
            title: 'Action',
            render: (_, record) => {
                const isAttributeExist = attributes?.findIndex((att) => att?.attributeGroupId === record.id);
                const isDisabled = !record.id || isAttributeExist > -1;
                return (
                    <div className="d-flex ag-action-cell">
                        {record?.editMode ? (
                            <div>
                                <span
                                    style={{ color: 'blue', cursor: 'pointer' }}
                                    onClick={() => {
                                        onAction('updateAttributeGroup', record);
                                        dispatch(
                                            toggleAttributeGroupEditMode({ attributeId: record.id, editMode: false })
                                        );
                                    }}
                                >
                                    Update
                                </span>
                                <span
                                    style={{ marginLeft: 5, cursor: 'pointer' }}
                                    onClick={() =>
                                        dispatch(
                                            toggleAttributeGroupEditMode({ attributeId: record.id, editMode: false })
                                        )
                                    }
                                >
                                    Cancel
                                </span>
                            </div>
                        ) : (
                            <>
                                <i
                                    className={`fas fa-edit mx-2 ${!record.id ? 'fa-disabled' : 'fa-enabled'}`}
                                    id="edittooltip"
                                    title="Edit"
                                    onClick={() =>
                                        dispatch(
                                            toggleAttributeGroupEditMode({ attributeId: record.id, editMode: true })
                                        )
                                    }
                                />
                                <i
                                    className={`fas fa-trash fa-danger ${isDisabled ? 'fa-disabled' : 'fa-enabled'}`}
                                    id="deletetooltip"
                                    title="Delete"
                                    onClick={() => handleDeleteAttributeGroup(record.id)}
                                />
                            </>
                        )}
                    </div>
                );
            },
        },
    ];

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => {
                return record?.editMode ? (
                    <Form.Item>
                        <Input
                            value={record.name}
                            disabled={record?.disabled}
                            style={{ width: 180 }}
                            placeholder="Enter Attribute Name"
                            onChange={(e) => {
                                const payload = {
                                    id: record.id,
                                    key: 'name',
                                    value: e.target.value,
                                };
                                dispatch(updateAttributeData(payload));
                            }}
                        />
                        {record?.error?.name ? <span style={{ color: 'red' }}>{record?.error?.name}</span> : ''}
                    </Form.Item>
                ) : (
                    <span>{record?.name}</span>
                );
            },
        },
        {
            title: 'Type',
            dataIndex: 'type',
            render: (_, record) => {
                return record?.editMode ? (
                    <Form.Item>
                        <Select
                            placeholder="Select Attribute Type"
                            defaultValue={record.type}
                            style={{ width: 180 }}
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto', zIndex: '100000' }}
                            disabled={record?.disabled}
                            onChange={(value) => {
                                const payload = {
                                    id: record.id,
                                    key: 'type',
                                    value,
                                };
                                dispatch(updateAttributeData(payload));
                            }}
                            options={ATTRIBUTE_TYPE_OPTIONS}
                        />
                        {record?.error?.type ? <span style={{ color: 'red' }}>{record?.error?.type}</span> : ''}
                    </Form.Item>
                ) : (
                    <span>{record?.type}</span>
                );
            },
        },
        {
            title: 'Options',
            dataIndex: 'selectDataSource',
            render: (_, record) => {
                return record?.editMode
                    ? getSelectContent(record)
                    : Object.keys(record?.selectDataSource || {}).length
                    ? getColumnContent(record)
                    : '';
            },
        },
        {
            title: 'Comments',
            dataIndex: 'comments',
            key: 'comments',
            render: (_, record) => {
                return record?.editMode ? (
                    <Form.Item>
                        <Input
                            placeholder="Enter Comments"
                            value={record?.comments}
                            disabled={record?.disabled}
                            style={{ width: 180 }}
                            onChange={(e) => {
                                const payload = {
                                    id: record.id,
                                    key: 'comments',
                                    value: e.target.value,
                                };
                                dispatch(updateAttributeData(payload));
                            }}
                        />
                    </Form.Item>
                ) : (
                    <span>{record?.comments}</span>
                );
            },
        },
        {
            title: 'Action',
            render: (_, record) => {
                return (
                    <div className="d-flex ag-action-cell">
                        {record?.editMode ? (
                            <>
                                <span
                                    style={{ color: 'blue', cursor: 'pointer' }}
                                    onClick={() => onAction('updateAttribute', record)}
                                >
                                    Update
                                </span>
                                <span
                                    style={{ marginLeft: 5, cursor: 'pointer' }}
                                    onClick={() =>
                                        dispatch(toggleAttributeEditMode({ attributeId: record.id, editMode: false }))
                                    }
                                >
                                    Cancel
                                </span>
                            </>
                        ) : (
                            <i
                                className={`fas fa-edit ${isInteractionDisabled ? 'fa-disabled' : 'fa-enabled'}`}
                                id="edittooltip"
                                title="Edit"
                                onClick={() =>
                                    dispatch(toggleAttributeEditMode({ attributeId: record.id, editMode: true }))
                                }
                            />
                        )}
                        <i
                            className={`fas fa-edit mx-2 ${isInteractionDisabled ? 'fa-disabled' : 'fa-enabled'}`}
                            id="updateAttributeGrouptooltip"
                            title="Update Attribute Group"
                            onClick={() => {
                                handleEditAttributeGroup(record);
                            }}
                        />
                        <i
                            className={`fas fa-trash fa-danger ${isInteractionDisabled ? 'fa-disabled' : 'fa-enabled'}`}
                            id="deletetooltip"
                            title="Delete"
                            onClick={() => handleDeleteAttribute(record.id)}
                        />
                    </div>
                );
            },
        },
    ];

    const expandedRowRender = (info) => {
        const data = [];
        attributes?.forEach((aGroup) => {
            if (aGroup?.attributeGroupId == info?.id) {
                data.push(aGroup);
            }
        });
        return <Table columns={columns} dataSource={data} pagination={false} rowKey={(record) => record.id} />;
    };

    const getBaseData = () => {
        return [
            ...attributeGroups.map((aGroup) => {
                return {
                    ...aGroup,
                    key: aGroup?.id,
                };
            }),
            {
                id: null,
                key: null,
                name: 'Others',
            },
        ];
    };

    const onSaveDataSource = (dataSource) => {
        dispatch(
            updateAttributeData({
                id: attributeData.id,
                key: 'selectDataSource',
                value: dataSource,
            })
        );
        setShowEditDataSourceModal(false);
    };

    return (
        <>
            <Table
                columns={baseColumns}
                expandable={{ expandedRowRender }}
                dataSource={getBaseData()}
                bordered
                size="small"
                scroll={{
                    y: 240,
                }}
                rowKey={(record) => record.id}
            />
            <DeleteModal showModal={showDeleteModal} handleOk={handleDelete} handleCancel={cancelDeleteModal} />

            <AddAttributeGroupModal
                showAddAttributeGroup={showAddAttributeGroup}
                setShowAddAttributeGroup={setShowAddAttributeGroup}
                editMode={true}
                initialValues={attributeData}
            />

            {showEditDataSourceModal && (
                <DataSourceSelectionModal
                    show={showEditDataSourceModal}
                    dataSourceInfo={attributeData?.selectDataSource || {}}
                    onSave={onSaveDataSource}
                    onCancel={() => setShowEditDataSourceModal(false)}
                />
            )}
        </>
    );
};

export default AttributeTable;
