import React, { useEffect } from 'react';
import withRouter from '../../components/common/withRouter';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import { useSelector } from 'react-redux';

const Layout = (props) => {
    const {
        isPreloader,
        leftSideBarTheme,
    } = useSelector((state) => ({
        isPreloader: state.layout.isPreloader,
        leftSideBarType: state.layout.leftSideBarType,
        showRightSidebar: state.layout.showRightSidebar,
        leftSideBarTheme: state.layout.leftSideBarTheme,
    }));

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const pathName = props.router.location.pathname;

    useEffect(() => {
        if (isPreloader === true) {
            document.getElementById('preloader').style.display = 'block';
            document.getElementById('status').style.display = 'block';

            setTimeout(function () {
                document.getElementById('preloader').style.display = 'none';
                document.getElementById('status').style.display = 'none';
            }, 2500);
        } else {
            document.getElementById('preloader').style.display = 'none';
            document.getElementById('status').style.display = 'none';
        }
    }, [isPreloader, pathName]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <React.Fragment>
            <div id="preloader">
                <div id="status">
                    <div className="spinner">
                        <i className="uil-shutter-alt spin-icon"></i>
                    </div>
                </div>
            </div>
            <div id="layout-wrapper">
                <Header />
                <Sidebar
                    theme={leftSideBarTheme}
                    isMobile={isMobile}
                />
                <div className="main-content">{props.children}</div>
                <Footer />
            </div>
        </React.Fragment>
    );
};

export default withRouter(Layout);
