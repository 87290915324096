/* eslint-disable import/no-anonymous-default-export */
import request from '../../../services/request';

const getAllAvailableDatasources = () => request.get('datasources/list/all');
const getOneAvailableDatasource = (id) => request.get(`datasources/list/${id}`);
const getAllDatasources = (type) => {
    const queryParams = type ? `?type=${type}` : '';
    return request.get(`datasources${queryParams}`);
};
const getDatasource = (id) => request.get(`datasources/${id}`);
const addDatasource = (payload) => request.post('datasources', payload);
const updateDatasource = (id, payload) => request.put(`datasources/${id}`, payload);
const deleteDatasource = (id) => request.delete(`datasources/${id}`);
const testReader = (payload) => request.post(`datasources/testreader/`, payload);
const testWriter = (payload) => request.post(`datasources/testwriter/`, payload);
const testApi = (payload) => request.post('datasources/testapi/', payload);

export default  {
    getOneAvailableDatasource,
    getAllAvailableDatasources,
    getAllDatasources,
    getDatasource,
    addDatasource,
    updateDatasource,
    deleteDatasource,
    testReader,
    testWriter,
    testApi
}
