import { NodeToolbar, useReactFlow } from 'reactflow';
import { DeleteModal } from '../../../../../components/delete-modal/DeleteModal';
import { useState } from 'react';
import { NODE_TYPES } from '../../plugins/constant';
import { useTranslation } from 'react-i18next';
import { App } from 'antd';
import { useSelector } from 'react-redux';

const checkIfNodeIsDeletable = (nodeInfo, nodes, intersections) => {
    const returnObject = {
        isNodeDeletable: true,
        errorMessage: '',
    };

    const { nodeType } = nodeInfo.data;

    const hasElements = () => {
        return intersections.some(
            (intersection) =>
                intersection.data.nodeType !== NODE_TYPES.POOL && intersection.data.nodeType !== NODE_TYPES.LANE
        );
    };

    switch (nodeType) {
        case NODE_TYPES.START_NODE:
            returnObject.isNodeDeletable = false;
            returnObject.errorMessage = 'action-toolbar.start-node-delete-error';
            break;
        case NODE_TYPES.POOL:
            const isAnotherPoolPresent = nodes.some(
                (node) => node.data.nodeType === NODE_TYPES.POOL && node.id !== nodeInfo.id
            );
            if (!isAnotherPoolPresent) {
                returnObject.isNodeDeletable = false;
                returnObject.errorMessage = 'action-toolbar.last-pool-node-delete-error';
            } else if (hasElements()) {
                returnObject.isNodeDeletable = false;
                returnObject.errorMessage = 'action-toolbar.pool-node-has-elements-error';
            }
            break;
        case NODE_TYPES.LANE:
            if (hasElements()) {
                returnObject.isNodeDeletable = false;
                returnObject.errorMessage = 'action-toolbar.lane-node-has-elements-error';
            }
            break;
        default:
            break;
    }
    return returnObject;
};

function ActionsToolbar({ nodeId, onNodeAction = () => false }) {
    const { t } = useTranslation();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const { modal } = App.useApp();

    const { getNode, getNodes, getIntersectingNodes } = useReactFlow();
    const nodeInfo = getNode(nodeId);
    const intersections = getIntersectingNodes(nodeInfo);
    const nodes = getNodes();

    const { isInteractionDisabled } = useSelector((state) => state.processDetails);

    const handleDelete = () => {
        const { isNodeDeletable, errorMessage } = checkIfNodeIsDeletable(nodeInfo, nodes, intersections);
        if (!isNodeDeletable) {
            modal.error({
                title: t('action-toolbar.error'),
                content: t(errorMessage),
            });
            return;
        }
        setShowDeleteModal(true);
    };

    const onSubmit = () => {
        onNodeAction('delete');
        setShowDeleteModal(false);
    };

    const handleCancel = () => {
        setShowDeleteModal(false);
    };

    return (
        <NodeToolbar className="nodrag" offset={24}>
            <button
                className={`color-swatch ${isInteractionDisabled ? 'fa-disabled' : ''}`}
                onClick={handleDelete}
                disabled={isInteractionDisabled}
            >
                <i className={`fas fa-trash fa-danger`}></i>
            </button>
            <DeleteModal showModal={showDeleteModal} handleOk={onSubmit} handleCancel={handleCancel} />
        </NodeToolbar>
    );
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => (
    <App>
        <ActionsToolbar {...props} />
    </App>
);
