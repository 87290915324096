import { datasource } from "../services/index";
import { connectionHandler } from "../../utils/services";

const getDatasources = () => {
    return datasource.getAllDatasources();
}

const testWriter = (payload) => {
    return datasource.testWriter(payload);
}

const getTablesFromDatasource = (payload) => {
    return connectionHandler.getTablesFromDatasource(payload);
}

const getTableContent = (payload) => {
    return connectionHandler.getTableContentFromDatasource(payload);
}

const getQueryResult = (payload) => {
    return connectionHandler.getQueryResulFromDatasource(payload);
}

export {
    getDatasources,
    getTablesFromDatasource,
    getTableContent,
    getQueryResult,
    testWriter,
}
