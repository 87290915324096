import { useState, useEffect } from 'react';
import { Input } from 'antd';

export const InputField = ({ fieldInfo, form, initialValue, type }) => {
    const [value, setValue] = useState(initialValue || []);

    useEffect(() => {
        form.setFieldValue(fieldInfo?.name, initialValue);
        setValue(initialValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue]);

    const handleChange = (e) => {
        
        // If condition denotes that it is used for handling action sub componenet in node form
        if (fieldInfo?.actionKey) {
            form.setFieldValue(['actions', fieldInfo?.actionKey, fieldInfo?.name], e.target.value);
            form.validateFields([['actions', fieldInfo?.actionKey, fieldInfo?.name]]);
        } else {
            form.setFieldValue(fieldInfo?.name, e.target.value);
            form.validateFields([fieldInfo?.name]);
        }
        setValue(e.target.value);
    }

    return <Input value={value} onChange={handleChange} placeholder={fieldInfo?.placeholder} type={type} />;
}