export const NODE_TYPES = {
    // gateway nodes
    EXCLUSIVE_GATEWAY: 'exclusive-gateway',
    INCLUSIVE_GATEWAY: 'inclusive-gateway',
    PARALLEL_GATEWAY: 'parallel-gateway',
    COMPLEX_GATEWAY: 'complex-gateway',
    MERGE: 'merge',

    // event nodes
    START_NODE: 'start-node',
    EVENT: 'event',
    END: 'end',

    // activity nodes
    ACTIVITY: 'activity',
    FORM_EDITING: 'form-editing',
    SIMPLE_TASK: 'simple-task',
    // DB_READER: 'db-reader',
    // DB_WRITER: 'db-writer',
    // REST_API: 'rest-api',

    // default
    POOL: 'pool',
    LANE: 'lane'
}
