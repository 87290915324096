import React from 'react';
import { UndoOutlined } from '@ant-design/icons';

const Globals = {
    DEFAULT_PLACEHOLDER: 'Click to edit',
    DEFAULT_UNDO_TOOLTIP_TEXT: 'Undo',
    DEFAULT_SAVE_TOOLTIP_TEXT: 'Save',
    DEFAULT_UNDO_ICON: <UndoOutlined />,
    DEFAULT_INPUT_MAX_LENGTH: 155,
};

export default Object.freeze(Globals);
