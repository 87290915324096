/* eslint-disable import/no-anonymous-default-export */
import request from '../../../services/request';

const getApiData = (payload) => request.post('getApiData', payload);
const testConnection = (payload) => request.post('testConnection', payload);
const getTables = (payload) => request.post('getTables', payload);
const getTablesFromDatasource = (payload) => request.post('getTablesFromDatasource', payload);
const getTableContent = (payload) => request.post('getTableContent', payload);
const getTableContentFromDatasource = (payload) => request.post('getTableContentFromDatasource', payload);
const getQueryResult = (payload) => request.post('getQueryResult', payload);
const getQueryResulFromDatasource = (payload) => request.post('getQueryResultFromDatasource', payload);

export default  {
    getApiData,
    testConnection,
    getTables,
    getTablesFromDatasource,
    getTableContent,
    getTableContentFromDatasource,
    getQueryResult,
    getQueryResulFromDatasource
}
