import { Card, Select } from 'antd';
import { useEffect, useState } from 'react';
import { Handle, NodeResizer, Position, useKeyPress, useReactFlow } from 'reactflow';
import { useNodeClick } from '../../hooks/useNodeClick';
import ActionsToolbar from '../toolbar/actions';
import ProcessStepToolbar from '../toolbar/nodes';
import { nodeClassMapping } from '../../plugins';
import { useDispatch, useSelector } from 'react-redux';
import { saveFlow } from '../../slices/process-details';
import NodeLabel from '../../node-edge-label/label';

const ACTIVITY_TYPES = [
    {
        key: 'simple-task',
        value: 'Simple Task',
    },
    {
        key: 'form-editing',
        value: 'Form Editing',
    },
];

function Activity({ id, data, selected }) {
    const { name, nodeType } = data;
    const { setNodes, deleteElements } = useReactFlow();
    const [isVisible, setIsVisible] = useState(false);
    const [selectedNodeClass, setSelectedNodeClass] = useState(null);

    const shiftKeyPressed = useKeyPress('Shift');
    const handleStyle = { backgroundColor: 'black' };
    const dispatch = useDispatch();
    const { isInteractionDisabled } = useSelector((state) => state.processDetails);

    const onNodeAction = (action) => {
        if (action === 'delete') {
            deleteElements({ nodes: [{ id }] });
        }
    };

    const hideContextMenu = () => {
        setIsVisible(false);
    };

    const updateNodeData = (updatedData) => {
        setNodes((nodes) =>
            nodes.map((node) => {
                if (node.id === id) {
                    return {
                        ...node,
                        data: {
                            ...updatedData,
                        },
                    };
                }

                return node;
            })
        );
        setTimeout(() => {
            dispatch(saveFlow());
        });
    };

    const handleChange = (value) => {
        const activityType = value;
        if (!nodeClassMapping[activityType]) {
            setSelectedNodeClass(null);
            return;
        }
        const classMapping = new nodeClassMapping[activityType]();
        setSelectedNodeClass(classMapping);
        const defaultNodeData = classMapping.getDefaultNodeData();
        updateNodeData({ nodeType: value, ...defaultNodeData });
    };

    useEffect(() => {
        handleChange(nodeType);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (!selected && isVisible) {
            hideContextMenu();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    return (
        <>
            <ActionsToolbar nodeId={id} onNodeAction={onNodeAction} />
            <ProcessStepToolbar
                nodeId={id}
                isVisible={!isInteractionDisabled && isVisible}
                hideToolbar={() => setIsVisible(!isVisible)}
                onClickHandler={useNodeClick}
            />
            <NodeResizer
                keepAspectRatio={shiftKeyPressed}
                isVisible={selected}
                shouldResize={() => false}
                style={{ borderRadius: '5px' }}
            />

            <Card style={{ width: 215, border: '2px solid #CF4C2C' }}>
                <div
                    style={{
                        position: 'absolute',
                        top: '29px',
                        left: '12px',
                        transform: 'translateX(-50%)',
                        zIndex: 10,
                    }}
                >
                    <i className={selectedNodeClass?.icon} />
                </div>

                <NodeLabel placeholder={'Name'} name={name} id={id} className="activity-label" />

                <Select
                    placeholder="Select Node Type"
                    style={{ width: '100%', marginTop: 10 }}
                    onChange={handleChange}
                    className="nodrag"
                    value={nodeType}
                    disabled={isInteractionDisabled}
                >
                    {ACTIVITY_TYPES.map((action) => (
                        <Select.Option value={action.key} key={action.key}>
                            {action.value}
                        </Select.Option>
                    ))}
                </Select>
            </Card>

            {!selectedNodeClass?.hideTargetHandle && (
                <Handle style={handleStyle} id="left" type="target" position={Position.Left} isConnectable={!isInteractionDisabled} />
            )}
            {!selectedNodeClass?.hideSourceHandle && <Handle
                style={handleStyle}
                id="right"
                type="source"
                position={Position.Right}
                onClick={() => setIsVisible(!isVisible)}
                onConnect={() => setIsVisible(false)}
                isConnectable={!isInteractionDisabled}
            />}
        </>
    );
}

export default Activity;
