import React, { useCallback, useEffect } from 'react';
import SimpleBar from 'simplebar-react';
import MetisMenu from 'metismenujs';
import withRouter from '../../components/common/withRouter';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import { MENU_ITEMS } from './constant';

const SidebarContent = (props) => {
    const { t } = useTranslation();

    const activateParentDropdown = useCallback((item) => {
        item.classList.add('active');
        const parent = item.parentElement;
        const parent2El = parent.childNodes[1];

        if (parent2El && parent2El.id !== 'side-menu') {
            parent2El.classList.add('mm-show');
        }

        if (parent) {
            parent.classList.add('mm-active');
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add('mm-show'); // ul tag

                const parent3 = parent2.parentElement; // li tag

                if (parent3) {
                    parent3.classList.add('mm-active'); // li
                    parent3.childNodes[0].classList.add('mm-active'); //a
                    const parent4 = parent3.parentElement; // ul
                    if (parent4) {
                        parent4.classList.add('mm-show'); // ul
                        const parent5 = parent4.parentElement;
                        if (parent5) {
                            parent5.classList.add('mm-show'); // li
                            parent5.childNodes[0].classList.add('mm-active'); // a tag
                        }
                    }
                }
            }
            // scrollElement(item);
            return false;
        }
        // scrollElement(item);
        return false;
    }, []);

    const removeActivation = (items) => {
        for (var i = 0; i < items.length; ++i) {
            var item = items[i];
            const parent = items[i].parentElement;

            if (item && item.classList.contains('active')) {
                item.classList.remove('active');
            }
            if (parent) {
                const parent2El =
                    parent.childNodes && parent.childNodes.lenght && parent.childNodes[1] ? parent.childNodes[1] : null;
                if (parent2El && parent2El.id !== 'side-menu') {
                    parent2El.classList.remove('mm-show');
                }

                parent.classList.remove('mm-active');
                const parent2 = parent.parentElement;

                if (parent2) {
                    parent2.classList.remove('mm-show');

                    const parent3 = parent2.parentElement;
                    if (parent3) {
                        parent3.classList.remove('mm-active'); // li
                        parent3.childNodes[0].classList.remove('mm-active');

                        const parent4 = parent3.parentElement; // ul
                        if (parent4) {
                            parent4.classList.remove('mm-show'); // ul
                            const parent5 = parent4.parentElement;
                            if (parent5) {
                                parent5.classList.remove('mm-show'); // li
                                parent5.childNodes[0].classList.remove('mm-active'); // a tag
                            }
                        }
                    }
                }
            }
        }
    };

    const path = useLocation();
    const activeMenu = useCallback(() => {
        const pathName = path.pathname;
        let matchingMenuItem = null;
        const ul = document.getElementById('side-menu');
        const items = ul.getElementsByTagName('a');
        removeActivation(items);

        for (let i = 0; i < items.length; ++i) {
            if (pathName.startsWith(items[i].pathname)) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem);
        }
    }, [path.pathname, activateParentDropdown]);

    useEffect(() => {
        new MetisMenu('#side-menu');
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        activeMenu();
    }, [activeMenu]);

    return (
        <React.Fragment>
            <SimpleBar style={{ maxHeight: '100%' }}>
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        <li className="menu-title">{t('menu.settings.settings')} </li>
                        {
                            MENU_ITEMS.map((menuItem) => 
                                <li key={menuItem.id}>
                                    <Link to={menuItem.route} className="waves-effect">
                                        <i className={menuItem.icon}></i>
                                        <span>{t(`${menuItem.label}`)}</span>
                                    </Link>
                                </li>
                            )
                        }
                    </ul>
                </div>
            </SimpleBar>
        </React.Fragment>
    );
};

export default withRouter(withTranslation()(SidebarContent));
