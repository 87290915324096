import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { tenant } from '../../services';
import { showError } from '../../helpers/common';

const initialState = {
    tenants: [],
    loading: false,
    error: '',
};

export const getAllTenants = createAsyncThunk('tenants/all', async (_, { rejectWithValue }) => {
    try {
        const res = await tenant.getAllTenants();
        return res.data;
    } catch (error) {
        const message = error.message || error.toString();
        showError(message);
        return rejectWithValue(message);
    }
});

export const createTenant = createAsyncThunk('tenants/create', async ({ payload, toggle }, { rejectWithValue }) => {
    try {
        const res = await tenant.addTenant(payload);
        toggle();
        return res.data;
    } catch (error) {
        const message = error.message || error.toString();
        showError(message);
        return rejectWithValue(message);
    }
});

export const updateTenant = createAsyncThunk('tenants/update', async ({ id, payload, toggle }, { rejectWithValue }) => {
    try {
        await tenant.updateTenant(id, payload);
        toggle();
        return payload;
    } catch (error) {
        const message = error.message || error.toString();
        showError(message);
        return rejectWithValue(message);
    }
});

export const deleteTenant = createAsyncThunk('tenants/delete', async ({ id }, { rejectWithValue }) => {
    try {
        await tenant.deleteTenant(id);
        return true;
    } catch (error) {
        const message = error.message || error.toString();
        showError(message);
        return rejectWithValue(message);
    }
});

const tenantSlice = createSlice({
    name: 'tenant',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(createTenant.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(createTenant.fulfilled, (state, action) => {
                state.loading = false;
                state.tenants.unshift(action.payload);
            })
            .addCase(createTenant.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(getAllTenants.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getAllTenants.fulfilled, (state, action) => {
                state.loading = false;
                state.tenants = action.payload;
            })
            .addCase(getAllTenants.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(deleteTenant.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(deleteTenant.fulfilled, (state, action) => {
                state.loading = false;
                const {
                    arg: { id },
                } = action.meta;
                if (id) {
                    state.tenants = state.tenants.filter((item) => item.id !== id);
                }
            })
            .addCase(deleteTenant.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(updateTenant.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(updateTenant.fulfilled, (state, action) => {
                state.loading = false;
                const {
                    arg: { id },
                } = action.meta;
                if (id) {
                    state.tenants = state.tenants.map((item) =>
                        item.id === id ? { ...item, ...action.payload } : item
                    );
                }
            })
            .addCase(updateTenant.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            });
    },
});

const { reducer } = tenantSlice;
export default reducer;
