import { Button, Select } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { AddForm } from '../../../../forms/addForm';

export const FormSelector = ({ nodeId, nodeData, onChange }) => {
    const { forms } = useSelector((state) => state.processDetails);

    const { form, name: defaultFormName } = nodeData;

    const [selectedForm, setSelectedForm] = useState(form || null);
    const [showAddFormModal, setShowAddFormModal] = useState(false);

    const formOptions = forms?.map((form) => ({ label: form.name, value: form.id }));

    const onSelectForm = (formId = null) => {
        setSelectedForm(formId);
        onChange(formId);
        setShowAddFormModal(false);
    };

    return (
        <>
            <Button onClick={() => setShowAddFormModal(true)}>Edit Form</Button>
            <p style={{ margin: '5px 0px' }}>Choose from an existing form</p>
            <Select allowClear options={formOptions} name="form" onChange={onSelectForm} value={selectedForm} />

            {showAddFormModal && (
                <AddForm
                    defaultFormName={defaultFormName !== 'Activity' ? defaultFormName : ''}
                    nodeId={nodeId}
                    showModal={showAddFormModal}
                    selectedForm={selectedForm}
                    toggle={() => {
                        setShowAddFormModal(false);
                    }}
                    isEdit={selectedForm}
                    onSubmit={onSelectForm}
                />
            )}
        </>
    );
};
