import usFlag from '../assets/images/flags/us.jpg';
import germany from '../assets/images/flags/germany.jpg';

const languages = {
    de: {
        label: 'German',
        flag: germany,
    },
    en: {
        label: 'English',
        flag: usFlag,
    },
};

export default languages;
