import i18n from '../../../i18n';
import store from '../../../store';

const resourceLoaders = {
    getAttributeGroups() {
        const state = store.getState();
        const { processDetails } = state;
        return processDetails?.attributeGroups?.length
            ? [...processDetails?.attributeGroups, { id: '', name: 'Others' }]
            : [{ id: '', name: 'Others' }];
    },
};

class AttributeGroup {
    methods = {};

    // eslint-disable-next-line no-useless-constructor
    constructor() {
        this.methods = {
            loadOptions: resourceLoaders,
        };
    }

    getDefaultAttributes() {
        return [
            {
                displayName: 'attribute-group.name',
                name: 'name',
                description: 'attribute-group.name',
                type: 'text',
                default: '',
                required: true,
                section: '', // use translation keys here
                tab: '', // use translation keys here
                rules: [
                    {
                        required: true,
                        message: i18n.t('attribute-group.name-required'),
                    },
                ],
            },
        ];
    }

    getEditAttributes() {
        return [
            {
                displayName: 'attribute-group.name',
                name: 'attributeGroupId',
                description: 'attribute-group.name',
                type: 'select',
                default: '',
                required: true,
                typeOptions: {
                    loadOptionsMethod: 'getAttributeGroups',
                },
            },
        ];
    }
}
export { AttributeGroup as default };
