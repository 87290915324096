import { Menu } from 'antd';
import { NodeToolbar, Position, useReactFlow } from 'reactflow';
import eventIcon from '../../../../../assets/bpmn/event.svg';
import exclusiveGateway from '../../../../../assets/bpmn/gateway-exclusive.svg';
import inclusiveGateway from '../../../../../assets/bpmn/gateway-inclusive.svg';
import mergeIcon from '../../../../../assets/bpmn/gateway-none.svg';
import parallelGateway from '../../../../../assets/bpmn/gateway-parallel.svg';
import taskIcon from '../../../../../assets/bpmn/task.svg';
import endEventIcon from '../../../../../assets/bpmn/end-event.svg';
import { useTranslation } from 'react-i18next';
import { getNextNodes } from '../../plugins/utils/GenericFunctions';

const PROCESS_STEP_OPTIONS = [
    {
        key: 'activity',
        label: 'process-step-options.activity',
        description: 'process-step-options.activity-description',
        type: 'activity',
        icon: taskIcon,
        children: [],
    },
    {
        type: 'divider',
    },
    {
        key: 'gateway',
        label: 'process-step-options.gateway',
        description: '',
        icon: mergeIcon,
        children: [
            {
                key: 'inclusive-gateway',
                label: 'process-step-options.inclusive-gateway',
                description: 'process-step-options.inclusive-gateway-description',
                type: 'shape',
                icon: inclusiveGateway,
            },
            {
                type: 'divider',
            },
            {
                key: 'exclusive-gateway',
                label: 'process-step-options.exclusive-gateway',
                description: 'process-step-options.exclusive-gateway-description',
                type: 'shape',
                icon: exclusiveGateway,
            },
            {
                type: 'divider',
            },
            {
                key: 'parallel-gateway',
                label: 'process-step-options.parallel-gateway',
                description: 'process-step-options.parallel-gateway-description',
                type: 'shape',
                icon: parallelGateway,
            },
            {
                type: 'divider',
            },
            {
                key: 'merge',
                label: 'process-step-options.merge',
                type: 'shape',
                icon: mergeIcon,
            },
        ],
    },
    {
        type: 'divider',
    },
    {
        key: 'event',
        label: 'process-step-options.event',
        description: 'process-step-options.event-description',
        icon: eventIcon,
        children: [
            {
                key: 'end',
                label: 'process-step-options.end',
                description: '',
                type: 'shape',
                icon: endEventIcon,
            },
        ],
    },
];

function ProcessStepToolbar({
    isVisible = false,
    nodeId = null,
    hideToolbar = () => false,
    position = Position.Right,
    onClickHandler = () => false,
    edgeId = null,
}) {
    const { t } = useTranslation();
    const { getEdge } = useReactFlow();

    if (edgeId) {
        const edge = getEdge(edgeId);
        const { target } = edge;
        nodeId = target;
    }

    const outgoers = getNextNodes(nodeId);

    return (
        <NodeToolbar
            nodeId={nodeId}
            className="nodrag process-step-toolbar"
            offset={24}
            position={position}
            isVisible={isVisible}
        >
            <Menu
                mode="vertical"
                items={PROCESS_STEP_OPTIONS.map((option) =>
                    option?.type === 'divider'
                        ? {
                              type: 'divider',
                          }
                        : {
                              key: option.key,
                              label: (
                                  <div
                                      {...(option?.children?.length === 0
                                          ? {
                                                onClick: onClickHandler(
                                                    edgeId ? edgeId : nodeId,
                                                    option.key,
                                                    hideToolbar
                                                ),
                                            }
                                          : {})}
                                  >
                                      <img src={option.icon} alt="" width={28} height={28} />
                                      <span className="mx-2">{t(option.label)}</span>
                                  </div>
                              ),
                              popupClassName: 'process-step-toolbar-submenu',
                              ...(option?.children?.length && {
                                  children: option.children?.map((child) =>
                                      child?.type === 'divider'
                                          ? {
                                                type: 'divider',
                                            }
                                          : {
                                                key: child.key,
                                                disabled: edgeId || (child.key === 'end' && outgoers.length !== 0),
                                                label: (
                                                    <div
                                                        className={`d-flex sidebar-item ${
                                                            edgeId || (child.key === 'end' && outgoers.length !== 0)
                                                                ? 'pointer-none'
                                                                : ''
                                                        }`}
                                                        onClick={onClickHandler(
                                                            edgeId ? edgeId : nodeId,
                                                            child.key,
                                                            hideToolbar
                                                        )}
                                                    >
                                                        {child?.icon && (
                                                            <img src={child.icon} alt="" width={28} height={28} />
                                                        )}
                                                        <div className="d-flex flex-column mx-1">
                                                            <span>
                                                                <b>{t(child.label)}</b>
                                                            </span>
                                                            {child.description && (
                                                                <span className="process-step-description">
                                                                    {t(child.description)}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                ),
                                            }
                                  ),
                              }),
                          }
                )}
            />
        </NodeToolbar>
    );
}

export default ProcessStepToolbar;
