import React from 'react';
import { Link } from 'react-router-dom';
import SidebarContent from './SidebarContent';
import logoProdyneSm from '../../assets/images/prodyne_sm.svg';
import logoProdyne from '../../assets/images/prodyne_logo.svg';
import { toggleSidebar } from '../../helpers/common';

const Sidebar = (props) => {
    return (
        <React.Fragment>
            <div className="vertical-menu">
                <div className="navbar-brand-box">
                    <Link to="/" className="logo logo-dark">
                        <span className="logo-sm">
                            <img src={logoProdyneSm} alt="" height="30" />
                        </span>
                        <span className="logo-lg">
                            <img src={logoProdyne} alt="" height="40" />
                        </span>
                    </Link>

                    <Link to="/" className="logo logo-light">
                        <span className="logo-sm">
                            <img src={logoProdyneSm} alt="" height="30" />
                        </span>
                        <span className="logo-lg">
                            <img src={logoProdyne} alt="" height="40" />
                        </span>
                    </Link>
                </div>
                <button
                    onClick={() => {
                        toggleSidebar();
                    }}
                    type="button"
                    className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
                >
                    <i className="fa fa-fw fa-bars"></i>
                </button>
                <div className="sidebar-menu-scroll">
                    {props.type !== 'condensed' ? <SidebarContent /> : <SidebarContent />}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Sidebar;
