import { Card, Modal, Tabs, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Container } from 'reactstrap';
import { io } from 'socket.io-client';

import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../components/breadcrumb/Breadcrumb';
import SOCKET_RESPONSE from '../../constants/socket';
import { SOCKET_URL } from '../../constants/url';
import { showError } from '../../helpers/common';
import { dashboard } from '../../services/index';
import FormRenderer from './form-renderer';
import { NodesTab } from './getNodesTab';
import { PROCESS_ENVIRONMENTS } from '../../constants/constants';

const getProcessEnvironments = (t) => {
    const environmentOptions = [];
    for (const [key, value] of Object.entries(PROCESS_ENVIRONMENTS)) {
        environmentOptions.push({ value: key, label: t(`process.${value}`) });
    }
    return environmentOptions;
};

const Dashboard = () => {
    document.title = ' Dashboard';

    const { t } = useTranslation();
    const [activeKey, setActiveKey] = useState('');
    const [processes, setProcesses] = useState([]);
    const [formData, setFormData] = useState([]);
    const [subTaskId, setSubTaskId] = useState(null);
    const [showNewTaskModal, setShowNewTaskModal] = useState(false);
    const [environment, setEnvironment] = useState(PROCESS_ENVIRONMENTS.DESIGNER);

    const socketRef = useRef();
    const authUser = localStorage.getItem('authUser');
    let userId = null;
    if (authUser) {
        const obj = JSON.parse(authUser);
        userId = obj?.id;
    }

    useEffect(() => {
        if (activeKey) {
            socketRef.current = io(SOCKET_URL, { transports: ['websocket'], upgrade: false, autoConnect: true });

            socketRef.current.on(SOCKET_RESPONSE.CONNECT, () => {
                socketRef.current.emit(
                    SOCKET_RESPONSE.JOIN_ROOM,
                    '',
                    [`process/${activeKey}`, `process/${activeKey}/${userId}`],
                    (roomIdLeft, roomIdJoined) => {
                        console.log('roomIdLeft', roomIdLeft);
                        console.log('roomIdJoined', roomIdJoined);
                    }
                );
            });

            socketRef.current.on(SOCKET_RESPONSE.CONNECT_ERROR, () => {
                setTimeout(() => socketRef.current.connect(), 5000);
            });

            socketRef.current.on(SOCKET_RESPONSE.DISCONNECT, () => {
                // console.log(SOCKET_RESPONSE.DISCONNECT);
            });

            socketRef.current.on(SOCKET_RESPONSE.REFRESH_VIEW, refreshView);
            socketRef.current.on(SOCKET_RESPONSE.SHOW_POPUP, showPopup);
            return () => {
                socketRef.current.off(SOCKET_RESPONSE.REFRESH_VIEW, refreshView);
                socketRef.current.off(SOCKET_RESPONSE.SHOW_POPUP, showPopup);
                socketRef.current.close();
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeKey]);

    const getDashboardData = async () => {
        try {
            const res = await dashboard.getDashboardData(environment);
            setProcesses(res?.data);
            setActiveKey(res?.data?.[0]?.id);
        } catch (err) {
            showError(err?.message);
        }
    };

    useEffect(() => {
        getDashboardData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [environment]);

    const onDashboardAction = async (payload) => {
        try {
            await dashboard.handleDashboardActions(payload);
        } catch (err) {
            showError(err?.message);
        }
    };

    const getTabItems = () => {
        return processes?.map((process) => {
            return {
                key: process?.id,
                label: process?.name,
                children: <NodesTab process={process} onDashboardAction={onDashboardAction} key={new Date()} />,
            };
        });
    };

    const onSubmit = async (actionButton, data) => {
        const attributes = data.reduce((acc, attr) => {
            const key = attr.name.split('_')[0];
            acc[key] = attr.value;
            return acc;
        }, {});
        const payload = {
            actionKey: actionButton.action,
            actionData: { subTaskId, attributes, processId: activeKey },
        };

        try {
            await dashboard.handleDashboardActions(payload);
            setShowNewTaskModal(false);
        } catch (err) {
            showError(err?.message);
        }
    };

    const refreshView = async () => {
        const res = await dashboard.getDashboardData(environment);
        setProcesses(res?.data);
        setActiveKey(activeKey);
    };

    const showPopup = (response) => {
        const { formData, subTaskId } = response;
        setSubTaskId(subTaskId);
        setFormData(formData);
        setShowNewTaskModal(true);
    };

    const onChange = (value) => {
        setEnvironment(value);
    };

    const environmentOptions = getProcessEnvironments(t);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Dashboard">
                        <Select
                            value={environment}
                            style={{ width: 120 }}
                            options={environmentOptions}
                            onChange={onChange}
                        />
                    </Breadcrumbs>
                </Container>
                <Card style={{ height: 'calc(100vh - 220px)', overflowY: 'auto' }}>
                    {processes?.length > 0 ? (
                        <Tabs activeKey={activeKey} items={getTabItems()} onChange={(aKey) => setActiveKey(aKey)} />
                    ) : (
                        <>No action(s)</>
                    )}
                </Card>
            </div>

            <Modal
                title={formData?.name ?? t('dashboard.dashboard')}
                width={620}
                open={showNewTaskModal}
                footer={null}
                maskClosable={false}
                destroyOnClose
                closable={false}
            >
                <FormRenderer onSubmit={onSubmit} formData={formData} formValues={[]} />
            </Modal>
        </React.Fragment>
    );
};

export default Dashboard;
