import React, { useEffect, useState, useCallback } from 'react';
import {useStateWithRef} from "../../../helpers/useStateWithRef";
import {Tabs, Form, Table, Select, Button, Input, Radio} from 'antd';
import {
    Col, Label,
    Modal,
    ModalBody,
    ModalHeader, Row,
} from 'reactstrap';
import {FilterOutlined, FilterFilled, DeleteOutlined} from "@ant-design/icons";
import {SocialEditor} from "@remirror/react-editors/social";
import {
    getDatasources,
    getTableContent,
    getTablesFromDatasource,
    getQueryResult,
    testWriter
} from "../../../plugins/datasource/nodes/database-writer.method";
import {useTranslation} from "react-i18next";
import {showError} from "../../../helpers/common";
import {process} from "../../../services";
import "./db-writer.css";

/**
 * @todos:
 * - integrate mentions https://remirror.io/docs/extensions/mention-extension/
 * -> execute query from mention editor
 */

const DatabaseWriter = ({ formInfo, fieldInfo, form, initialValues }) => {
    const { t } = useTranslation();
    const { TabPane } = Tabs;
    const [attributeList, setAttributeList] = useState([]);

    const [modal, setModal] = useState(false);
    const [simModal, setSimModal] = useState(false);

    const [selectedDatasource, setSelectedDatasource, selectedDatasourceRef] = useStateWithRef(null);
    const [dataSourceOptions, setDataSourceOptions] = useState([]);
    const [fields, setFields] = useState([]);
    const [selectedMode, setSelectedMode, selectedModeRef] = useStateWithRef('Update');

    const [tableOptions, setTableOptions] = useState([]);
    const [selectedTable, setSelectedTable, selectedTableRef] = useStateWithRef([]);
    const [tableData, setTableData] = useState([]);
    const [selectedColumn, setSelectedColumn, selectedColumnRef] = useStateWithRef('');

    const [radioValue, setRadioValue, radioValueRef] = useStateWithRef('');
    const [selectedOperator, setSelectedOperator, selectedOperatorRef] = useStateWithRef('');
    const [selectedValue, setSelectedValue, selectedValueRef] = useStateWithRef('');
    const [selectedAttribute, setSelectedAttribute, selectedAttributeRef] = useStateWithRef('');
    const [queryResult, setQueryresult] = useState('');
    const [testResult, setTestResult] = useState(null);

    /**
     * handle selected mode
     */
    const handleModeChange = (e) => {
        setSelectedMode(e.target.value);
    };

    /**
     * handle filter
     */
    const handleOperatorChange = (value) => {
        setSelectedOperator(value);
    };

    const handleValueChange = (value) => {
        setSelectedValue(value);
    };

    const handleAttributeChange = (value) => {
        setSelectedAttribute(value);
    };

    const handleRadioChange = (e) => {
        setRadioValue(e.target.value);
    };

    const removeFilter = (recordId) => {
        const updatedTableOptions = tableOptions.map(option => {
            if (option.id === selectedTable && option.columns.includes(recordId)) {
                const updatedFilters = { ...option[selectedMode.toLowerCase()].filters };
                delete updatedFilters[recordId];

                return {
                    ...option,
                    [selectedMode.toLowerCase()]: {
                        ...option[selectedMode.toLowerCase()],
                        filters: updatedFilters,
                    },
                };
            }
            return option;
        });

        setTableOptions(updatedTableOptions);
        form.setFieldValue('databaseWriter', updatedTableOptions);
    };


    const handleFilter = () => {
        const newFilterValue = {
            operator: selectedOperatorRef.current,
            radioValue: radioValueRef.current,
            value: selectedValueRef.current,
            attribute: selectedAttributeRef.current,
        };

        const updatedTableOptions = tableOptions.map(option => {
            if (option.id === selectedTable && option.columns.includes(selectedColumnRef.current)) {
                const currentMode = option[selectedMode.toLowerCase()];

                // Preserve existing attributes if they exist
                const updatedAttributes = currentMode.attributes
                    ? { ...currentMode.attributes }
                    : {};

                // check if the attribute for the column already exists, if not, set it
                if (!updatedAttributes[selectedColumnRef.current]) {
                    updatedAttributes[selectedColumnRef.current] = selectedAttributeRef.current;
                }

                return {
                    ...option,
                    [selectedMode.toLowerCase()]: {
                        ...currentMode,
                        filters: {
                            ...currentMode.filters,
                            [selectedColumnRef.current]: newFilterValue,
                        },
                        attributes: updatedAttributes,
                    },
                };
            }
            return option;
        });

        //console.log(updatedTableOptions);
        setTableOptions(updatedTableOptions);
        form.setFieldValue('databaseWriter', updatedTableOptions);
        toggle();
    };

    const prefillSimModalWithFilters = useCallback(
        (column) => {
            const selectedTableOptions = tableOptions.find(table => table.id === selectedTableRef.current);
            let filterExists = false;

            if (selectedTableOptions && selectedTableOptions?.[selectedMode.toLowerCase()]?.filters) {
                filterExists = selectedTableOptions[selectedMode.toLowerCase()].filters[column];
            }

            if (filterExists) {
                setSelectedOperator(filterExists?.operator);
                setSelectedValue(filterExists?.value);
                setSelectedAttribute(filterExists?.attribute);
                setRadioValue(filterExists?.radioValue);
            } else {
                setRadioValue('attribute');
                setSelectedOperator('');
                setSelectedValue('');
                setSelectedAttribute('');
            }
        },
        [selectedTableRef, tableOptions, selectedMode, setSelectedOperator, setSelectedValue, setSelectedAttribute, setRadioValue]
    );

    const simulate = async () => {
        let value = selectedValueRef.current;
        if (radioValueRef.current==="attribute") value = selectedAttributeRef.current;
        const query = "SELECT * FROM "+selectedTableRef.current+" WHERE "+selectedColumnRef.current+selectedOperatorRef.current+"'"+value+"' LIMIT 1";
        console.log(query);
        try {
            const seletedDatasource = await form.getFieldsValue(['datasource']);
            const res = await getQueryResult({ id: seletedDatasource.datasource, query: query });
            const queryResult = res.result;
            setQueryresult(queryResult);
            console.log(queryResult);
        } catch (error) {
            console.error(error);
        }
    };

    /*
     * modal window handling
     */
    const toggle = useCallback(() => {
        setModal(!modal);
    }, [modal, setModal]);

    const showModal = useCallback(
        (record) => {
            setSelectedColumn(record?.id);
            setSelectedOperator('');
            setSelectedValue('');
            setSelectedAttribute('');
            prefillSimModalWithFilters(record?.id);
            toggle();
        },
        [toggle, prefillSimModalWithFilters, setSelectedColumn, setSelectedOperator, setSelectedValue, setSelectedAttribute]
    );

    const toggleSimModal = useCallback(() => {
        setSimModal(!simModal);
    }, [simModal, setSimModal]);

    const showSimModal = useCallback(
        (record) => {
            setQueryresult('');
            if (!simModal) simulate();
            toggleSimModal();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [simModal, toggleSimModal, setQueryresult]
    );

    /**
     * fetch datasources
     */
    const columns = [
        {title: t('database-reader.column'), dataIndex: 'name', key: 'id'},
        {
            title: t('database-reader.attribute'),
            render: (text, record) => {
                const selectedTableOptions = tableOptions.find(table => table.id === selectedTableRef.current);
                let attributeValue = '';

                if (selectedMode !== 'Delete' && selectedTableOptions?.[selectedMode.toLowerCase()]?.attributes) {
                    attributeValue = selectedTableOptions[selectedMode.toLowerCase()]?.attributes[record.id];
                }

                if (selectedMode==="Delete") return (
                    <div>-</div>
                )
                return (
                    <Select value={attributeValue} onChange={(value) => handleAttribute(value, record.id)}>
                        {attributeList.map((attribute) => (
                            <Select.Option key={attribute.id} value={attribute.id}>
                                {attribute.name}
                            </Select.Option>
                        ))}
                    </Select>
                );
            },
        },
        {
            title: t('database-reader.filter'),
            dataIndex: null,
            render: (text, record) => {
                const selectedTableOptions = tableOptions.find(table => table.id === selectedTableRef.current);
                let filterExists = false;
                if (selectedTableOptions && selectedTableOptions?.[selectedMode.toLowerCase()]?.filters) {
                    filterExists = selectedTableOptions[selectedMode.toLowerCase()].filters[record.id];
                }

                if (selectedMode==="Insert") return (
                    <div>-</div>
                )
                return (
                    <Button
                        type="text"
                        icon={filterExists ? <FilterFilled style={{ color: '#1677ff' }} /> : <FilterOutlined />}
                        onClick={() => showModal(record)}
                    />
                );
            },
        },
        {
            render: (text, record) => {
                const selectedTableOptions = tableOptions.find(table => table.id === selectedTableRef.current);
                let filterExists = false;
                let filterDisplay = '-';

                if (selectedTableOptions && selectedTableOptions?.[selectedMode.toLowerCase()]?.filters) {
                    filterExists = selectedTableOptions[selectedMode.toLowerCase()].filters[record.id];
                }

                if (filterExists) {
                    filterDisplay = `${filterExists.operator} ${
                        filterExists.radioValue === 'attribute'
                            ? filterExists.attribute
                            : filterExists.value
                    }`;
                }

                const handleRemoveFilter = () => {
                    removeFilter(record.id);
                };

                return (
                    <div>
                        {filterDisplay !== '-' && (
                            <>
                                {filterDisplay}
                                <Button
                                    type="text"
                                    icon={<DeleteOutlined />}
                                    onClick={handleRemoveFilter}
                                />
                            </>
                        )}
                        {filterDisplay === '-' && <div>{filterDisplay}</div>}
                    </div>
                );
            },
        }
    ];

    const handleAttribute = (value, recordId) => {
        const updatedTableOptions = tableOptions.map(option => {
            if (option.id === selectedTable && option.columns.includes(recordId)) {
                // Check if attributes already exist, if not, create a new object
                const updatedAttributes = option[selectedMode.toLowerCase()]?.attributes
                    ? { ...option[selectedMode.toLowerCase()]?.attributes, [recordId]: value }
                    : { [recordId]: value };

                return {
                    ...option,
                    [selectedMode.toLowerCase()]: {
                        ...option[selectedMode.toLowerCase()],
                        attributes: updatedAttributes,
                    },
                };
            }
            return option;
        });

        //console.log(updatedTableOptions);
        setTableOptions(updatedTableOptions);
        form.setFieldValue('databaseWriter', updatedTableOptions);
    };

    /**
     * fetch tables
     */
    const handleSelectDatasource = async (selectedDatasource, initialTableOptions=[]) => {
        try {
            setSelectedDatasource(selectedDatasource); // set selected datasource

            const result = await getTablesFromDatasource({id: selectedDatasource});
            const newTableOptions = result.data.map((item) => {
                const newModes = {};
                ['update', 'insert', 'delete'].forEach((mode) => {
                    const existingOption = Array.isArray(initialTableOptions) ? initialTableOptions.find((opt) => opt.id === item.id) : undefined;
                    newModes[mode] = existingOption?.[mode] || { filters: undefined, attributes: undefined };
                });

                return {
                    id: item.id,
                    name: item.name,
                    label: item.name,
                    columns: item.columns,
                    ...newModes,
                };
            });
            setTableOptions(newTableOptions);

            const allColumns = newTableOptions.reduce((accumulator, table) => {
                return accumulator.concat(table.columns);
            }, []);
            setFields(allColumns);
        } catch (error) {
            console.error(error);
        }
    };

    /**
     * fetch table columns
     */
    const handleSelectTable = async (table) => {
        try {
            setSelectedTable(table);
            form.setFieldValue('selectedTable', table);
            const seletedDatasource = await form.getFieldsValue(['datasource']);
            const result = await getTableContent({ id: seletedDatasource.datasource, table: table });
            const tableData = Object.keys(result.result[0]).map((key) => ({
                id: key,
                name: key,
            }));
            setTableData(tableData);
        } catch (error) {
            console.error(error);
        }
    };

    /**
     * handle query
     */
    const handleQuery = async () => {
        try {
            const editorContent = form.getFieldValue('query');
            const jsonData = editorContent.helpers.getJSON();
            const parts = jsonData?.content[0]?.content;
            let query = parts.map(item => {
                if (item.type === 'text') {
                    return item.text;
                }
                if (item.type === 'mentionAtom' && item.attrs && item.attrs.id) {
                    return item.attrs.id;
                }
                return '';
            }).join('');
            query = query.replace(/;/g, ''); // remove semicolons from the query
            query += " LIMIT 1";

            console.log(query);
            if (query) {
                const seletedDatasource = await form.getFieldsValue(['datasource']);
                const res = await getQueryResult({ id: seletedDatasource.datasource, query: query });
                const queryResult = res?.result[0];
                console.log(queryResult);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const test = async () => {
        try {
            // prepare data to send
            const selectedTableOptions = tableOptions.find(table => table.id === selectedTableRef.current);
            const dataToSend = {
                selectedMode: selectedMode,
                selectedTable: selectedTableRef.current,
                attributes: selectedTableOptions[selectedMode.toLowerCase()]?.attributes,
                filters: selectedTableOptions[selectedMode.toLowerCase()]?.filters
            };

            // execute request
            const result = await testWriter(dataToSend);
            setTestResult(result.result);
        } catch (error) {
            console.error('Error occurred:', error);
        }
    };

    /**
     * on startup...
     */
    useEffect(() => {
        // handle initialvalues
        console.log(initialValues);
        const initialize = async () => {
            // load available datasources on startup
            try {
                const result = await getDatasources();
                const dataSourceOptions = result.data.map(item => ({
                    id: item.id,
                    name: item.name,
                }));
                setDataSourceOptions(dataSourceOptions);
            } catch (error) {
                console.log(error);
            }

            // get attributes
            try {
                const res = await process.getAttributeList(formInfo?.processId);
                const newAttributeList = res?.data || [];
                newAttributeList.unshift('');
                setAttributeList(newAttributeList);
            } catch (err) {
                showError(err?.message);
            }

            // handle initialvalues
            console.log(initialValues);
            if (initialValues?.datasource) handleSelectDatasource(initialValues.datasource, initialValues.databaseWriter || []);
            if (initialValues?.selectedTable) handleSelectTable(initialValues.selectedTable);
            if (initialValues?.selectedMode) setSelectedMode(initialValues.selectedMode);
        }
        initialize();
    }, [formInfo, initialValues]);

    return (
        <div>
            <Modal isOpen={modal} toggle={toggle} style={{ width: '800px' }}>
                <ModalHeader toggle={toggle} tag="h4">
                    {`Filter`}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <Label className="form-label">{t('database-reader.operator')}</Label><br />
                            <Select
                                onChange={(value) => handleOperatorChange(value)}
                                style={{ width: '200px' }}
                                name='operator'
                                value={selectedOperator}
                            >
                                <Select.Option value=">">&gt;</Select.Option>
                                <Select.Option value=">=">&gt;=</Select.Option>
                                <Select.Option value="=">=</Select.Option>
                                <Select.Option value="<=">&lt;=</Select.Option>
                                <Select.Option value="<">&lt;</Select.Option>
                                <Select.Option value="null">null</Select.Option>
                                <Select.Option value="not null">not null</Select.Option>
                            </Select>
                        </Col>
                        <Col>
                            <Radio.Group onChange={handleRadioChange} defaultValue={radioValueRef.current}>
                                <Radio value="attribute">{t('database-reader.attribute')}</Radio>
                                <Select
                                    onChange={(value) => handleAttributeChange(value)}
                                    disabled={radioValue !== 'attribute'}
                                    value={selectedAttribute}
                                >
                                    {attributeList.map((attribute) => (
                                        <Select.Option key={attribute.id} value={attribute.id}>
                                            {attribute.name}
                                        </Select.Option>
                                    ))}
                                </Select>

                                <Radio value="input">{t('database-reader.value')}</Radio>
                                <Input
                                    style={{ width: '100%' }}
                                    placeholder="Enter value"
                                    disabled={radioValue !== 'input'}
                                    value={selectedValue}
                                    onChange={(e) => handleValueChange(e.target.value)}
                                />
                            </Radio.Group>
                        </Col>
                    </Row>
                    <br /><br/>
                    <Row>
                        <Col>
                            <div className="text-start">
                                <button onClick={() => showSimModal()} className="btn btn-info">
                                    {t('database-reader.simulate')}
                                </button>
                            </div>
                        </Col>
                        <Col>
                            <div className="text-end">
                                <button onClick={() => toggle()} className="btn btn-outline-secondary" style={{ marginRight: '10px' }}>
                                    {t('action.cancel')}
                                </button>
                                <button onClick={handleFilter} className="btn btn-success save-user">
                                    {t('action.save')}
                                </button>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>

            <Modal isOpen={simModal} toggle={toggleSimModal} style={{ width: '800px' }}>
                <ModalHeader toggle={toggleSimModal} tag="h4">
                    {`Simulation`}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            {queryResult && (
                                <div>
                                    {JSON.stringify(queryResult)}
                                </div>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="text-end">
                                <button onClick={() => toggleSimModal()} className="btn btn-outline-secondary" style={{ marginRight: '10px' }}>
                                    {t('action.cancel')}
                                </button>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>

            <div>
                <Form.Item label="Datasource" name="datasource" rules={[{ required: true }]}>
                    <Select onChange={handleSelectDatasource}>
                        {dataSourceOptions.map((option) => (
                            <Select.Option key={option.id} value={option.id}>
                                {option.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                {selectedDatasourceRef.current && (
                    <div>
                        <div>
                            {/* Radio select section */}
                            <Form.Item label="" name="selectedMode" rules={[{ required: true }]}>
                            <Radio.Group onChange={handleModeChange} value={selectedMode}>
                                <Radio.Button value="Update">Update</Radio.Button>
                                <Radio.Button value="Insert">Insert</Radio.Button>
                                <Radio.Button value="Delete">Delete</Radio.Button>
                            </Radio.Group>
                            </Form.Item>
                        </div>

                        <Tabs>
                            <TabPane tab={t('database-reader.tab-table')} key="table">
                                <Form.Item label={t('database-reader.db-tables')} name="selectedTable" style={{ marginBottom: 0 }}>
                                    <Select onChange={handleSelectTable}>
                                        {tableOptions.map((option) => (
                                            <Select.Option key={option.id} value={option.id}>
                                                {option.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Table columns={columns} dataSource={tableData} pagination={false} />
                            </TabPane>

                            <TabPane tab={t('database-reader.tab-query')} key="query">
                                <Form.Item label='' name="query" rules={[{ required: false }]}>
                                    <SocialEditor placeholder='Select #field from @table' users={tableOptions} tags={fields}></SocialEditor>
                                </Form.Item>
                                <Button onClick={handleQuery}>{t('database-reader.tab-query')}</Button><br /><br />
                            </TabPane>
                        </Tabs>
                    </div>
                )}
            </div>

            {
                /*
                <div>
                    <br />
                    <Button onClick={test} type="primary">Test</Button>
                    {Array.isArray(testResult) ? (
                        testResult.map((item, index) => (
                            <div key={index}>{item}</div> // Display each item
                        ))
                    ) : (
                        <div>{testResult}</div> // Display a single item
                    )}
                </div>
                */
            }

        </div>
    );
};

export default DatabaseWriter;
