import { useEffect, useState } from 'react';
import { Select } from 'antd';

export const Options = ({ attribute, classMapping = null, onChange = () => false, value = null, nodeId = null }) => {
    const {
        name,
        default: defaultValue,
        mode,
        typeOptions,
        options: attributeOptions,
        groupOptions,
        groupOptionsKey,
        ungroupedOptionsKey,
    } = attribute;
    let val = value || defaultValue;
    if (mode === 'multiple' && (!Array.isArray(val) || !val)) {
        val = val ? [val] : [];
    }
    const [selectedOption, setSelectedOption] = useState(val);
    const [options, setOptions] = useState(attributeOptions || []);

    const getArgument = (argumentName = null) => {
        if (typeOptions === undefined) {
            return undefined;
        }
        if (typeOptions[argumentName] === undefined) {
            return undefined;
        }
        return typeOptions[argumentName];
    };

    const getOptions = async () => {
        const loadOptionsMethod = getArgument('loadOptionsMethod');
        if (loadOptionsMethod) {
            const method = classMapping?.methods?.loadOptions?.[loadOptionsMethod];
            const data = method ? await method.call(this, nodeId) : [];
            let parsedOptions = [];
            if (groupOptions) {
                parsedOptions = data?.reduce((acc, obj) => {
                    const groupLabel = obj[groupOptionsKey] || ungroupedOptionsKey;
                    const optionGroup = acc.find((o) => o.label === groupLabel);
                    const option = {
                        label: obj['name'],
                        value: obj['id'],
                    };
                    if (!optionGroup) {
                        acc.push({
                            label: groupLabel,
                            options: [option],
                        });
                    } else {
                        optionGroup.options.push(option);
                    }
                    return acc;
                }, []);
                parsedOptions.push(
                    ...parsedOptions.splice(
                        parsedOptions.findIndex((v) => v.label === ungroupedOptionsKey),
                        1
                    )
                );
            } else {
                parsedOptions = data.map((d) => ({ label: d.name, value: d.id }));
            }
            setOptions(parsedOptions);
        }
    };

    useEffect(() => {
        // if (nodeId) {
        getOptions();
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nodeId]);

    const onOptionChange = (value) => {
        setSelectedOption(value);
        onChange(value);
    };

    return <Select options={options} name={name} onChange={onOptionChange} value={selectedOption} mode={mode} />;
};
