import React, { useEffect } from 'react';
import { Steps, Button } from 'antd';
import { useDatasourceContext } from './datasource-context';
import { useDispatch } from 'react-redux';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import { createDatasource, updateDatasource } from '../slices/datasource';
import { useState } from 'react';
import DataPoints from './step2-data-points';
const { Step } = Steps;

const DatasoureForm = ({ closeModal, initialvalues }) => {
    const { state, dispatch } = useDatasourceContext();
    const [stateTypes, setStateType] = useState('');
    const reduxDispatch = useDispatch();

    const [steps, setSteps] = useState([{ title: 'Datasource Type', content: <Step1 setStateType={setStateType} /> }]);

    const getStepType = () => {
        const commonSteps = [{ title: 'Datasource Type', content: <Step1 setStateType={setStateType} /> }];
    
        let specificSteps;
    
        if (stateTypes === 'easydata') {
            specificSteps = [{ title: 'Data Points', content: <DataPoints closeModal={closeModal} /> }];
        } else {
            specificSteps = [
                { title: 'Credentials', content: <Step2 /> },
                { title: 'Tables & Queries', content: <Step3 /> },
            ];
        }
    
        setSteps([...commonSteps, ...specificSteps]);
    };
    
    useEffect(() => {
        getStepType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateTypes]);

    useEffect(() => {
        if (Object.keys(initialvalues).length > 0) {
            const step2Data = { ...initialvalues };
            delete step2Data.name;
            delete step2Data.type;

            dispatch({ type: 'SET_EDIT_MODE', isEdit: true });
            dispatch({
                type: 'SET_DATA',
                data: {
                    step1: { name: initialvalues.name, type: initialvalues.type },
                    step2: step2Data,
                    'step2-easy-data': {tableHeaders: step2Data?.tableHeaders, tableRows: step2Data?.tableRows}
                },
            });
            setStateType(initialvalues.type);
        }
    }, [dispatch, initialvalues]);

    const handleSave = () => {
        const { step1, step2 } = state.formData;

        const payload = {
            name: step1.name,
            type: step1.type,
            credentials: step2,
        };
        delete payload.credentials.table;

        if (state.isEdit) {
            reduxDispatch(updateDatasource({ id: payload.credentials.id, payload }));
        } else {
            reduxDispatch(createDatasource({ payload }));
        }
        closeModal();
    };

    return (
        <div>
            <Steps current={state.step - 1} size="small">
                {steps.map((item) => (
                    <Step key={item.title} title={item.title} />
                ))}
            </Steps>
            <br />
            <div className="steps-content">{steps[state.step - 1].content}</div>
            <br />
            <div className="steps-action" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {state.step === steps.length && stateTypes !== 'easydata' && (
                    <Button type="primary" onClick={handleSave}>
                        Abschließen
                    </Button>
                )}
            </div>
        </div>
    );
};

export default DatasoureForm;
