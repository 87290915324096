/* eslint-disable import/no-anonymous-default-export */
import request from './request';

const getAllTenants = () => request.get('tenants');
const getTenant = (id) => request.get(`tenants/${id}`);
const addTenant = (payload) => request.post('tenants', payload);
const updateTenant = (id, payload) => request.put(`tenants/${id}`, payload);
const deleteTenant = (id) => request.delete(`tenants/${id}`);

export default {
    getAllTenants,
    getTenant,
    addTenant,
    updateTenant,
    deleteTenant
};
