import { Form, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { showError } from '../../../helpers/common';
import { addAttributeGroup, updateAttribute } from '../details/slices/process-details';
import FormMaker from '../details/sidebar/form-maker';
import AttributeGroup from './AttributeGroup';
import { useEffect, useRef } from 'react';

const AddAttributeGroupModal = ({ showAddAttributeGroup, setShowAddAttributeGroup, editMode, initialValues = {} }) => {
    const { t } = useTranslation();
    const [formRef] = Form.useForm();
    const dispatch = useDispatch();
    const { process } = useSelector((state) => state.processDetails);

    const onAddAttributeGroupSave = async (info) => {
        try {
            if (!editMode && info) {
                const payload = { ...info, processId: process.id };
                dispatch(addAttributeGroup(payload));
            }
            if (editMode) {
                const payload = {
                    name: initialValues.name,
                    type: initialValues.type,
                    attributeGroupId: info.attributeGroupId || null,
                    processId: process.id,
                };
                dispatch(updateAttribute({ id: initialValues.id, payload }));
            }
            setShowAddAttributeGroup(false);
            formRef.resetFields();
        } catch (err) {
            showError(err.message);
        }
    };

    let classMapping = useRef(null);
    let formFields = useRef([]);
    useEffect(() => {
        formRef.resetFields();
        classMapping.current = new AttributeGroup();
        formFields.current = editMode ? classMapping.current.getEditAttributes() : classMapping.current.getDefaultAttributes();

        if (editMode) {
            formRef.setFieldsValue({ attributeGroupId: initialValues.attributeGroupName });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formRef]);

    return (
        <Modal
            title={`${editMode ? t('attribute-group.update') : t('attribute-group.add')}`}
            maskClosable={false}
            open={showAddAttributeGroup}
            okText={t('action.save')}
            onOk={() => formRef.submit()}
            onCancel={() => {
                setShowAddAttributeGroup(false);
            }}
            destroyOnClose
        >
            <div style={{ marginTop: 20 }}>
                <FormMaker
                    formRef={formRef}
                    formFields={formFields.current}
                    classMapping={classMapping.current}
                    showDivider={false}
                    onFinish={onAddAttributeGroupSave}
                />
            </div>
        </Modal>
    );
};

export default AddAttributeGroupModal;
