import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import { Form, Layout } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useReactFlow } from 'reactflow';
import { nodeClassMapping } from '../plugins';
import FormMaker from './form-maker';
import { saveFlow } from '../slices/process-details';
const { Header, Content } = Layout;

const debounceMs = 500;

export const NodeDetails = () => {
    const { t } = useTranslation();
    const [formRef] = Form.useForm();
    const { selectedNode, isInteractionDisabled } = useSelector((state) => state.processDetails);

    const { getNode, setNodes } = useReactFlow();
    const dispatch = useDispatch();
    const node = getNode(selectedNode.id);
    const { nodeType } = node?.data || { nodeType: '' };

    const classMapping = nodeType && nodeClassMapping[nodeType] ? new nodeClassMapping[nodeType]() : null;
    const formFields = classMapping ? classMapping.getNodeAttributes() : [];
    const { id, data: nodeData } = node || { id: null, data: null };

    useEffect(() => {
        formRef.resetFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        const initialValues =
            formFields?.reduce((acc, obj) => {
                const name = obj.name;
                acc[name] = nodeData[name] || '';
                return acc;
            }, {}) || {};
        formRef.setFieldsValue(initialValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nodeData]);

    const debouncedSubmit = useCallback(
        debounce(async (values) => {
            setNodes((nds) =>
                nds.map((node) => {
                    if (node.id === id) {
                        node.data = {
                            ...node.data,
                            ...values,
                        };
                    }
                    return node;
                })
            );
            setTimeout(() => {
                dispatch(saveFlow());
            });
        }, debounceMs),
        [node]
    );

    if (!nodeType || !nodeClassMapping[nodeType]) {
        return;
    }

    return (
        <Layout className="layout-container">
            <Header className="info-header">{t('sidebar.node-details')}</Header>
            <Content className="info-content-container">
                <FormMaker
                    formRef={formRef}
                    layout="vertical"
                    formFields={formFields}
                    disabled={isInteractionDisabled}
                    classMapping={classMapping}
                    onChange={debouncedSubmit}
                    nodeData={nodeData}
                    id={id}
                />
            </Content>
        </Layout>
    );
};
