import EventNode from './EventNode';
import StartNode from './event/StartNode';
import MergeNode from './gateway/MergeNode';
import ExclusiveNode from './gateway/ExclusiveNode';
import InclusiveNode from './gateway/InclusiveNode';
import ParallelNode from './gateway/ParallelNode';
import ComplexNode from './gateway/ComplexNode';
import FormEditingNode from './activity/form-editing';
import { NODE_TYPES } from './constant';
import PoolNode from './default/PoolNode';
import LaneNode from './default/LaneNode';
import EndNode from './event/EndNode';
import ActivityNode from './ActivityNode';
import SimpleTaskNode from './activity/simple-task';

export const nodeClassMapping = {
    // gateway nodes
    [NODE_TYPES.EXCLUSIVE_GATEWAY]: ExclusiveNode,
    [NODE_TYPES.INCLUSIVE_GATEWAY]: InclusiveNode,
    [NODE_TYPES.PARALLEL_GATEWAY]: ParallelNode,
    [NODE_TYPES.COMPLEX_GATEWAY]: ComplexNode,
    [NODE_TYPES.MERGE]: MergeNode,

    // event nodes
    [NODE_TYPES.EVENT]: EventNode,
    [NODE_TYPES.START_NODE]: StartNode,
    [NODE_TYPES.END]: EndNode,
    
    // activity nodes
    [NODE_TYPES.ACTIVITY]: ActivityNode,
    [NODE_TYPES.FORM_EDITING]: FormEditingNode,
    [NODE_TYPES.SIMPLE_TASK]: SimpleTaskNode,
    
    // default nodes
    [NODE_TYPES.POOL]: PoolNode,
    [NODE_TYPES.LANE]: LaneNode,
};
