import React, { useEffect, useState, useCallback } from 'react';
import {useStateWithRef} from "../../../helpers/useStateWithRef";
import {Tabs, Form, Select, Button, Input} from 'antd';
import {
    getDatasources,
    testApi,
} from "../../../plugins/restapi/nodes/rest-api.method";
import {useTranslation} from "react-i18next";
import {showError} from "../../../helpers/common";
import {process} from "../../../services";

/**
 *
 * @param formInfo
 * @param fieldInfo
 * @param form
 * @param initialValues
 * @returns {JSX.Element}
 * @constructor
 */
const RestApi = ({ formInfo, fieldInfo, form, initialValues }) => {
    const { t } = useTranslation();
    const { TabPane } = Tabs;
    const [attributeList, setAttributeList] = useState([]);

    const [selectedDatasource, setSelectedDatasource, selectedDatasourceRef] = useStateWithRef(null);
    const [dataSourceOptions, setDataSourceOptions, dataSourceOptionsRef] = useStateWithRef([]);
    const [apiType, setApiType, apiTypeRef] = useStateWithRef('REST');
    const [selectedMethod, setSelectedMethod, selectedMethodRef] = useStateWithRef('GET');
    const [host, setHost, hostRef] = useStateWithRef('');
    const [selectedAttribute, setSelectedAttribute] = useState(null);

    const httpMethods = ['GET', 'POST', 'PUT']; // ['DELETE', 'GET', 'HEAD', 'OPTIONS', 'PATCH', 'POST', 'PUT']

    /**
     * handle attributes
     */
    const handleSelectAttribute = (selectedId) => {
        setSelectedAttribute(selectedId);
    };

    /**
     * fetch datasources
     */
    const columns = [
        {title: t('database-reader.column'), dataIndex: 'name', key: 'id'},
        {
            title: t('database-reader.attribute'),
            render: (text, record) => {
                return (
                    <div></div>
                );
            },
        },
    ];

    /**
     * set selected datasource and fetch tables data
     */
    const handleSelectDatasource = async (selectedDatasourceId) => {
        try {
            setSelectedDatasource(selectedDatasourceId); // set selected datasource

            // find the selected datasource object
            const selectedDatasourceObj = dataSourceOptionsRef.current.find(ds => ds.id === selectedDatasourceId);
            if (selectedDatasourceObj && selectedDatasourceObj?.datasource_credential) {
                // extract and set the host
                const hostValue = selectedDatasourceObj?.datasource_credential?.credentials?.host || '';
                setHost(hostValue);
            }
        } catch (error) {
            console.error(error);
        }
    };

    /**
     * fetch API data
     */
    const fetch = async () => {
        try {
            let datasource = dataSourceOptionsRef.current.find(ds => ds.id === selectedDatasourceRef.current);
            let credentials = datasource?.datasource_credential?.credentials;
            if (credentials) testApi(credentials);
        } catch (error) {
            console.error('Error occurred:', error);
        }
    };

    const handleSelectMethod = (method) => {
        setSelectedMethod(method);
    };

    const handleSelectApiType = (value) => {
        setApiType(value);

        // Automatically set to POST for SOAP
        if (value === 'SOAP') {
            setSelectedMethod('POST');
            form.setFieldsValue({ httpMethod: 'POST' }); // Also set the form value
        } else {
            // Set to default value for REST
            setSelectedMethod('GET');
            form.setFieldsValue({ httpMethod: 'GET' });
        }
    };

    /**
     * on startup...
     * load initial values
     */
    useEffect(() => {
        // load and handle initial values
        console.log(initialValues);
        const initialize = async () => {
            // load available datasources on startup
            try {
                const result = await getDatasources("restapi");
                setDataSourceOptions(result.data);
            } catch (error) {
                console.log(error);
            }

            // get attributes
            try {
                const res = await process.getAttributeList(formInfo?.processId);
                const newAttributeList = res?.data || [];
                newAttributeList.unshift('');
                setAttributeList(newAttributeList);
            } catch (err) {
                showError(err?.message);
            }

            // set initial values
            if (initialValues?.datasource) await handleSelectDatasource(initialValues.datasource);

            if (initialValues?.apiType === 'SOAP') {
                setApiType('SOAP');
                setSelectedMethod('POST');
            } else {
                setApiType('REST');
                setSelectedMethod(initialValues?.httpMethod || 'GET');
            }
            form.setFieldsValue( { apiType: apiTypeRef.current });
            form.setFieldsValue( { httpMethod: selectedMethodRef.current });
        }
        initialize();
    }, [formInfo, initialValues]);

    return (
        <div>

            <div>
                <Form.Item label="Datasource" name="datasource" rules={[{ required: true }]}>
                    <Select onChange={handleSelectDatasource}>
                        {dataSourceOptionsRef.current.map((option) => (
                            <Select.Option
                                key={option.id}
                                value={option.id}
                                selected={option.id === selectedDatasourceRef.current}
                            >
                                {option.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                {selectedDatasourceRef.current && (
                    <div>
                        <Form.Item label="API Type" name="apiType" rules={[{ required: true }]}>
                            <Select defaultValue="REST" onChange={handleSelectApiType}>
                                <Select.Option value="REST">REST</Select.Option>
                                <Select.Option value="SOAP">SOAP</Select.Option>
                            </Select>
                        </Form.Item>

                        {apiTypeRef.current === 'REST' && (
                            <Form.Item label="HTTP Method" name="httpMethod" rules={[{ required: true }]}>
                                <Select defaultValue="GET" onChange={handleSelectMethod} value={selectedMethodRef.current}>
                                    {httpMethods.map((method) => (
                                        <Select.Option key={method} value={method}>
                                            {method}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )}

                        {apiTypeRef.current === 'SOAP' && (
                            <Form.Item label="HTTP Method" name="httpMethod">
                                <Input value="POST" disabled />
                            </Form.Item>
                        )}

                        <Form.Item label="URL">
                            <Input
                                value={hostRef.current}
                                placeholder="URL"
                                onChange={(e) => {
                                    setHost(e.target.value);
                                }}
                            />
                        </Form.Item>

                        <Form.Item label="Target" name="attribute">
                            <Select onChange={handleSelectAttribute} value={selectedAttribute?.id}>
                                {attributeList.map((attribute) => (
                                    <Select.Option key={attribute.id} value={attribute.id}>
                                        {attribute.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <div>
                            <Button onClick={fetch} type="primary">Fetch</Button>
                            <br /><br />
                        </div>
                    </div>
                )}
            </div>

        </div>
    );
};


export default RestApi;
