import React from 'react';

const Footer = () => {
    return (
        <React.Fragment>
            <footer className="footer">
                <div className="text-center">{new Date().getFullYear()} © ProDyne GmbH</div>
            </footer>
        </React.Fragment>
    );
};

export default Footer;
