import React, { createContext, useReducer, useContext } from 'react';

const DatasourceContext = createContext();

const initialState = {
    isEdit: false,
    databases: false,
    step: 1,
    formData: {
        step1: { name: '', type: '' },
        step2: { username: '', password: '', host: '', port: '' },
        step3: { },
        'step2-easy-data': {tableHeaders: [], tableRows: []}
    },
};

function reducer(state, action) {
    switch (action.type) {
        case 'SET_EDIT_MODE':
            return { ...state, isEdit: action.isEdit };
        case 'SET_DATABASES':
            return { ...state, databases: action.databases };
        case 'NEXT_STEP':
            return { ...state, step: state.step + 1 };
        case 'PREVIOUS_STEP':
            return { ...state, step: state.step - 1 };
        case 'UPDATE_DATA':
            return {
                ...state,
                formData: {
                    ...state.formData,
                    [action.step]: { ...state.formData[action.step], ...action.data },
                },
            };
        case 'SET_DATA':
            const updatedFormData = { ...state.formData };
            for (const stepKey in action.data) {
                if (stepKey in updatedFormData) {
                    updatedFormData[stepKey] = { ...updatedFormData[stepKey], ...action.data[stepKey] };
                }
            }
            return {
                ...state,
                formData: updatedFormData,
            };

        default:
            return state;
    }
}

function DatasourceContextProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <DatasourceContext.Provider value={{ state, dispatch }}>
            {children}
        </DatasourceContext.Provider>
    );
}

function useDatasourceContext() {
    const context = useContext(DatasourceContext);
    if (!context) {
        throw new Error('useDatasourceContext must be used within a DatasourceContextProvider');
    }
    return context;
}

export { DatasourceContextProvider, useDatasourceContext };
