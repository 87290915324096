import { DeleteOutlined } from '@ant-design/icons';
import { Button, Form, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Label, Spinner } from 'reactstrap';
import clone from 'rfdc/default';
import { v4 as uuidv4 } from 'uuid';
import { useStateWithRef } from '../../../helpers/useStateWithRef';

import { useDatasourceContext } from './datasource-context';
import { createDatasource, updateDatasource } from '../slices/datasource';
import { useDispatch } from 'react-redux';

function DataPoints({ closeModal }) {
    const { t } = useTranslation();
    const { state, dispatch } = useDatasourceContext();
    const [loading] = useState(false);
    const [form] = Form.useForm();
    const reduxDispatch = useDispatch();

    const [tableHeaders, setTableHeaders, tableHeadersRef] = useStateWithRef(state?.formData?.['step2-easy-data']?.tableHeaders || []);
    const [tableRows, setTableRows, tableRowsRef] = useStateWithRef(state?.formData?.['step2-easy-data']?.tableRows || []);

    const getTableColumn = (columnId, value = '') => {
        const tableColumn = {
            title: (
                <div className="d-flex align-items-center">
                    <Form.Item
                        name={`header-${columnId}`}
                        className="mb-0"
                        required
                        initialValue={value}
                        messageVariables={{ label: t('datasource.column-name') }}
                        // eslint-disable-next-line no-template-curly-in-string
                        rules={[{ required: true, message: '${label} is required' }]}
                    >
                        <Input
                            placeholder={t('datasource.column-name-placeholder')}
                            label={t('datasource.name')}
                            onChange={(event) => changeColumnLabel(columnId, event.target.value)}
                        />
                    </Form.Item>
                    <DeleteOutlined style={{ marginLeft: 10, color: 'red' }} onClick={() => deleteColumn(columnId)} />
                </div>
            ),
            dataIndex: `column-${columnId}`,
            key: columnId,
            value,
            render: (_, record) => {
                return (
                    <Form.Item
                        name={`${record.key}-${columnId}`}
                        className="mb-0"
                        required
                        initialValue={record[`column-${columnId}`] || ''}
                        rules={[{ required: true, message: 'Please enter a value' }]}
                    >
                        <Input onChange={(event) => changeRowData(record.key, columnId, event.target.value)} />
                    </Form.Item>
                );
            },
        };
        return tableColumn;
    };

    const onFinish = () => {
        if (!tableHeadersRef.current?.length || !tableRowsRef.current?.length) {
            alert('Please enter data points.');
            return;
        }

        const { step1, step2 } = state.formData;
        const payload = {
            name: step1.name,
            type: step1.type,
            tableHeaders: tableHeadersRef.current.map((header) => ({
                dataIndex: header.dataIndex,
                key: header.key,
                value: header.value,
            })),
            tableRows: tableRowsRef.current,
        };
        if (step2?.id) {
            reduxDispatch(updateDatasource({ id: step2?.id, payload }));
        } else {
            reduxDispatch(createDatasource({ payload }));
        }
        closeModal();
    };

    const handleRowAdd = () => {
        let newRow = {};
        for (const header of tableHeadersRef.current) {
            newRow[header.dataIndex] = '';
            newRow.key = uuidv4();
        }
        setTableRows([...tableRowsRef.current, newRow]);
    };

    const changeColumnLabel = (columnId, text) => {
        const clonedTableHeaders = clone(tableHeadersRef.current);
        const header = clonedTableHeaders.find((row) => row.dataIndex === `column-${columnId}`);
        if (header) {
            header.value = text;
        }
        setTableHeaders(clonedTableHeaders);
    };

    const deleteColumn = (columnId) => {
        const clonedTableHeaders = clone(tableHeadersRef.current);
        const index = clonedTableHeaders.findIndex((row) => row.dataIndex === `column-${columnId}`);
        if (index > -1) {
            clonedTableHeaders.splice(index, 1);
        }
        setTableHeaders(clonedTableHeaders);

        if (!clonedTableHeaders.length) {
            setTableRows([]);
            return;
        }

        const clonedTableRows = clone(tableRowsRef.current);
        clonedTableRows.forEach((row) => {
            delete row[`column-${columnId}`];
        });
        setTableRows(clonedTableRows);
    };

    const deleteRow = (rowId) => {
        const clonedTableRows = clone(tableRowsRef.current);
        const index = clonedTableRows.findIndex((row) => row.key === rowId);
        if (index > -1) {
            clonedTableRows.splice(index, 1);
        }
        setTableRows(clonedTableRows);
    };

    const changeRowData = (rowKey, columnId, value) => {
        const clonedTableRows = clone(tableRowsRef.current);
        const rowData = clonedTableRows.find((row) => row.key === rowKey);
        if (rowData) {
            rowData[`column-${columnId}`] = value;
        }
        setTableRows(clonedTableRows);
    };

    const handleColumnAdd = () => {
        const columnId = uuidv4();
        const newColumn = getTableColumn(columnId);
        setTableHeaders([...tableHeadersRef.current, newColumn]);
    };

    const getTableHeaders = () => {
        if (!tableHeadersRef.current?.length) {
            return [];
        }
        return [
            ...tableHeaders,
            {
                title: '',
                dataIndex: 'action',
                render: (_, record) => {
                    return <DeleteOutlined style={{ color: 'red' }} onClick={() => deleteRow(record.key)} />;
                },
            },
        ];
    };

    const handlePrevious = () => {
        dispatch({ type: 'PREVIOUS_STEP' });
    };

    useEffect(() => {
        if (tableHeadersRef.current?.length) {
            const tableColumns = [];
            for (const header of tableHeaders) {
                tableColumns.push(getTableColumn(header.key, header.value));
            }
            setTableHeaders(tableColumns);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableHeadersRef.current]);

    return (
        <Form form={form} name="step2-easydata">
            <div className="d-flex align-items-center justify-content-between mb-2">
                <Label className="mb-0">Data</Label>
                <div>
                    <Button onClick={handleColumnAdd} type="primary">
                        Add column
                    </Button>
                    <Button className="mx-2" disabled={!tableHeaders.length} onClick={handleRowAdd} type="primary">
                        Add row
                    </Button>
                </div>
            </div>
            <Table columns={getTableHeaders()} dataSource={tableRows} bordered pagination={false} className="mb-4" />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button onClick={handlePrevious}>Previous</Button>

                <Button type="primary" disabled={loading} onClick={onFinish}>
                    {!loading && t('action.save')}
                    {loading && <Spinner size="sm"></Spinner>}
                </Button>
            </div>
        </Form>
    );
}

export default DataPoints;
