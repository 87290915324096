import { Button, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import { connectionHandler } from '../../utils/services';
import { useDatasourceContext } from '../modules/datasource-context';

const Step2 = () => {
    const { state, dispatch } = useDatasourceContext();
    const [formFields, setFormFields] = useState([]);
    const [connectionResult, setConnectionResult] = useState(null);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const testConnection = async () => {
        try {
            setLoading(true);
            const values = await form.validateFields();
            const credentials = {
                ...values,
                type: state.formData.step1.type,
            };
            credentials.type = state.formData.step1.type;
            const connection = await connectionHandler.testConnection(credentials);
            setConnectionResult(connection);
        } catch (error) {
            // Handle error
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (state.formData.step1.type) {
            let newFormFields = [];
            const credentials = state.databases[state.formData.step1.type]?.credentials;
            if (credentials) {
                Object.keys(credentials).forEach((credential, index) => {
                    newFormFields.push({
                        type: credentials[credential].type,
                        name: credential,
                        label: credential,
                        required: credentials[credential].required,
                        placeholder: 'Enter ' + credential,
                        rules: [{ required: true }],
                    });
                });
            }
            setFormFields(newFormFields);
        }
    }, [state.formData.step1.type, state.databases]);

    // submit form
    const onFinish = (values) => {
        dispatch({ type: 'UPDATE_DATA', step: 'step2', data: values });
        dispatch({ type: 'NEXT_STEP' });
    };

    const handlePrevious = () => {
        dispatch({ type: 'PREVIOUS_STEP' });
    };

    return (
        <Form form={form} name="step2" initialValues={state.formData.step2} onFinish={onFinish}>
            {formFields.map((field, index) => (
                <Form.Item key={field.name} label={field.label} name={field.name} rules={field.rules}>
                    <Input type={field.type} placeholder={field.placeholder} />
                </Form.Item>
            ))}

            <div style={{ textAlign: 'center' }}>
                {loading && <Spinner size="sm"></Spinner>}
                {connectionResult &&
                    (connectionResult.success ? (
                        <span style={{ color: 'green' }}>
                            ✔ Verbindung erfolgreich
                            <br />
                            <br />
                        </span>
                    ) : (
                        <span style={{ color: 'red' }}>
                            ❌ Verbindung fehlgeschlagen
                            <br />
                            <br />
                        </span>
                    ))}
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button onClick={handlePrevious}>Previous</Button>
                <Button onClick={testConnection} color="success" disabled={loading}>
                    {!loading && 'Verbindung testen'}
                    {loading && <Spinner size="sm"></Spinner>}
                </Button>
                <Button type="primary" htmlType="submit">
                    Next
                </Button>
            </div>
        </Form>
    );
};

export default Step2;
