import { useDispatch } from 'react-redux';
import { useReactFlow, useStoreApi } from 'reactflow';
import { v4 as uuidv4 } from 'uuid';
import { saveFlow, setSelectedNode } from '../slices/process-details';
import { nodeClassMapping } from '../plugins';

function useEdgeClick(id, newNodeNodeType, callback = () => false) {
    const { setEdges, setNodes, getNode, getEdge } = useReactFlow();
    const store = useStoreApi();
    const dispatch = useDispatch();

    const onClick = () => {
        // first we retrieve the edge object to get the source and target id
        const edge = getEdge(id);

        if (!edge) {
            return;
        }

        // we retrieve the target node to get its position
        const targetNode = getNode(edge.target);

        if (!targetNode) {
            return;
        }

        let defaultNodeData = {};
        if (nodeClassMapping[newNodeNodeType]) {
            const classMapping = new nodeClassMapping[newNodeNodeType]();
            defaultNodeData = classMapping.getDefaultNodeData();
        }

        // create a unique id for newly added elements
        const insertNodeId = uuidv4();

        // this is the node object that will be added in between source and target node
        const insertNode = {
            id: insertNodeId,
            data: { nodeType: newNodeNodeType, ...defaultNodeData },
            style: { minWidth: 80, minHeight: 80 },
            position: { x: 0, y: 0 },
            selected: true,
            type: defaultNodeData.type,
        };

        // new connection from source to new node
        const sourceEdge = {
            id: `${edge.source}->${insertNodeId}`,
            source: edge.source,
            target: insertNodeId,
            type: 'workflow',
        };

        // new connection from new node to target
        const targetEdge = {
            id: `${insertNodeId}->${edge.target}`,
            source: insertNodeId,
            target: edge.target,
            type: 'workflow',
        };

        // remove the edge that was clicked as we have a new connection with a node inbetween
        setEdges((edges) => edges.filter((e) => e.id !== id).concat([sourceEdge, targetEdge]));

        // insert the node between the source and target node in the react flow state
        setNodes((nodes) => nodes.concat(insertNode));

        setTimeout(() => {
            const { addSelectedNodes } = store.getState();
            addSelectedNodes([insertNodeId]);
            dispatch(setSelectedNode(insertNode));
            dispatch(saveFlow());
        });
        callback();
    };

    return onClick;
}

export default useEdgeClick;
