/* eslint-disable no-param-reassign */
import axios from 'axios';
import { API_URL } from '../constants/url';

const request = axios.create();

let REQUEST_URLS = [];

request.interceptors.request.use(
    (config) => {
        REQUEST_URLS.push(config.url);
        config.baseURL = `${API_URL}`;
        if (localStorage.getItem('authUser')) {
            const user = JSON.parse(localStorage.getItem('authUser'));
            config.headers = {
                Authorization: `Bearer ${user.token}`,
                Accept: 'application/json',
            };
        }
        return config;
    },
    (error) => Promise.reject(error)
);

request.interceptors.response.use(
    (response) => {
        const { data, headers } = response;
        if (data instanceof ArrayBuffer) {
            if (headers['content-type'] === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                const url = window.URL.createObjectURL(
                    new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                );
                const fileName = headers['content-disposition'].split('filename=')[1].split(';')[0];
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName.replaceAll('"', ''));
                document.body.appendChild(link);
                link.click();
                return '';
            }
            const parsedResponse = JSON.parse(new TextDecoder().decode(data));
            return parsedResponse?.data || '';
        }
        return data;
    },
    (error) => {
        if (error.response.status === 401 && error.response?.data?.code === 'invalid_token') {
            localStorage.removeItem('authUser');
            localStorage.clear();
            window.location.href = '/login';
        }
        if (error.response) {
            return Promise.reject(error.response.data);
        }
        if (error.request) {
            return Promise.reject(error.request);
        }
        return Promise.reject(error);
    }
);

export default request;
