import { datasource } from "../../datasource/services/index";
import { connectionHandler } from "../../utils/services";

const getDatasources = () => {
    return datasource.getAllDatasources();
}

const fetchApiData = (payload) => {
    return connectionHandler.getApiData(payload);
}

const testApi = (payload) => {
    return datasource.testApi(payload);
}

export {
    getDatasources,
    fetchApiData,
    testApi
}
