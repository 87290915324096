import React, { useEffect, useRef } from "react";
import { ReactFormBuilder } from 'flow-form-builder';
import CustomFormElementsEdit from './form-elements-edit';
//import DemoBar from '../formular/demobar';

import 'flow-form-builder/dist/app.css';
import {useTranslation} from "react-i18next";

//https://github.com/Kiho/react-form-builder/
//https://mui.com/material-ui/react-accordion/
//https://codesandbox.io/s/flow-form-builder-crim4

const CustomReactFormBuilder = ({ toolbarItems, formSubmitHandler, formInitHandler }) => {
    const { t } = useTranslation();
    const formBuilderRef = useRef(null);

    const handleLoad = () => {
        return Promise.resolve(formInitHandler());
    };

    const handlePost = (data) => {
        if (data.task_data) {
            const jsonFormData = JSON.stringify(data.task_data);
            formSubmitHandler(jsonFormData);
        }
    };

    // useEffect(() => {
    //     if (formBuilderRef.current) {
    //         // adjust dropzone height
    //         const formBuilderHeight = formBuilderRef.current.querySelector('.react-form-builder').clientHeight;
    //         if (formBuilderHeight) {
    //             const formPlaceHolder = formBuilderRef.current.querySelector('.form-place-holder');
    //             if (formPlaceHolder) formPlaceHolder.style.height = `${formBuilderHeight}px`;
    //         }

    //         // replace dropzone placeholder
    //         const dropzoneElement = formBuilderRef.current.querySelector('.form-place-holder > div');
    //         if (dropzoneElement) {
    //             dropzoneElement.innerText = t('forms.drophere');
    //         }
    //     }

    //     // replace toolbox header
    //     const toolbarElement = document.querySelector('.react-form-builder-toolbar');
    //     const toolbarElementHeader = toolbarElement.querySelector('h4');
    //     if (toolbarElementHeader) {
    //         toolbarElement.removeChild(toolbarElementHeader);
    //     }
    // }, [t]);

    return (
        <div className="form-builder-container" style={{ flex: 1, height: "100%" }} ref={formBuilderRef}>
            <ReactFormBuilder
                toolbarItems={toolbarItems}
                //customToolbarItems={toolbarItems}
                onPost={handlePost}
                onLoad={handleLoad}
                renderEditForm={props => <CustomFormElementsEdit {...props} />}
            />
        </div>
    );
};

export default CustomReactFormBuilder;
