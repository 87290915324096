import { datasource } from "../services/index";
import { connectionHandler } from "../../utils/services";

// get all available datasources
const getDatasources = () => {
    return datasource.getAllDatasources();
}

// test reader execute
const testReader = (payload) => {
    return datasource.testReader(payload);
}

// get tables from selected datasource
const getTablesFromDatasource = (payload) => {
    return connectionHandler.getTablesFromDatasource(payload);
}

// get content from selected table
const getTableContent = (payload) => {
    return connectionHandler.getTableContentFromDatasource(payload);
}

// get result from a query
const getQueryResult = (payload) => {
    return connectionHandler.getQueryResulFromDatasource(payload);
}

export {
    getDatasources,
    getTablesFromDatasource,
    getTableContent,
    getQueryResult,
    testReader,
}
