import { generatePath } from './utils.js';

function Triangle({ width, height, ...svgAttributes }) {
    const trianglePath = generatePath([
        [0, height],
        [width / 2, 0],
        [width, height],
    ]);

    return <path d={trianglePath} {...svgAttributes} />;
}

export default Triangle;
