import { configureStore } from '@reduxjs/toolkit';

import reducer from './reducers';

const store = configureStore({
    reducer: reducer,
    devTools: true,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ['process-details/setRfInstance'],
                // Ignore these paths in the state
                ignoredPaths: [/processDetails.rfInstance/],
            },
        }),
});

export default store;
