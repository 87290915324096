export const MENU_ITEMS = [
    {
        id: 1,
        route: '/dashboard',
        label: 'menu.settings.dashboard',
        icon: 'fas fa-home'
    },
    {
        id: 2,
        route: '/my-operations',
        label: 'menu.settings.my-operations',
        icon: 'fab fa-stack-exchange'
    },
    {
        id: 3,
        route: '/tenant',
        label: 'menu.settings.tenant',
        icon: 'fas fa-house-user'
    },
    {
        id: 4,
        route: '/user',
        label: 'menu.settings.user',
        icon: 'fas fa-user'
    },
    {
        id: 5,
        route: '/role',
        label: 'menu.settings.role',
        icon: 'fas fa-users-cog'
    },
    {
        id: 6,
        route: '/process',
        label: 'menu.settings.process',
        icon: 'fas fa-tasks'
    },
    {
        id: 7,
        route: '/datasources',
        label: 'menu.settings.data-sources',
        icon: 'fas fa-table'
    }
];