import { Form, Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { showError } from '../../../helpers/common';
import FormMaker from '../details/sidebar/form-maker';
import { createViews, updateView } from '../details/slices/process-details';
import Views from './Views';
import { removeKeys } from '../details/utils';

const DEFAULT_FORM_VALUE = {
    name: '',
    views_attributes: [{ attributeId: '', isVisibleDesktop: false, isVisibleMobile: false, isStickyInGroup: false }],
};

const AddViewModal = ({
    showModal,
    setShowModal,
    initialData = DEFAULT_FORM_VALUE,
    setSelectedRecord = () => false,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [formRef] = Form.useForm();
    const { process } = useSelector((state) => state.processDetails);

    const handleSave = async (data) => {
        try {
            let payload;

            if (initialData?.id) {
                payload = { ...data };
                payload = removeKeys(payload, ['key']);
                dispatch(updateView({ id: initialData.id, payload }));
            } else {
                payload = { ...data, processId: process.id };
                payload = removeKeys(payload, ['key']);
                dispatch(createViews(payload));
            }

            closeModal();
        } catch (err) {
            showError(err?.message);
        }
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedRecord(null);
    };

    let classMapping = new Views();
    let formFields = classMapping.getDefaultAttributes();

    formRef.setFieldsValue(initialData ?? DEFAULT_FORM_VALUE);

    return (
        <Modal
            open={showModal}
            onCancel={closeModal}
            destroyOnClose
            centered
            okText={t('action.save')}
            onOk={() => formRef.submit()}
            style={{ minWidth: '600px', minHeight: '600px' }}
            maskClosable={false}
        >
            <div style={{ marginTop: 20 }}>
                <FormMaker
                    formRef={formRef}
                    formFields={formFields}
                    classMapping={classMapping}
                    showDivider={false}
                    onFinish={handleSave}
                />
            </div>
        </Modal>
    );
};

export default AddViewModal;
