import { useEffect, useState } from 'react';
import { showError } from '../../helpers/common';
import { dashboard, forms, tasks } from '../../services';
import FormRenderer from './form-renderer';
import { getDefaultAttributes } from './utils';

const DashboardForm = ({ processId, nodeInfo }) => {
    const [lastTask, setLastTask] = useState(null);
    const [formData, setFormData] = useState(null);

    const getLastTask = async () => {
        try {
            const res = await tasks.getLastTask(nodeInfo.rfNodeId, processId);
            setLastTask(res || null);
        } catch (err) {
            showError(err?.message);
        }
    };

    const getFormFields = async () => {
        try {
            if (nodeInfo) {
                const res = await forms.getFormData(nodeInfo?.form);
                setFormData(res?.data);
            }
        } catch (err) {
            showError(err?.message);
        }
    };

    useEffect(() => {
        getLastTask();
        getFormFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [processId]);

    const onDashboardAction = async (payload) => {
        await dashboard.handleDashboardActions(payload);
        await getLastTask();
    };

    const onSubmit = (actionButton, data) => {
        if (actionButton?.action) {
            const nAttr = {};
            data?.forEach((row) => {
                nAttr[row?.name?.split('_')[0]] = row?.value;
            });
            const attr = { ...getDefaultAttributes(actionButton) };

            onDashboardAction({
                actionKey: actionButton?.action,
                actionData: { subTaskId: lastTask?.id, attributes: { ...nAttr, ...attr }, processId },
            });
        }
    };

    return (
        <div>
            <h5>{nodeInfo?.name}</h5>
            {!lastTask ? (
                <>No task found.</>
            ) : (
                <FormRenderer
                    onSubmit={onSubmit}
                    formData={formData}
                    formValues={lastTask}
                />
            )}
        </div>
    );
};

export default DashboardForm;