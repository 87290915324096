import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { datasource } from '../services';
import { showError } from '../../../helpers/common';

const initialState = {
    datasources: [],
    loading: false,
    error: '',
};

export const getOneAvailableDatasource = createAsyncThunk('datasources/list/', async ({ id }, { rejectWithValue }) => {
    try {
        const res = await datasource.getAllAvailableDatasources(id);
        return res.data;
    } catch (error) {
        const message = error.message || error.toString();
        showError(message);
        return rejectWithValue(message);
    }
});

export const getAllAvailableDatasources = createAsyncThunk('datasources/list/all', async (_, { rejectWithValue }) => {
    try {
        const res = await datasource.getAllAvailableDatasources();
        return res.data;
    } catch (error) {
        const message = error.message || error.toString();
        showError(message);
        return rejectWithValue(message);
    }
});

export const getAllDatasources = createAsyncThunk('datasources/all', async (_, { rejectWithValue }) => {
    try {
        const res = await datasource.getAllDatasources();
        return res.data;
    } catch (error) {
        const message = error.message || error.toString();
        showError(message);
        return rejectWithValue(message);
    }
});

export const createDatasource = createAsyncThunk('datasources/create', async ({ payload }, { rejectWithValue }) => {
    try {
        const res = await datasource.addDatasource(payload);
        return res.data;
    } catch (error) {
        const message = error.message || error.toString();
        showError(message);
        return rejectWithValue(message);
    }
});

export const updateDatasource = createAsyncThunk('datasources/update', async ({ id, payload }, { rejectWithValue }) => {
    try {
        await datasource.updateDatasource(id, payload);
        return payload;
    } catch (error) {
        const message = error.message || error.toString();
        showError(message);
        return rejectWithValue(message);
    }
});

export const deleteDatasource = createAsyncThunk('datasources/delete', async ({ id }, { rejectWithValue }) => {
    try {
        await datasource.deleteDatasource(id);
        return true;
    } catch (error) {
        const message = error.message || error.toString();
        showError(message);
        return rejectWithValue(message);
    }
});

const datasourceSlice = createSlice({
    name: 'datasource',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(createDatasource.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(createDatasource.fulfilled, (state, action) => {
                state.loading = false;
                state.datasources.unshift(action.payload);
            })
            .addCase(createDatasource.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(getAllDatasources.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getAllDatasources.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(deleteDatasource.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(deleteDatasource.fulfilled, (state, action) => {
                state.loading = false;
                const {
                    arg: { id },
                } = action.meta;
                if (id) {
                    state.datasources = state.datasources.filter((item) => item.id !== id);
                }
            })
            .addCase(deleteDatasource.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(updateDatasource.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(updateDatasource.fulfilled, (state, action) => {
                state.loading = false;
                const {
                    arg: { id },
                } = action.meta;
                if (id) {
                    state.datasources = state.datasources.map((item) =>
                        item.id === id ? { ...item, ...action.payload } : item
                    );
                }
            })
            .addCase(updateDatasource.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(getAllAvailableDatasources.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getAllAvailableDatasources.fulfilled, (state, action) => {
                state.loading = false;
                state.datasources = action.payload;
            })
            .addCase(getAllAvailableDatasources.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(getOneAvailableDatasource.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getOneAvailableDatasource.fulfilled, (state, action) => {
                state.loading = false;
                state.datasources = action.payload;
            })
            .addCase(getOneAvailableDatasource.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            });
    },
});

const { reducer } = datasourceSlice;
export default reducer;
