import React, { useEffect } from 'react';
import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import withRouter from '../../components/common/withRouter';
import { useSelector, useDispatch } from 'react-redux';
import { login } from '../../store/slices/auth';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import logoProdyne from '../../assets/images/prodyne_logo.svg';
import logolight from '../../assets/images/logo-light.png';
import { clearMessage } from '../../store/slices/message';
import { useTranslation } from 'react-i18next';

const Login = (props) => {
    document.title = ' Login';
    const { t } = useTranslation();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(clearMessage());
    }, [dispatch]);

    const formik = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            username: '',
            password: '',
        },
        validationSchema: Yup.object({
            username: Yup.string().required(t('error-message.username-required')),
            password: Yup.string().required(t('error-message.password-required')),
        }),
        onSubmit: (values) => {
            dispatch(login({ ...values, navigate }));
        },
    });

    const { message: error } = useSelector((state) => state.message);

    useEffect(() => {
        document.body.className = 'authentication-bg';
        // remove classname when component will unmount
        return function cleanup() {
            document.body.className = '';
        };
    });

    return (
        <React.Fragment>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="text-center">
                                <Link to="/" className="mb-5 d-block auth-logo">
                                    <img src={logoProdyne} alt="" height="22" className="logo logo-dark" />
                                    <img src={logolight} alt="" height="22" className="logo logo-light" />
                                </Link>
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-center justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card>
                                <CardBody className="p-4">
                                    <div className="p-2 mt-4">
                                        <Form
                                            className="form-horizontal"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                formik.handleSubmit();
                                                return false;
                                            }}
                                        >
                                            {error ? <Alert color="danger">{error}</Alert> : null}

                                            <div className="mb-3">
                                                <Label className="form-label">{t('login.username')}</Label>
                                                <Input
                                                    name="username"
                                                    className="form-control"
                                                    placeholder={t('login.username-placeholder')}
                                                    type="username"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.username || ''}
                                                    invalid={
                                                        formik.touched.username && formik.errors.username ? true : false
                                                    }
                                                />
                                                {formik.touched.username && formik.errors.username ? (
                                                    <FormFeedback type="invalid">{formik.errors.username}</FormFeedback>
                                                ) : null}
                                            </div>

                                            <div className="mb-3">
                                                <div className="float-end">
                                                    <Link to="/forgot-password" className="text-muted">
                                                        {t('login.forgot-password')}
                                                    </Link>
                                                </div>
                                                <Label className="form-label">{t('login.password')}</Label>
                                                <Input
                                                    name="password"
                                                    value={formik.values.password || ''}
                                                    type="password"
                                                    placeholder={t('login.password-placeholder')}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    invalid={
                                                        formik.touched.password && formik.errors.password ? true : false
                                                    }
                                                />
                                                {formik.touched.password && formik.errors.password ? (
                                                    <FormFeedback type="invalid">{formik.errors.password}</FormFeedback>
                                                ) : null}
                                            </div>

                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customControlInline"
                                                />
                                                <label className="form-check-label" htmlFor="customControlInline">
                                                {t('login.remember-me')}
                                                </label>
                                            </div>

                                            <div className="mt-3">
                                                <button
                                                    className="btn btn-primary w-100 waves-effect waves-light"
                                                    type="submit"
                                                >
                                                    {t('login.login')}
                                                </button>
                                            </div>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                                <p>© {new Date().getFullYear()} ProDyne GmbH</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(Login);
