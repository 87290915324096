import { Divider, Form, Input, InputNumber } from 'antd';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Options } from './options';
import InlineEdit from './inline-edit';

const FormMaker = ({
    formRef,
    layout = 'horizontal',
    disabled = false,
    formFields = [],
    id = null,
    classMapping = null,
    showDivider = true,
    onChange = () => false,
    onFinish = () => false,
    ...rest
}) => {
    const { t } = useTranslation();

    const getField = (attribute) => {
        const { type } = attribute;
        if (type === 'text') {
            return <Input />;
        }
        if (type === 'select') {
            return <Options nodeId={id} attribute={attribute} classMapping={classMapping} mode={attribute?.mode} />;
        }
        if (type === 'number') {
            return <InputNumber />;
        }
        if (type === 'inline-edit') {
            const initialValue = formRef.getFieldValue(attribute.name);
            return <InlineEdit initialValue={initialValue} defaultValue={null} />;
        }
        if (type === 'custom-component' && attribute.component) {
            const Component = attribute.component.type;
            return <Component nodeId={id} {...rest} />;
        }
    };

    const onValuesChange = (updatedValues, allValues) => {
        onChange(allValues);
    };

    return (
        <Form layout={layout} form={formRef} onValuesChange={onValuesChange} disabled={disabled} onFinish={onFinish}>
            {formFields.map((field) => {
                let renderComponent = true;
                const {
                    name,
                    displayName,
                    required,
                    isRenderedConditionally,
                    conditionalRenderingMethod,
                    rules,
                    isFormsList,
                } = field;
                if (isRenderedConditionally && conditionalRenderingMethod) {
                    const method = classMapping?.methods?.renderingOption?.[conditionalRenderingMethod];
                    renderComponent = method?.call(this, id);
                }
                let Component = null;
                if (isFormsList) {
                    Component = field?.component?.type;
                }
                if (renderComponent) {
                    return (
                        <Fragment key={field.id}>
                            {!isFormsList && (
                                <Form.Item
                                    key={name}
                                    label={t(displayName)}
                                    name={name}
                                    required={required}
                                    rules={rules || []}
                                >
                                    {getField(field)}
                                </Form.Item>
                            )}
                            {showDivider && <Divider style={{ marginBottom: 16 }} />}
                            {isFormsList && <Component form={formRef} />}
                        </Fragment>
                    );
                }
                return <></>;
            })}
        </Form>
    );
};

export default React.forwardRef(FormMaker);
