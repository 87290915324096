import { Select } from 'antd';
import React, { useState } from 'react';
import { showError } from '../../../helpers/common';

export const AddSelectField = ({
    options = [],
    placeholder = '',
    onAdd = () => false,
    initialValue = '',
    onSelect = () => false,
}) => {
    const [searchV, setSearchV] = useState('');

    const onSearch = (value) => {
        setSearchV(value);
    };

    const onOptionChange = (val) => {
        onSelect(val);
    };

    const onAddOption = async () => {
        if (searchV) {
            try {
                await onAdd(searchV);
                setSearchV('');
            } catch (err) {
                showError(err.message);
            }
        }
    };

    return (
        <>
            <label>Tab Name</label>
            <Select
                showSearch
                value={initialValue}
                onChange={onOptionChange}
                placeholder={placeholder}
                optionFilterProp="children"
                searchValue={searchV}
                onSearch={onSearch}
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                notFoundContent={
                    <div onClick={onAddOption} style={{ paddingInline: 5, color: '#000' }}>
                        + Click here to create new tab <a style={{ color: 'blue' }}>'{searchV}'</a>
                    </div>
                }
                options={options}
                style={{ textAlign: 'left' }}
                allowClear
            />
        </>
    );
};
