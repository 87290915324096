import { useDispatch } from 'react-redux';
import { useReactFlow } from 'reactflow';
import { saveFlow } from '../slices/process-details';
import { getDefaultPoolAndLane } from '../constant';
import { fixPoolPosition } from '../utils';

function useAddPool(id) {
    const { setNodes, getNode, getNodes } = useReactFlow();
    const dispatch = useDispatch();

    const onClick = () => {
        // we need the parent node object for getting its position
        const parentNode = getNode(id);
        if (!parentNode) {
            return;
        }

        const nodes = getNodes();
        let nodeIndex = -1;
        let position = { x: 0, y: 0 };

        if (parentNode) {
            position = { x: parentNode.position.x, y: parentNode.style.height + parentNode.position.y + 20 };
            nodeIndex = nodes.findIndex((n) => n.id === id);
        }

        const { poolAndLane } = getDefaultPoolAndLane();
        poolAndLane[0].position = position;

        nodes.splice(nodeIndex + 1, 0, ...poolAndLane);
        fixPoolPosition(nodes);
        setNodes(nodes);
        setTimeout(() => {
            dispatch(saveFlow());
        });
    };
    return onClick;
}

export default useAddPool;
