
import EventNode from '../EventNode';
import { DashboardVisibility } from '../components/DashboardVisiblity';
import { NODE_TYPES } from '../constant';

class StartNode extends EventNode {
    constructor() {
        super();
        this.displayName = 'Start';
        this.description = '';
        this.name = 'start';
        this.color = '#63A817';
        this.icon = '';
        this.type = 'shape';
        this.nodeShape = 'circle';
        this.hideTargetHandle = true;
        this.nodeType = NODE_TYPES.START_NODE;
    }

    getNodeAttributes() {
        const defaultAttributes = this.getDefaultAttributes();
        return [
            ...defaultAttributes,
            {
                displayName: 'Dashboard Settings',
                name: 'dashboard-settings',
                description: 'Dashboard Settings',
                type: 'custom-component',
                component: <DashboardVisibility />
            },
            {
                displayName: 'Dashboard Button Label',
                name: 'dashboard-button-label',
                description: 'Dashboard Button Label',
                type: 'text',
            },
        ];
    }

    getDefaultNodeData() {
        return {
            name: this.displayName,
            type: this.type,
            nodeType: this.nodeType,
            'dashboard-settings': {
                showOnDashboard: true
            },
            'dashboard-button-label': 'Start'
        }
    }
}

export { StartNode as default };
