import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Input, Modal, ModalBody, ModalHeader, Row, UncontrolledTooltip, Button } from 'reactstrap';
import Breadcrumbs from '../../../components/breadcrumb/Breadcrumb';
import { DeleteModal } from '../../../components/delete-modal/DeleteModal';
import Grid from '../../../components/table/ag-grid';
import { deleteDatasource, getAllDatasources } from '../slices/datasource';
import { DatasourceContextProvider } from "../modules/datasource-context";
import DatasoureForm from "../modules/datasource-form";

function DatasourcePage() {
    const { t } = useTranslation();
    const [deleteModal, setDeleteModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [modal, setModal] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [selectedDatasource, setSelectedDatasource] = useState({ });

    // handle click behaviour (edit, delete)
    const dispatch = useDispatch();
    const { datasources } = useSelector((state) => state.datasource);
    
    useEffect(() => {
        dispatch(getAllDatasources());
    }, [dispatch]);

    const handleDeleteDatasource = () => {
        if (selectedDatasource?.id) {
            dispatch(deleteDatasource({ id: selectedDatasource.id }));
            setDeleteModal(false);
        }
    };

    const toggle = useCallback(() => {
        if (modal) { // close
            setSelectedDatasource({});
            setModal(false);
        } else { // open
            setModal(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modal]);

    const closeModal = () => {
        setSelectedDatasource({});
        setModal(false);
    };

    const handleDatasourceClick = useCallback(
        (arg) => {
            const isEditMode = arg ? true : false;

            setSelectedDatasource({}); // reset
            if (isEditMode) {
                if (arg.credentials) {
                    setSelectedDatasource({
                        id: arg?.id || '',
                        name: arg?.name || '',
                        type: arg?.type || '',
                        tableHeaders: arg?.tableHeaders || [],
                        tableRows: arg?.tableRows || [],
                        ...arg?.credentials,
                    });
                } else {
                    setSelectedDatasource({
                        id: arg?.id || '',
                        name: arg?.name || '',
                        type: arg?.type || '',
                        tableHeaders: arg?.tableHeaders || [],
                        tableRows: arg?.tableRows || [],
                        ...arg?.datasource_credential?.credentials
                    });
                }
            }
            setIsEdit(isEditMode);
            toggle();
        },
        [toggle]
    );

    const onClickDelete = (datasource) => {
        setSelectedDatasource(datasource);
        setDeleteModal(true);
    };

    const [columnDefs] = useState([
        {
            headerName: t('datasource.name'),
            field: 'name',
        },
        {
            headerName: t('action.action'),
            cellRenderer: (props) => {
                const { data } = props;

                return (
                    <div className="d-flex ag-action-cell">
                        <p onClick={() => handleDatasourceClick(data)} className="text-primary">
                            <i className="fas fa-edit" id="edittooltip" />
                            <UncontrolledTooltip placement="top" target="edittooltip">
                                {t('action.edit')}
                            </UncontrolledTooltip>
                        </p>
                        <p onClick={() => onClickDelete(data)} className="mx-2 text-danger">
                            <i className="fas fa-trash" id="deletetooltip" />
                            <UncontrolledTooltip placement="top" target="deletetooltip">
                                {t('action.delete')}
                            </UncontrolledTooltip>
                        </p>
                    </div>
                );
            },
        },
    ]);

    return (
        <React.Fragment>
            <DeleteModal
                showModal={deleteModal}
                handleOk={handleDeleteDatasource}
                handleCancel={() => setDeleteModal(false)}
            />

            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title={t('datasource.datasource')} breadcrumbItem={t('datasource.datasource')}>
                        <>
                            <Input
                                type="text"
                                className="form-control bg-light border-light rounded w-50"
                                placeholder={`${t('action.search')}...`}
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                            <Button color="primary" onClick={() => handleDatasourceClick()}>
                                {t('datasource.add-datasource')}
                            </Button>
                        </>
                    </Breadcrumbs>
                    <Card>
                        <Row>
                            <Col xs={12}>
                                <Grid columnDefs={columnDefs} rowData={datasources} quickFilterText={searchText} />
                            </Col>
                        </Row>
                    </Card>
                    <Modal isOpen={modal} toggle={toggle} autoFocus={false}>
                        <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? t('datasource.edit-datasource') : t('datasource.add-datasource')}
                        </ModalHeader>
                        <ModalBody style={{ height: 'calc(80vh)', overflow: 'auto' }}>
                            <DatasourceContextProvider>
                                <DatasoureForm closeModal={closeModal} initialvalues={selectedDatasource} />
                            </DatasourceContextProvider>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
        </React.Fragment>
    );
}

export default DatasourcePage;
