import PropTypes from 'prop-types';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import { Route, Routes } from 'react-router-dom';
import { userRoutes, authRoutes } from './routes/allRoutes';
import Authmiddleware from './routes/middleware/Authmiddleware';
import AuthLayout from './layout/auth-layout';
import NonAuthLayout from './layout/NonAuthLayout';
import 'antd/dist/reset.css';
import './assets/scss/theme.scss';
import "react-toastify/dist/ReactToastify.css";

const App = () => {
    return (
        <React.Fragment>
            <ToastContainer />
            <Routes>
                {authRoutes.map((route, idx) => (
                    <Route
                        path={route.path}
                        element={<NonAuthLayout>{route.component}</NonAuthLayout>}
                        key={idx}
                        isAuthProtected={false}
                    />
                ))}

                {userRoutes.map((route, idx) => (
                    <Route
                        path={route.path}
                        element={
                            <Authmiddleware>
                                <AuthLayout>{route.component}</AuthLayout>
                            </Authmiddleware>
                        }
                        key={idx}
                        isAuthProtected={true}
                        exact
                    />
                ))}
            </Routes>
        </React.Fragment>
    );
};

App.propTypes = {
    layout: PropTypes.any,
};

export default App;
