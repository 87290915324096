import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../store/slices/auth';

const Logout = () => {
    document.title = ' Logout';

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(logout({ navigate }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    return <></>;
};

export default Logout;
