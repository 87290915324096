
import EventNode from '../EventNode';
import { NODE_TYPES } from '../constant';

class EndNode extends EventNode {
    constructor() {
        super();
        this.displayName = 'End';
        this.description = '';
        this.name = 'end';
        this.color = '#ff0000';
        this.icon = '';
        this.type = 'shape';
        this.nodeShape = 'circle';
        this.hideSourceHandle = true;
        this.nodeType = NODE_TYPES.END;
    }

    getNodeAttributes() {
        const defaultAttributes = this.getDefaultAttributes();
        return [
            ...defaultAttributes,
        ];
    }

    getDefaultNodeData() {
        return {
            name: this.displayName,
            type: this.type,
            nodeType: this.nodeType,
        }
    }
}

export { EndNode as default };
