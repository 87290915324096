import { Button, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import i18n from '../../../../../../i18n';
import { isPrevNodeStartNode } from '../../utils/GenericFunctions';
import { SHOW_FORM_OPTIONS } from './constant';
import React, { useState } from 'react';
import AddViewModal from '../../../../views/addViewModal';

const DEFAULT_SHOW_FORM_OPTIONS = [
    {
        value: SHOW_FORM_OPTIONS.DASHBOARD_FORM,
        label: i18n.t('node-details.show-form-option-1'),
    },
    {
        value: SHOW_FORM_OPTIONS.TASK_LIST,
        label: i18n.t('node-details.show-form-option-3'),
    },
];

const SHOW_FORM_ADDITIONAL_OPTION = {
    value: SHOW_FORM_OPTIONS.POPUP_WINDOW,
    label: i18n.t('node-details.show-form-option-2'),
};

const getShowFormOptions = (id) => {
    const isStartNode = isPrevNodeStartNode(id);
    if (isStartNode) {
        const options = [...DEFAULT_SHOW_FORM_OPTIONS];
        options.splice(1, 0, SHOW_FORM_ADDITIONAL_OPTION);
        return options;
    }
    return [...DEFAULT_SHOW_FORM_OPTIONS];
};

export const ShowForm = ({ nodeId, nodeData, onChange }) => {
    const { t } = useTranslation();
    const { 'show-form': showForm } = nodeData;
    const { views } = useSelector((state) => state.processDetails);

    const [mode, setMode] = useState(showForm?.mode || undefined);
    const [view, setView] = useState(showForm?.view || undefined);
    const [addViewModal, setAddViewModal] = useState(false);

    const showFormOptions = getShowFormOptions(nodeId);
    const viewsOptions = views
        ?.map((v) => ({ label: v.name, value: v.id }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const triggerChange = (changedValue) => {
        onChange({ mode, view, ...changedValue });
    };

    const onSelectMode = (selectedMode) => {
        setMode(selectedMode);
        setView(undefined);
        triggerChange({ mode: selectedMode, view: undefined });
    };

    const onSelectView = (selectedView) => {
        setView(selectedView);
        triggerChange({ view: selectedView });
    };

    return (
        <>
            <Select
                allowClear
                options={showFormOptions}
                name="mode"
                onChange={onSelectMode}
                value={mode}
                placeholder={t('node-details.select-mode')}
            />
            {mode === SHOW_FORM_OPTIONS.TASK_LIST && (
                <div className="d-flex my-2">
                    <Select
                        allowClear
                        options={viewsOptions}
                        name="view"
                        onChange={onSelectView}
                        value={view}
                        placeholder={t('node-details.select-view')}
                        className="me-2"
                    />
                    <Button onClick={() => setAddViewModal(true)}>Add View</Button>
                    {addViewModal && <AddViewModal showModal={addViewModal} setShowModal={setAddViewModal} />}
                </div>
            )}
        </>
    );
};
