const layoutTypes = {
    HORIZONTAL: 'horizontal',
    VERTICAL: 'vertical',
};

const layoutModeTypes = {
    LIGHT: 'light',
    DARK: 'dark',
};

const layoutWidthTypes = {
    FLUID: 'lg',
    BOXED: 'boxed',
};

const topBarThemeTypes = {
    LIGHT: 'light',
    DARK: 'dark',
    COLORED: 'colored',
};

const sidebarSizeTypes = {
    DEFAULT: 'lg',
    COMPACT: 'small',
    ICON: 'sm',
};

const leftSideBarThemeTypes = {
    LIGHT: 'light',
    COLORED: 'colored',
    DARK: 'dark',
};

export { layoutTypes, layoutModeTypes, layoutWidthTypes, topBarThemeTypes, sidebarSizeTypes, leftSideBarThemeTypes };
