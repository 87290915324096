import React, { useState, useEffect, useImperativeHandle } from 'react';
import { Select } from 'antd';
import { showError } from '../../../helpers/common';

export const SelectField = React.forwardRef(
    ({ orFieldsInfo = {}, fieldInfo, form, initialValue, formInfo = {}, optionGroupKey = null }, formMakerRef) => {
        const [options, setOptions] = useState(fieldInfo?.options || []);
        const [value, setValue] = useState(initialValue || (fieldInfo?.mode ? [] : undefined));

        useImperativeHandle(formMakerRef, () => ({
            refreshOptions() {
                fetchOptions();
            },
        }));

        const fetchOptions = async () => {
            let params = [];
            if (fieldInfo?.dataFetchMethodParams?.length && Object.keys(formInfo).length) {
                fieldInfo?.dataFetchMethodParams.forEach((param) => {
                    if (formInfo[param]) {
                        params.push(formInfo[param]);
                    } else {
                        params.push('');
                    }
                });
            }
            try {
                const res = await fieldInfo?.dataFetchMethod(...params);
                let options = [];
                if (optionGroupKey) {
                    options = res?.data?.reduce((acc, obj) => {
                        const groupLabel = obj[optionGroupKey] || 'Others';
                        const optionGroup = acc.find(o => o.label === groupLabel);
                        const option = {
                            label: obj[fieldInfo?.optionLabel || 'name'],
                            value: obj[fieldInfo?.optionKey || 'id'],
                        };
                        if (!optionGroup) {
                            acc.push({
                                label: groupLabel,
                                options: [option]
                            });
                        } else {
                            optionGroup.options.push(option);
                        }
                        return acc;
                    }, []);
                    options.push(...options.splice(options.findIndex(v => v.label === 'Others'), 1));
                } else {
                    options = res?.data?.map((op) => {
                        return {
                            label: op[fieldInfo?.optionLabel || 'name'],
                            value: op[fieldInfo?.optionKey || 'id'],
                        };
                    })
                }
                setOptions(options);
            } catch (err) {
                showError(err?.message);
            }
        };
        useEffect(() => {
            if (fieldInfo.dataFetchMethod) {
                fetchOptions();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        useEffect(() => {
            form.setFieldValue(fieldInfo?.name, initialValue);
            setValue(initialValue);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [initialValue]);

        const onOptionChange = (val) => {
            setValue(val);
            if (fieldInfo?.actionKey) {
                form.setFieldValue(['actions', fieldInfo?.actionKey, fieldInfo?.name], val);
                if (fieldInfo?.saveLabel) {
                    const selectedOption = options?.find((opt) => opt?.value === val);
                    form.setFieldValue(
                        ['actions', fieldInfo?.actionKey, `${fieldInfo?.name}-label`],
                        selectedOption?.label
                    );
                }
                form.validateFields([['actions', fieldInfo?.actionKey, fieldInfo?.name]]);
            } else {
                form.setFieldValue(fieldInfo?.name, val);
                form.validateFields([fieldInfo?.name]);
            }
            // validate other OR fields
            if (Object.keys(orFieldsInfo).length) {
                form.validateFields(orFieldsInfo?.fields?.map((field) => field.name));
            }
        };

        return (
            <Select
                dropdownStyle={{ maxHeight: 400, overflow: 'auto', zIndex: '100000' }}
                value={value}
                onChange={onOptionChange}
                options={options}
                mode={fieldInfo?.mode ? fieldInfo?.mode : '-'}
                placeholder={fieldInfo?.placeholder}
                allowClear
            />
        );
    }
);
