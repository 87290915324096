import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, Row, Col, Radio, Space, Upload, Card, Modal, Collapse, Checkbox } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { readExcelHeaders } from '../../../helpers/excel-utils';
import { useStateWithRef } from '../../../helpers/useStateWithRef';
import clone from 'rfdc/default';
import EditableTable from './EditableTable';
import DataSourceSelectionModal from './DataSourceSelectionModal';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

const HEADER_OPTIONS = {
    firstRowAsHeader: 'xls-form.use-firstrow-as-header',
    manualAttributes: 'xls-form.manual-attributes',
};

const ExcelField = ({ formInfo, fieldInfo, form, initialValue, ...rest }) => {
    const { t } = useTranslation();
    const [headerOption, setHeaderOption] = useState('');
    const [uploadDisabled, setUploadDisabled] = useState(false);
    const [excelTemplateFile, setExcelTemplateFile] = useState([]);
    const [attributesFromExcel, setAttributesFromExcel] = useState([]);
    const [selectedSheet, setSelectedSheet, selectedSheetRef] = useStateWithRef(null);
    const [showAttributesModal, setShowAttributesModal] = useState(false);
    const [selectedAttributes, setSelectedAttributes] = useState(initialValue || []);
    const [showSelectSourceModal, setShowSelectSourceModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState({});

    const { attributes, attributeGroups, datasources } = useSelector((state) => state.processDetails);

    useEffect(() => {
        setSelectedAttributes(initialValue || []);
    }, [initialValue]);

    const setExcelFieldError = (sAttributes) => {
        form.setFields([
            {
                name: 'excelUpload',
                errors: sAttributes?.length ? '' : ['Attributes selection required!'],
            },
        ]);
    };

    useEffect(() => {
        form.setFieldValue(fieldInfo.name, selectedAttributes);
        return () => {
            form.setFieldValue(fieldInfo.name, selectedAttributes);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAttributes]);

    const uploadProps = {
        accept: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
        maxCount: 1,
        beforeUpload: () => false,
        onChange: ({ fileList }) => {
            setExcelTemplateFile(fileList);
        },
        fileList: excelTemplateFile,
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target.result;
            const excelHeaders = readExcelHeaders(data);
            setAttributesFromExcel(excelHeaders);
            if (Object.keys(excelHeaders)?.length === 1) {
                const defaultSheetName = Object.keys(excelHeaders)[0];
                // selectedSheetRef.current.value = defaultSheetName;
                setSelectedSheet(defaultSheetName);
            }

            setShowAttributesModal(true);
            setExcelTemplateFile([]);
        };
        reader?.readAsArrayBuffer(excelTemplateFile?.[0]?.originFileObj);
    };

    const resetAttributesAndSelectSheet = (value) => {
        if (selectedSheet) {
            const clonedData = clone(attributesFromExcel);
            clonedData[selectedSheet].forEach((attr) => (attr.isChecked = false));
            setAttributesFromExcel(clonedData);
        }
        setSelectedSheet(value);
    };

    const selectAttribute = (sheetName, index) => {
        const clonedData = clone(attributesFromExcel);
        const elem = clonedData[sheetName][index];
        elem.isChecked = !elem.isChecked;
        setAttributesFromExcel(clonedData);
    };

    const onSelectAllAttribute = (sheetName, e) => {
        const clonedData = clone(attributesFromExcel);
        const elems = clonedData[sheetName];
        elems.forEach((ele) => {
            ele.isChecked = e.target.checked;
        });
        setAttributesFromExcel(clonedData);
    };

    const showAttributesItems = () => {
        const isAttributeDisabled = (att) => {
            return fieldInfo?.defaultAttributes.find((attri) => attri.name === att.name);
        };

        return [
            {
                key: 1,
                label: (
                    <>
                        Attributes (Sheet: <b>{selectedSheetRef.current}</b>)
                        <Checkbox
                            checked={attributesFromExcel[selectedSheetRef.current].every((att) => att.isChecked)}
                            onChange={(e) => onSelectAllAttribute(selectedSheetRef.current, e)}
                        >
                            Select All
                        </Checkbox>
                    </>
                ),
                children: (
                    <div className="d-flex flex-column">
                        {attributesFromExcel[selectedSheetRef.current].map((attr, index) => (
                            <Checkbox
                                checked={attr.isChecked}
                                disabled={isAttributeDisabled(attr)}
                                onChange={() => selectAttribute(selectedSheetRef.current, index)}
                                key={attr.id}
                            >
                                {attr.name}
                            </Checkbox>
                        ))}
                    </div>
                ),
            },
        ];
    };

    const getAttributes = () => {
        const sheetsLength = Object.keys(attributesFromExcel).length;
        if (sheetsLength > 1) {
            return (
                <>
                    <span>
                        <strong>Multiple sheets detected. Please select one:</strong>&nbsp;
                    </span>
                    <Radio.Group
                        name="sheet-select"
                        className="mb-2"
                        onChange={(event) => resetAttributesAndSelectSheet(event.target.value)}
                        value={selectedSheet}
                    >
                        <Space direction="horizontal">
                            {Object.keys(attributesFromExcel).map((sheetName, index) => (
                                <Radio value={sheetName} key={index}>
                                    {sheetName}
                                </Radio>
                            ))}
                        </Space>
                    </Radio.Group>
                    {selectedSheet && <Collapse items={showAttributesItems()} defaultActiveKey={['1']} />}
                </>
            );
        }
        const defaultSheetName = Object.keys(attributesFromExcel)[0];
        return <>{defaultSheetName && <Collapse items={showAttributesItems()} defaultActiveKey={['1']} />}</>;
    };

    const checkOkState = () => {
        if (selectedSheetRef.current) {
            const sheetData = attributesFromExcel[selectedSheetRef.current];
            return sheetData.filter((d) => d.isChecked)?.length ? false : true;
        }
        return true;
    };

    const filterAttributes = () => {
        const selectedAttributesArr = [];
        const lSelectedAttributes = [...selectedAttributes];
        const sheetData = attributesFromExcel[selectedSheet];
        sheetData
            .filter((d) => d.isChecked)
            .forEach((a) => {
                const isExist = [].find((pAtt) => pAtt.name === a.name);
                if (!isExist) {
                    selectedAttributesArr.push({
                        id: a.id,
                        key: a.id,
                        comments: `XLS Header: ${a.name}`,
                        name: a.name,
                        type: 'text',
                    });
                } else {
                    let existingIndex = lSelectedAttributes.findIndex((sAtt) => sAtt.name === a.name);
                    lSelectedAttributes[existingIndex].disabled = true;
                }
            });

        setSelectedAttributes([...lSelectedAttributes, ...selectedAttributesArr]);
        setExcelFieldError([...lSelectedAttributes, ...selectedAttributesArr]);
        setShowAttributesModal(false);
        setSelectedSheet(null);
        setUploadDisabled(true);
    };

    const onFetchAttributes = () => {
        const lAttributes = [...selectedAttributes];
        const lProcessAttributes = [...attributes];
        const newAttributes = [];
        lProcessAttributes.forEach((pAttribute) => {
            const isExist = lAttributes.findIndex((lAtt) => lAtt?.name === pAttribute.name);
            if (isExist < 0) {
                newAttributes.push({
                    id: pAttribute.id,
                    key: pAttribute.id,
                    comments: pAttribute?.comments,
                    name: pAttribute.name,
                    type: 'text',
                    disabled: true,
                });
            } else {
                lAttributes[isExist].disabled = true;
            }
        });
        setSelectedAttributes([...lAttributes, ...newAttributes]);
        setExcelFieldError([...lAttributes, ...newAttributes]);
    };

    const onSaveDataSource = (dataSourceInfo) => {
        const selectedAttributeIndex = selectedAttributes.findIndex(
            (sAttribute) => sAttribute?.id === selectedRecord?.id
        );
        const tempSelectedAttributes = [...selectedAttributes];
        tempSelectedAttributes[selectedAttributeIndex].selectDataSource = dataSourceInfo;
        if (
            Object.keys(dataSourceInfo).length &&
            tempSelectedAttributes[selectedAttributeIndex]?.error?.dataSourceInfo
        ) {
            delete tempSelectedAttributes[selectedAttributeIndex].error.dataSourceInfo;
        }
        setSelectedAttributes(tempSelectedAttributes);
        form.setFieldValue('excelUpload', tempSelectedAttributes);
        setShowSelectSourceModal(false);
    };

    const onCancelDataSource = () => {
        setSelectedRecord({});
        setShowSelectSourceModal(false);
    };

    const validateDuplicateNames = (attrs) => {
        return attrs.map((att) => {
            let error = {};
            if (att.name) {
                const isDuplicateExist = attrs.findIndex((a) => a.name === att.name && a.id !== att.id);
                if (isDuplicateExist > -1) error.name = 'Attribute Already Exist';
            }
            return {
                ...att,
                error,
            };
        });
    };

    const onChangeValue = (id, key, value) => {
        let clonedAttributes = [...selectedAttributes];
        const cIndex = clonedAttributes.findIndex((attribute) => attribute.id === id);
        clonedAttributes[cIndex][key] = value;
        if (key === 'type' && value !== 'select') {
            clonedAttributes[cIndex]['selectDataSource'] = {};
        }
        if (key === 'name') {
            clonedAttributes = validateDuplicateNames([...clonedAttributes]);
        }

        setSelectedAttributes([...clonedAttributes]);
    };

    const onAttributeTableAction = (actionType, record) => {
        if (actionType === 'delete') {
            const tempSelectedAttributes = [...selectedAttributes]?.filter((row) => row.id !== record.id);
            setSelectedAttributes(tempSelectedAttributes);
            setExcelFieldError(tempSelectedAttributes);
        }
        if (actionType === 'chooseDataSource') {
            setShowSelectSourceModal(true);
            setSelectedRecord(record);
        }
    };

    return (
        <>
            <Card title={t('xls-form.upload-existing-excel')}>
                <Row>
                    <Col className="col-12">
                        <Radio.Group
                            name="excelHeaderAttribute"
                            className="mb-2"
                            onChange={(event) => setHeaderOption(event.target.value)}
                            value={headerOption}
                            disabled={uploadDisabled}
                        >
                            <Space direction="vertical">
                                {Object.keys(HEADER_OPTIONS).map((a) => (
                                    <Radio value={a} key={a}>
                                        {t(`${HEADER_OPTIONS[a]}`)}
                                    </Radio>
                                ))}
                            </Space>
                        </Radio.Group>
                    </Col>
                </Row>
                {headerOption && (
                    <Row>
                        <Col className="col-12">
                            <Upload {...uploadProps}>
                                <Button icon={<UploadOutlined />} disabled={uploadDisabled}>
                                    {t('xls-form.choose-file')}
                                </Button>
                            </Upload>
                            <Button className="mt-2" onClick={handleSubmit} disabled={uploadDisabled}>
                                {t('xls-form.fetch-headers-from-excel')}
                            </Button>
                        </Col>
                    </Row>
                )}
            </Card>
            <Row>
                <Col className="col-12 mb-2 mt-4 d-flex align-items-center justify-content-between">
                    Attributes
                    <div className="d-flex">
                        {fieldInfo?.hideFetchAttributes ? (
                            ''
                        ) : (
                            <Button onClick={() => onFetchAttributes()}>Fetch attributes</Button>
                        )}
                        {fieldInfo?.showAddIcon ? (
                            <Button
                                style={{ paddingInline: 10, display: 'flex', marginLeft: 10 }}
                                onClick={() => {
                                    const tempAttributes = [
                                        ...selectedAttributes,
                                        {
                                            name: '',
                                            newRecord: true,
                                            id: new Date().getTime().toString(36) + Math.random().toString(36).slice(2),
                                        },
                                    ];
                                    setSelectedAttributes([...tempAttributes]);
                                    form.setFieldValue('excelUpload', tempAttributes);
                                    setExcelFieldError(tempAttributes);
                                }}
                            >
                                <AddOutlinedIcon />
                                <div style={{ marginLeft: 2, fontSize: 15 }}>Add Attribute</div>
                            </Button>
                        ) : (
                            ''
                        )}
                    </div>
                </Col>
                <EditableTable
                    form={form}
                    selectedAttributes={selectedAttributes}
                    processAttributeGroups={attributeGroups}
                    dataSourceList={datasources}
                    onChange={onChangeValue}
                    headerNameEditable={headerOption === 'manualAttributes'}
                    onAction={onAttributeTableAction}
                />
            </Row>

            {showAttributesModal && (
                <Modal
                    destroyOnClose={true}
                    title={t('xls-form.select-attributes')}
                    centered
                    open={showAttributesModal}
                    onOk={() => filterAttributes()}
                    okButtonProps={{ disabled: checkOkState() }}
                    onCancel={() => setShowAttributesModal(false)}
                    width={1000}
                    bodyStyle={{ minHeight: '60vh', maxHeight: '60vh', overflowX: 'scroll' }}
                >
                    {getAttributes()}
                </Modal>
            )}

            {showSelectSourceModal && (
                <DataSourceSelectionModal
                    datasources={datasources}
                    show={showSelectSourceModal}
                    dataSourceInfo={selectedRecord?.selectDataSource || {}}
                    onSave={onSaveDataSource}
                    onCancel={onCancelDataSource}
                />
            )}
        </>
    );
};

export default ExcelField;
