import React, { useEffect, useState } from 'react';
import { Input, Form, Button, Select, Table, Tabs } from 'antd';
import { useDatasourceContext } from '../modules/datasource-context';
import { connectionHandler } from '../../utils/services';

const { TextArea } = Input;
const { TabPane } = Tabs;

const Step3 = () => {
    const { state, dispatch } = useDatasourceContext();
    const [selectedTab, setSelectedTab] = useState('table');
    const [tables, setTables] = useState([]);
    const [selectedTable, setSelectedTable] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [tableColumns, setTableColumns] = useState([]);
    const [queryResult, setQueryResult] = useState(null);
    const [totalEntries, setTotalEntries] = useState(0);
    const [queryInfo, setQueryInfo] = useState('');
    const [form] = Form.useForm();

    useEffect(() => {
        const fetchTables = async () => {
            try {
                const credentials = state.formData.step2;
                credentials.type = state.formData.step1.type;
                const tableList = await connectionHandler.getTables(credentials);
                setTables(tableList.tables);
            } catch (error) {
                console.error(error);
            }
        };

        if (state.formData.step1.type !== "restapi") fetchTables();
    }, [state.formData.step1.type, state.formData.step2]);

    const handleSelectTable = async (tableName) => {
        setSelectedTable(tableName);
        try {
            const credentials = state.formData.step2;
            credentials.type = state.formData.step1.type;
            credentials.table = tableName;
            const tableList = await connectionHandler.getTableContent(credentials);
            const tableData = tableList.result.flat();
            setTableData(tableData);

            const columns = Object.keys(tableData[0] || {}).map((key) => ({
                title: key,
                dataIndex: key,
                key: key,
            }));
            setTableColumns(columns);
            setTotalEntries(tableList.count);
        } catch (error) {
            console.error(error);
        }
    };

    const handleQuery = async () => {
        const formValues = await form.validateFields();
        const query = formValues.query;
        try {
            if (query) {
                const credentials = state.formData.step2;
                credentials.type = state.formData.step1.type;
                credentials.query = query;
                const res = await connectionHandler.getQueryResult(credentials);
                const queryResult = res.result;
                setQueryInfo(res.info);
                setQueryResult(queryResult);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleTabChange = (key) => {
        setSelectedTab(key);
    };

    const onFinish = (values) => {
        dispatch({ type: 'UPDATE_DATA', step: 'step3', data: values });
        dispatch({ type: 'NEXT_STEP' });
    };

    const handlePrevious = () => {
        dispatch({ type: 'PREVIOUS_STEP' });
    };

    const tableInfo = selectedTable
        ? `${tableData.length} von ${totalEntries} Einträgen aus Tabelle ${selectedTable}`
        : '';

    return (
        <Form form={form} name="step3" initialValues={state.formData.step3} onFinish={onFinish}>
            {state.formData.step1.type !== 'restapi' && (
                <Tabs activeKey={selectedTab} onChange={handleTabChange}>
                    <TabPane tab="Datenbank-Tabelle auswählen" key="table">
                        <Form.Item label="Datenbank-Tabellen" name="selectedTable" style={{ marginBottom: 0 }}>
                            <Select onChange={handleSelectTable}>
                                {tables
                                    ? tables.map((tableName) => (
                                          <Select.Option key={tableName} value={tableName}>
                                              {tableName}
                                          </Select.Option>
                                      ))
                                    : null}
                            </Select>
                        </Form.Item>

                        {selectedTable && (
                            <div style={{ overflowX: 'auto' }}>
                                {tableInfo && <p>{tableInfo}</p>}
                                <Table dataSource={tableData} columns={tableColumns} pagination={false} />
                            </div>
                        )}
                    </TabPane>

                    <TabPane tab="Query ausführen" key="query">
                        <Form.Item label="Query" name="query" rules={[{ required: false }]}>
                            <TextArea rows={4} />
                        </Form.Item>

                        {<p>{queryInfo}</p>}
                        {queryResult && (
                            <div style={{ overflowX: 'auto' }}>
                                <Table
                                    dataSource={queryResult}
                                    columns={Object.keys(queryResult[0] || {}).map((key) => ({
                                        title: key,
                                        dataIndex: key,
                                        key: key,
                                    }))}
                                    pagination={false}
                                />
                            </div>
                        )}
                    </TabPane>
                </Tabs>
            )}

            <br />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button onClick={handlePrevious}>Previous</Button>
                {selectedTab === 'query' && <Button onClick={handleQuery}>Query ausführen</Button>}
            </div>
        </Form>
    );
};

export default Step3;
