import ShapeNode from './shape-node';
import Activity from './activity';
import Lane from './lane';
import Pool from './pool';

export const nodeTypes = {
    lane: Lane,
    pool: Pool,
    shape: ShapeNode,
    activity: Activity
};
