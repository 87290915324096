import { Radio, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { CANCEL_BEHAVIOUR_OPTIONS } from './constant';

export const CancelBehaviour = ({ nodeData, onChange }) => {
    const { t } = useTranslation();
    return (
        <Radio.Group onChange={onChange} value={nodeData['cancel-behaviour']}>
            <Space direction="vertical">
                {CANCEL_BEHAVIOUR_OPTIONS.map((option) => <Radio value={option.key}>{t(option.value)}</Radio>)}
            </Space>
        </Radio.Group>
    );
};
