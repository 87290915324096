/* eslint-disable import/no-anonymous-default-export */
import request from './request';

const getAllForms = (processId) => request.get(`forms/${processId}`);
const getForm = (id) => request.get(`forms/${id}`);
const addForm = (payload) => request.post('forms', payload);
const updateForm = (id, payload) => request.put(`forms/${id}`, payload);
const deleteForm = (id) => request.delete(`forms/${id}`);
const getFormData = (id) => request.get(`forms/${id}/formData`);

export default {
    getAllForms,
    getForm,
    addForm,
    updateForm,
    deleteForm,
    getFormData
};
