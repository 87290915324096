import ActivityNode from "../../ActivityNode";
import { DashboardVisibility } from "../../components/DashboardVisiblity";
import { conditionalRenderingLoaders, formEditingResourceLoaders } from "../../utils/GenericFunctions";

class SimpleTaskNode extends ActivityNode {
    constructor() {
        super();
        this.displayName = 'Simple Task';
        this.description = '';
        this.name = 'simple-task';
        this.icon = 'fas fa-tasks';
        this.type = 'activity';
        this.nodeType = 'simple-task';
        this.methods = {
            loadOptions: {...formEditingResourceLoaders, ...this.methods.loadOptions},
            renderingOption: conditionalRenderingLoaders
        };
    }

    getNodeAttributes() {
        const defaultAttributes = this.getDefaultAttributes();
        return [
            ...defaultAttributes,
            {
                displayName: 'node-details.action',
                name: 'action',
                description: 'node-details.action',
                type: 'text',
                default: '',
                required: true,
            },
            {
                displayName: 'node-details.dashboard-settings',
                name: 'dashboard-settings',
                description: 'node-details.dashboard-settings',
                type: 'custom-component',
                component: <DashboardVisibility />
            },
        ];
    }

    getDefaultNodeData() {
        return {
            name: this.displayName,
            type: this.type,
            action: 'Done',
            nodeType: this.nodeType,
            'dashboard-settings': {
                showOnDashboard: true
            },
        }
    }
}

export { SimpleTaskNode as default };
