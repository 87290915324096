import BaseNode from "../BaseNode";

class MergeNode extends BaseNode {
    constructor() {
        super();
        this.displayName = 'Merge';
        this.description = '';
        this.name = 'merge';
        this.color = '#ADAD30';
        this.type = 'shape';
        this.nodeShape = 'diamond';
    }

    getDefaultNodeData() {
        return {
            name: this.displayName,
            type: this.type,
        }
    }
};

export { MergeNode as default };