import { formatValueToLocale, getFormattedDate } from '../../helpers/common';

const getValue = (attribute, value) => {
    let returnValue = value;
    const { type } = attribute;
    switch (type) {
        case 'number':
            returnValue = formatValueToLocale(value);
            break;
        case 'date':
            returnValue = getFormattedDate(value);
            break;
        case 'datetime':
            returnValue = getFormattedDate(value, true);
            break;
        default:
            break;
    }
    return returnValue;
};

export const getTaskTableAttributes = (attributes = [], viewAttributes = []) => {
    const dynamicColumn = [];
    let filteredAttributes = attributes;
    if (viewAttributes?.length) {
        const viewIds = viewAttributes?.filter(a => a.isVisibleDesktop).map(a => a.attributeId);
        filteredAttributes = attributes?.filter(a => viewIds.includes(a.id));
    }

    const attributeGroup = filteredAttributes.reduce((acc, obj) => {
        const { attributeGroupName } = obj;
        if (attributeGroupName) {
            if (!acc[attributeGroupName]) {
                acc[attributeGroupName] = [];
            }
            acc[attributeGroupName].push(obj);
        } else {
            if (!acc['Others']) {
                acc['Others'] = [];
            }
            acc['Others'].push(obj);
        }
        return acc;
    }, {});

    const others = attributeGroup['Others'];
    delete attributeGroup['Others'];

    let sortedAG = Object.keys(attributeGroup)
        .sort()
        .reduce((obj, key) => {
            obj[key] = attributeGroup[key];
            return obj;
        }, {});
    sortedAG = { ...sortedAG, Others: others };

    for (const [key, value] of Object.entries(sortedAG)) {
        const children =
            value?.map((child, index) => {
                const viewAttributeDetail = viewAttributes.find(a => a.attributeId === child.id);
                return {
                    headerName: child?.name,
                    columnGroupShow: viewAttributeDetail?.isStickyInGroup ? "open" : "",
                    cellRenderer: (props) => {
                        const { data } = props;
                        const value = data.taskInfo[child.id]?.value;
                        const formattedValue = getValue(child, value);
                        return <>{formattedValue}</>;
                    },
                };
            }) || [];
        dynamicColumn.push({
            headerName: key,
            children: children,
        });
    }
    return dynamicColumn;
};

export const getDefaultAttributes = (nodeInfo) => {
    const att = {};
    const defaultAttributes = nodeInfo?.attributes || [];
    defaultAttributes?.forEach((dAtt) => {
        att[dAtt?.attribute] = dAtt?.value;
    });
    return att;
};
