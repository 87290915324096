import { useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ReactFormGenerator } from 'flow-form-builder';
import ColorButton from '../../components/color-button/ColorButton';

const FormRenderer = ({
    onSubmit = () => {},
    formData = {},
    formValues = {},
}) => {
    const submitTypeRef = useRef(null);
    let fieldData = [];
    let formActions = [];
    if (formData && Object.keys(formData)?.length) {
        fieldData = JSON.parse(formData?.fieldData);
        formActions = JSON.parse(formData?.actions);
    }

    const getAnswers = () => {
        const defaultFormValues = fieldData?.map((field) => {
            const fName = field?.field_name?.split('_')[0];
            return {
                name: field?.field_name,
                value: formValues?.taskInfo?.[fName]?.value,
            };
        }) || [];
        return defaultFormValues;
    };

    const onFormSubmit = (data) => {
        onSubmit(submitTypeRef.current, data);
    };

    return (
        <ReactFormGenerator
            key={uuidv4()}
            onSubmit={onFormSubmit}
            data={fieldData || []}
            answer_data={getAnswers()}
            submitButton={
                formActions?.map((action) => (
                    <ColorButton
                        key={action.id}
                        style={{ marginRight: '10px' }}
                        type="primary"
                        htmlType="submit"
                        override={action.color}
                        onClick={() => submitTypeRef.current = action}
                    >
                        {action.label}
                    </ColorButton>
                ))
            }
        />
    );
};

export default FormRenderer;
