/* eslint-disable import/no-anonymous-default-export */
import request from './request';

const getProcessFlow = (id) => request.get(`process-flows/${id}/`);
const addProcessFlow = (payload) => request.post('process-flows', payload);
const updateProcessFlow = (id, payload) => request.put(`process-flows/${id}`, payload);

export default {
    getProcessFlow,
    addProcessFlow,
    updateProcessFlow,
};
