import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createProcess, deleteProcess, getAllProcesses, updateProcess } from '../../store/slices/process';
import Breadcrumbs from '../../components/breadcrumb/Breadcrumb';
import { Button, Card, Col, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import Grid from '../../components/table/ag-grid';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { setProcess } from '../../store/slices/process-flow';
import { DeleteModal } from '../../components/delete-modal/DeleteModal';

const getVersionInfo = (data = [], area = 'STAGING') => {
    const versions = data?.process_versions;
    if (!versions?.length) {
        return '';
    }
    const processData = versions.find((version) => version.area === area);
    return processData?.version ?? '';
}

function ProcessPage() {
    const { t } = useTranslation();
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedProcess, setSelectedProcess] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [modal, setModal] = useState(false);
    const [searchText, setSearchText] = useState('');
    const navigate = useNavigate();

    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            name: selectedProcess?.name || '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required(t('error-message.role-name-required')),
        }),
        onSubmit: (values) => {
            const payload = {
                name: values.name,
            };
            if (isEdit && selectedProcess?.id) {
                // update process
                dispatch(updateProcess({ id: selectedProcess?.id, payload }));
                validation.resetForm();
            } else {
                // save new process
                dispatch(createProcess({ payload }));
                validation.resetForm();
            }
            toggle();
        },
    });

    const dispatch = useDispatch();
    const processes = useSelector((state) => state.process.processes);

    useEffect(() => {
        dispatch(getAllProcesses());
    }, [dispatch]);

    const handleDeleteProcess = () => {
        if (selectedProcess?.id) {
            dispatch(deleteProcess({ id: selectedProcess.id }));
            setDeleteModal(false);
        }
    };

    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);
            setSelectedProcess(null);
        } else {
            setModal(true);
        }
        validation.resetForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modal]);

    const handleProcessClick = useCallback(
        (arg) => {
            const isEditMode = arg ? true : false;
            setSelectedProcess(arg);
            setIsEdit(isEditMode);
            toggle();
        },
        [toggle]
    );

    const onClickDelete = (process) => {
        setSelectedProcess(process);
        setDeleteModal(true);
    };

    const configureProcess = (process) => {
        dispatch(setProcess(process));
        navigate(`/process/details`, { state: { processId: process.id } });
    };

    const [columnDefs] = useState([
        {
            headerName: t('process.name'),
            field: 'name',
        },
        {
            headerName: t('process.designer-version'),
            field: 'version',
        },
        {
            headerName: t('process.staging-version'),
            field: 'name',
            cellRenderer: (props) => {
                const { data } = props;
                return getVersionInfo(data);
            }
        },
        {
            headerName: t('process.live-version'),
            field: 'name',
            cellRenderer: (props) => {
                const { data } = props;
                return getVersionInfo(data, 'LIVE');
            }
        },
        {
            headerName: t('action.action'),
            cellRenderer: (props) => {
                const { data } = props;
                return (
                    <div className="d-flex ag-action-cell">
                        <Button color="success" onClick={() => configureProcess(data)}>
                            {t('action.details')}
                        </Button>
                        <Button color="primary" onClick={() => handleProcessClick(data)} className="mx-2">
                            {t('action.edit')}
                        </Button>
                        <Button
                            color="danger"
                            onClick={() => onClickDelete(data)}
                        >
                            {t('action.delete')}
                        </Button>
                    </div>
                );
            },
        },
    ]);

    return (
        <React.Fragment>
            <DeleteModal
                showModal={deleteModal}
                handleOk={handleDeleteProcess}
                handleCancel={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title={t('process.process')} breadcrumbItem={t('process.process')}>
                        <>
                            <Input
                                type="text"
                                className="form-control bg-light border-light rounded w-50"
                                placeholder={`${t('action.search')}...`}
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                            <Button color="primary" onClick={() => handleProcessClick()}>
                                {t('process.add-process')}
                            </Button>
                        </>
                    </Breadcrumbs>
                    <Card>
                        <Row>
                            <Col xs={12}>
                                <Grid columnDefs={columnDefs} rowData={processes} quickFilterText={searchText} />
                            </Col>
                        </Row>
                    </Card>
                    <Modal isOpen={modal} toggle={toggle} autoFocus={false}>
                        <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? t('process.edit-process') : t('process.add-process')}
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <Row>
                                    <Col className="col-12">
                                        <div className="mb-3">
                                            <Label className="form-label">{t('process.name')}</Label>
                                            <Input
                                                autoFocus
                                                name="name"
                                                type="text"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.name || ''}
                                                invalid={
                                                    validation.touched.name && validation.errors.name ? true : false
                                                }
                                            />
                                            {validation.touched.name && validation.errors.name ? (
                                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <button type="submit" className="btn btn-success save-user">
                                                {t('action.save')}
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ProcessPage;
