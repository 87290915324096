import { useEffect, useState } from 'react';
import { NodeResizer, useStore, Handle, Position, useKeyPress, useReactFlow } from 'reactflow';

import Shape from './shape';
import ActionsToolbar from '../toolbar/actions';
import ProcessStepToolbar from '../toolbar/nodes';
import { useNodeClick } from '../../hooks/useNodeClick';
import { nodeClassMapping } from '../../plugins';
import NodeLabel from '../../node-edge-label/label';
import { useSelector } from 'react-redux';

// this will return the current dimensions of the node (measured internally by react flow)
function useNodeDimensions(id) {
    const node = useStore((state) => state.nodeInternals.get(id));
    return {
        width: node?.width || 0,
        height: node?.height || 0,
    };
}

function ShapeNode({ id, selected, data }) {
    const { nodeType } = data;
    const { deleteElements } = useReactFlow();
    const [isVisible, setIsVisible] = useState(false);
    const { isInteractionDisabled } = useSelector((state) => state.processDetails);

    const { width, height } = useNodeDimensions(id);
    const shiftKeyPressed = useKeyPress('Shift');

    const onNodeAction = (action) => {
        if (action === 'delete') {
            deleteElements({ nodes: [{ id }] });
        }
    };

    const hideContextMenu = () => {
        setIsVisible(false);
    };

    useEffect(() => {
        if (!selected && isVisible) {
            hideContextMenu();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    if (!nodeClassMapping[nodeType]) {
        return;
    }
    const classMapping = new nodeClassMapping[nodeType]();
    const { color, icon, displayName, hideSourceHandle, hideTargetHandle, nodeShape } = classMapping;
    const handleStyle = { backgroundColor: color };

    return (
        <>
            <ActionsToolbar nodeId={id} onNodeAction={onNodeAction} />
            <ProcessStepToolbar
                nodeId={id}
                isVisible={!isInteractionDisabled && isVisible}
                hideToolbar={() => setIsVisible(!isVisible)}
                onClickHandler={useNodeClick}
            />
            <NodeResizer
                color={color}
                keepAspectRatio={shiftKeyPressed}
                isVisible={selected}
                shouldResize={() => false}
            />
            <div style={{ position: 'relative' }}>
                <Shape
                    type={nodeShape}
                    width={width}
                    height={height}
                    fill="transparent"
                    strokeWidth={2}
                    stroke={color}
                    fillOpacity={0.8}
                />
                <div className="node-icon">
                    <i className={`${icon} fa-2x`} />
                </div>
            </div>

            {!hideTargetHandle && (
                <Handle
                    style={handleStyle}
                    id="left"
                    type="target"
                    position={Position.Left}
                    isConnectable={!isInteractionDisabled}
                />
            )}
            {!hideSourceHandle && <Handle
                style={handleStyle}
                id="right"
                type="source"
                position={Position.Right}
                onClick={() => setIsVisible(!isVisible)}
                onConnect={() => setIsVisible(false)}
                isConnectable={!isInteractionDisabled}
            />}
            <NodeLabel placeholder={displayName} name={data.name} id={id} />
        </>
    );
}

export default ShapeNode;
