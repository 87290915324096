import { Position } from 'reactflow';

const CONDITION_REGEX = /^([<>]=?|=|not null|null)/;

export const extractOperatorAndValue = (data = null) => {
    const returnData = {
        operator: data?.operator || '',
        value: data?.value || '',
    };
    if (data?.value && !['contains', 'not-contains'].includes(data?.operator)) {
        const value = data.value;
        const match = value.match(CONDITION_REGEX);
        if (match) {
            const operator = match[1];
            const val = value.replace(operator, '');
            returnData.operator = operator;
            returnData.value = val;
        }
    }
    return returnData;
};

export function getId() {
    return `${Date.now()}`;
};

export const getSourceHandlePosition = (direction) => {
    switch (direction) {
        case 'TB':
            return Position.Bottom;
        case 'BT':
            return Position.Top;
        case 'LR':
            return Position.Right;
        case 'RL':
            return Position.Left;
        default: break;
    }
}

export const getTargetHandlePosition = (direction) => {
    switch (direction) {
        case 'TB':
            return Position.Top;
        case 'BT':
            return Position.Bottom;
        case 'LR':
            return Position.Left;
        case 'RL':
            return Position.Right;
        default: break;
    }
}

export const fixPoolPosition = (nodes) => {
    const pools = nodes.filter((n) => n.type === 'pool');
    for (let counter = 1; counter < pools.length; counter++) {
        const lastNode = pools[counter - 1];
        const node = pools[counter];
        let position = { ...lastNode.position, y: lastNode.style.height + lastNode.position.y + 20 };
        node.position = position;
        node.positionAbsolute = position;
    }
    return nodes;
};

export const isNumInRange = (num, target, range) => {
    return num < target + range && num > target - range;
};

export const removeKeys = (obj, keys) => {
    if (Array.isArray(obj)) return obj.map((item) => removeKeys(item, keys));

    if (typeof obj === 'object' && obj !== null) {
        return Object.keys(obj).reduce((previousValue, key) => (keys.includes(key)
            ? previousValue
            : { ...previousValue, [key]: removeKeys(obj[key], keys) }), {});
    }

    return obj;
};