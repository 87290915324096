/* eslint-disable import/no-anonymous-default-export */
import request from './request';

const getAllUsers = () => request.get('users');
const getUser = (id) => request.get(`users/${id}`);
const addUser = (payload) => request.post('users', payload);
const updateUser = (id, payload) => request.put(`users/${id}`, payload);
const deleteUser = (id) => request.delete(`users/${id}`);

export default {
    getAllUsers,
    getUser,
    addUser,
    updateUser,
    deleteUser
};
