import React from 'react';
import { useSelector } from 'react-redux';
import './styles.css';
import { NodeDetails } from './node-details';
import { EdgeDetails } from './edge-details';
import { ProcessDetails } from './process-details';

const Sidebar = () => {
    const {
        process: processDetail,
        selectedNode,
        selectedEdge
    } = useSelector((state) => state.processDetails);

    return (
        <aside>
            {selectedNode && <NodeDetails />}
            {selectedEdge && <EdgeDetails />}
            {!selectedNode && !selectedEdge && <ProcessDetails />}
        </aside>
    );
};

export default Sidebar;
