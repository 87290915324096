import React, { useState } from 'react';
import { Modal, Space, Button } from 'antd';
import ViewTable from './viewTable';
import { useTranslation } from 'react-i18next';
import AddViewModal from './addViewModal';

const ViewDetailsModal = ({ showModal, handleCancel }) => {
    const { t } = useTranslation();
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [addViewModal, setAddViewModal] = useState(false);

    return (
        <Modal
            title={t('action.views')}
            open={showModal}
            onCancel={handleCancel}
            destroyOnClose
            footer={
                <Space>
                    <Button type="default" onClick={handleCancel}>
                        {t('action.cancel')}
                    </Button>
                    <Button type="primary" onClick={() => setAddViewModal(true)}>
                        {t('process.add-view')}
                    </Button>
                </Space>
            }
            centered
        >
            <ViewTable setShowModal={setAddViewModal} setSelectedRecord={setSelectedRecord} />
            {addViewModal && (
                <AddViewModal
                    showModal={addViewModal}
                    setShowModal={setAddViewModal}
                    initialData={selectedRecord}
                    setSelectedRecord={setSelectedRecord}
                />
            )}
        </Modal>
    );
};

export default ViewDetailsModal;
