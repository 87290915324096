import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { processDetails } from '../services';
import { forms, processFlow, process as processService } from '../../../../services';
import { PROCESS_ENVIRONMENTS } from '../../../../constants/constants';
// const req = require.context('../plugins', true, /.node.js$/);

// const modules = req.keys().map(req);
// console.log(modules);

const initialState = {
    rfInstance: null,
    process: {},
    processVersions: [],
    attributes: [],
    attributesMaster: [],
    attributeGroups: [],
    attributeGroupMaster: [],
    datasources: [],
    forms: [],
    dashboardTabs: [],
    views: [],
    viewsMaster: [],
    loading: false,
    error: '',
    selectedNode: null,
    selectedEdge: null,
    selectedMode: 'DESIGNER',
    isInteractionDisabled: false
};

export const fetchProcessById = createAsyncThunk('process-details/fetchById', async (id, { rejectWithValue }) => {
    try {
        const res = await processDetails.getProcessById(id);
        return res.data;
    } catch (error) {
        const message = error.message || error.toString();
        return rejectWithValue(message);
    }
});

export const saveFlow = createAsyncThunk('process-details/saveFlow', async (arg, { getState, rejectWithValue }) => {
    try {
        const { process, rfInstance } = getState().processDetails;
        if (rfInstance && process?.id) {
            const { nodes, edges, viewport } = rfInstance.toObject();
            const payload = { nodes, edges, viewport };
            const res = await processFlow.updateProcessFlow(process.id, payload);
            return res.data;
        }
    } catch (error) {
        const message = error.message || error.toString();
        return rejectWithValue(message);
    }
});

export const createAttributes = createAsyncThunk('attributes/create', async ({ id, payload }, { rejectWithValue }) => {
    try {
        const res = await processService.addAttributes(id, payload);
        return res.data;
    } catch (error) {
        const message = error.message || error.toString();
        return rejectWithValue(message);
    }
});

export const createViews = createAsyncThunk('views/create', async (payload , { rejectWithValue }) => {
    try {
        const res = await processService.addViews(payload)
        return res.data;
    } catch (error) {
        const message = error.message || error.toString();
        return rejectWithValue(message);
    }
});

export const createForm = createAsyncThunk('forms/create', async (payload, { rejectWithValue }) => {
    try {
        const res = await forms.addForm(payload);
        return res.data;
    } catch (error) {
        const message = error.message || error.toString();
        return rejectWithValue(message);
    }
});

export const updateForm = createAsyncThunk('forms/update', async ({ id, payload }, { rejectWithValue }) => {
    try {
        const res = await forms.updateForm(id, payload);
        return res.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

export const createDashboardTab = createAsyncThunk('dashboard-tabs/create', async (payload, { rejectWithValue }) => {
    try {
        const res = await processService.postTabData(payload);
        return res.data;
    } catch (error) {
        const message = error.message || error.toString();
        return rejectWithValue(message);
    }
});

export const addAttributeGroup = createAsyncThunk('attribute-group/create', async (payload, { rejectWithValue }) => {
    try {
        const res = await processService.addAttributeGroup(payload);
        return res.data;
    } catch (error) {
        const message = error.message || error.toString();
        return rejectWithValue(message);
    }
});

export const deleteAttributeGroup = createAsyncThunk('attribute-group/delete', async (id, { rejectWithValue }) => {
    try {
        const res = await processService.deleteAttributeGroup(id);
        return res.data;
    } catch (error) {
        const message = error.message || error.toString();
        return rejectWithValue(message);
    }
});

export const deleteAttribute = createAsyncThunk('attribute/delete', async (id, { rejectWithValue }) => {
    try {
        const res = await processService.deleteAttribute(id);
        return res.data;
    } catch (error) {
        const message = error.message || error.toString();
        return rejectWithValue(message);
    }
});

export const deleteView = createAsyncThunk('view/delete', async (id, { rejectWithValue }) => {
    try {
        const res = await processService.deleteView(id)
        return res.data;
    } catch (error) {
        const message = error.message || error.toString();
        return rejectWithValue(message);
    }
});

export const updateView = createAsyncThunk('view/update', async ({ id, payload }, { rejectWithValue }) => {
    try {
        await processService.updateView(id, payload);
        return payload;
    } catch (error) {
        const message = error.message || error.toString();
        return rejectWithValue(message);
    }
});

export const updateAttribute = createAsyncThunk('attribute/update', async ({ id, payload }, { rejectWithValue }) => {
    try {
        await processService.updateAttribute(id, payload);
        return payload;
    } catch (error) {
        const message = error.message || error.toString();
        return rejectWithValue(message);
    }
});

export const updateAttributeGroup = createAsyncThunk(
    'attribute-group/update',
    async ({ id, payload }, { rejectWithValue }) => {
        try {
            await processService.updateAttributeGroup(id, payload);
            return payload;
        } catch (error) {
            const message = error.message || error.toString();
            return rejectWithValue(message);
        }
    }
);

export const publishProcess = createAsyncThunk('process/publish', async (id, { rejectWithValue }) => {
    try {
        const res = await processDetails.publishProcess(id);
        return res.data;
    } catch (error) {
        const message = error.message || error.toString();
        return rejectWithValue(message);
    }
});

const validateAttributeRows = (attrs) => {
    return attrs.map((att) => {
        let error = {};
        if (!att.name) error.name = 'Name is Required';
        if (att.name) {
            const isDuplicateExist = attrs.findIndex((a) => a.name === att.name && a.id !== att.id);
            if (isDuplicateExist > -1) error.name = 'Attribute Already Exist';
        }
        if (!att.type) error.type = 'Attribute Type Required';

        if (att.type === 'select' && !att?.selectDataSource && !Object.keys(att?.selectDataSource || {}).length)
            error.selectDataSource = 'Data Source Selection Required!';

        return {
            ...att,
            error,
        };
    });
};

const processDetailsSlice = createSlice({
    name: 'process-details',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchProcessById.pending, (state, action) => {
                state.loading = true;
                state.process = {};
                state.processVersions = [];
                state.attributes = [];
                state.attributesMaster = [];
                state.forms = [];
                state.dashboardTabs = [];
                state.views = [];
                state.attributeGroups = [];
                state.attributeGroupMaster = [];
            })
            .addCase(fetchProcessById.fulfilled, (state, action) => {
                state.loading = false;
                const { process, processVersions, attributes, forms, dashboardTabs, views, attributeGroups,  } = action.payload;
                state.process = process;
                state.processVersions = processVersions;
                state.attributes = attributes;
                state.attributesMaster = attributes;
                state.forms = forms;
                state.dashboardTabs = dashboardTabs;
                state.views = views;
                state.attributeGroups = attributeGroups;
                state.attributeGroupMaster = attributeGroups;
            })
            .addCase(fetchProcessById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
                state.process = {};
                state.processVersions = [];
                state.attributes = [];
                state.attributesMaster = [];
                state.forms = [];
                state.attributeGroups = [];
                state.attributeGroupMaster = [];
            })
            .addCase(createAttributes.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(createAttributes.fulfilled, (state, action) => {
                state.loading = false;
                state.attributes.push(...action.payload);
                state.attributesMaster.push(...action.payload);
            })
            .addCase(createAttributes.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(createViews.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(createViews.fulfilled, (state, action) => {
                state.loading = false;
                state.views.push(action.payload);
                state.viewsMaster.push(action.payload);
            })
            .addCase(createViews.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(addAttributeGroup.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(addAttributeGroup.fulfilled, (state, action) => {
                state.loading = false;
                state.attributeGroups.push(action.payload);
                state.attributeGroupMaster.push(action.payload);
            })
            .addCase(addAttributeGroup.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(saveFlow.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(saveFlow.fulfilled, (state, action) => {
                state.loading = false;
                const { processVersions } = action?.payload || { processVersions: [] };
                if (processVersions?.length) {
                    state.processVersions = processVersions;
                }
            })
            .addCase(saveFlow.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(createForm.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(createForm.fulfilled, (state, action) => {
                state.loading = false;
                const { form, attributes } = action.payload;
                state.attributes = attributes;
                state.forms.unshift(form);
            })
            .addCase(createForm.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(updateForm.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(updateForm.fulfilled, (state, action) => {
                state.loading = false;
                const {
                    arg: { id },
                } = action.meta;
                const { form, attributes } = action.payload;
                if (id) {
                    state.attributes = attributes;
                    state.forms = state.forms.map((item) => (item.id === id ? { ...item, ...form } : item));
                }
            })
            .addCase(updateForm.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(createDashboardTab.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(createDashboardTab.fulfilled, (state, action) => {
                state.loading = false;
                state.dashboardTabs.unshift(action.payload);
            })
            .addCase(createDashboardTab.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(deleteAttributeGroup.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(deleteAttributeGroup.fulfilled, (state, action) => {
                state.loading = false;
                const { arg: id } = action.meta;
                if (id) {
                    state.attributeGroups = state.attributeGroups.filter((item) => item.id !== id);
                }
            })
            .addCase(deleteAttributeGroup.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(deleteAttribute.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(deleteAttribute.fulfilled, (state, action) => {
                state.loading = false;
                const { arg: id } = action.meta;
                if (id) {
                    state.attributes = state.attributes.filter((item) => item.id !== id);
                }
            })
            .addCase(deleteView.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(deleteView.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(deleteView.fulfilled, (state, action) => {
                state.loading = false;
                const { arg: id } = action.meta;
                if (id) {
                    state.views = state.views.filter((item) => item.id !== id);
                }
            })
            .addCase(deleteAttribute.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(updateAttribute.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(updateAttribute.fulfilled, (state, action) => {
                state.loading = false;
                const {
                    arg: { id },
                } = action.meta;
                if (id) {
                    state.attributes = state.attributes.map((item) =>
                        item.id === id ? { ...item, editMode: false, ...action.payload } : item
                    );
                    state.attributesMaster = state.attributesMaster.map((item) =>
                        item.id === id ? { ...item, ...action.payload } : item
                    );
                }
            })
            .addCase(updateAttribute.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(updateView.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(updateView.fulfilled, (state, action) => {
                state.loading = false;
                const {
                    arg: { id },
                } = action.meta;
                if (id) {
                    state.views = state.views.map((item) =>
                        item.id === id ? { ...item, ...action.payload } : item
                    );
                    state.viewsMaster = state.viewsMaster.map((item) =>
                        item.id === id ? { ...item, ...action.payload } : item
                    );
                }
            })
            .addCase(updateView.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(updateAttributeGroup.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(updateAttributeGroup.fulfilled, (state, action) => {
                state.loading = false;
                const {
                    arg: { id },
                } = action.meta;
                if (id) {
                    state.attributeGroups = state.attributeGroups.map((item) =>
                        item.id === id ? { ...item, ...action.payload } : item
                    );
                    state.attributeGroupMaster = state.attributeGroupMaster.map((item) =>
                        item.id === id ? { ...item, ...action.payload } : item
                    );
                }
            })
            .addCase(updateAttributeGroup.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(publishProcess.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(publishProcess.fulfilled, (state, action) => {
                state.loading = false;
                const { processVersions } = action.payload || { processVersions: [] };
                state.processVersions = processVersions;
            })
            .addCase(publishProcess.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            });
    },
    reducers: {
        setSelectedNode: (state, action) => {
            state.selectedNode = action.payload;
        },
        setSelectedEdge: (state, action) => {
            state.selectedEdge = action.payload;
        },
        setRfInstance: (state, action) => {
            state.rfInstance = action.payload;
        },
        toggleAttributeEditMode: (state, action) => {
            const { attributeId, editMode } = action.payload;
            const { attributes, attributesMaster } = state;
            const attribute = attributes?.find((att) => att.id === attributeId);
            if (attribute) {
                attribute.editMode = editMode;
                if (!editMode) {
                    const attrMaster = attributesMaster?.find((att) => att.id === attributeId);
                    Object.assign(attribute, attrMaster);
                }
            }
        },
        updateAttributeData: (state, action) => {
            const { id, key, value } = action.payload;
            const attribute = state.attributes?.find((att) => att.id === id);
            if (attribute) {
                attribute[key] = value;
            }
            const updatedAttributes = validateAttributeRows(state.attributes);
            state.attributes = updatedAttributes;
        },
        toggleAttributeGroupEditMode: (state, action) => {
            const { attributeId, editMode } = action.payload;
            const { attributeGroups, attributeGroupMaster } = state;
            const attributeGroup = attributeGroups?.find((att) => att.id === attributeId);
            if (attributeGroup) {
                attributeGroup.editMode = editMode;
                if (!editMode) {
                    const attrMaster = attributeGroupMaster?.find((att) => att.id === attributeId);
                    Object.assign(attributeGroup, attrMaster);
                }
            }
        },
        updateAttributeGroupData: (state, action) => {
            const { id, key, value } = action.payload;
            const attributeGroup = state.attributeGroups?.find((att) => att.id === id);
            if (attributeGroup) {
                attributeGroup[key] = value;
            }
            const updatedAttributeGroups = validateAttributeRows(state.attributeGroups);
            state.attributeGroups = updatedAttributeGroups;
        },
        resetAttributes: (state) => {
            state.attributes = [...state.attributesMaster];
            state.attributeGroups = [...state.attributeGroupMaster];
        },
        setSelectedMode: (state, action) => {
            state.selectedMode = action.payload;
            state.isInteractionDisabled = action.payload !== PROCESS_ENVIRONMENTS.DESIGNER;
        },
        reset: () => initialState
    },
});

const { reducer, actions } = processDetailsSlice;
export const {
    setSelectedNode,
    setSelectedEdge,
    setRfInstance,
    toggleAttributeEditMode,
    updateAttributeData,
    toggleAttributeGroupEditMode,
    updateAttributeGroupData,
    resetAttributes,
    setSelectedMode,
    reset,
} = actions;
export default reducer;
