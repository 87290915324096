import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { role } from '../../services';
import { showError } from '../../helpers/common';

const initialState = {
    roles: [],
    loading: false,
    error: '',
};

export const getAllRoles = createAsyncThunk('roles/all', async (_, { rejectWithValue }) => {
    try {
        const res = await role.getAllRoles();
        return res.data;
    } catch (error) {
        const message = error.message || error.toString();
        showError(message);
        return rejectWithValue(message);
    }
});

export const createRole = createAsyncThunk('roles/create', async ({ payload, toggle }, { rejectWithValue }) => {
    try {
        const res = await role.addRole(payload);
        toggle();
        return res.data;
    } catch (error) {
        const message = error.message || error.toString();
        showError(message);
        return rejectWithValue(message);
    }
});

export const updateRole = createAsyncThunk('roles/update', async ({ id, payload, toggle }, { rejectWithValue }) => {
    try {
        await role.updateRole(id, payload);
        toggle();
        return payload;
    } catch (error) {
        const message = error.message || error.toString();
        showError(message);
        return rejectWithValue(message);
    }
});

export const deleteRole = createAsyncThunk('roles/delete', async ({ id }, { rejectWithValue }) => {
    try {
        await role.deleteRole(id);
        return true;
    } catch (error) {
        const message = error.message || error.toString();
        showError(message);
        return rejectWithValue(message);
    }
});

const roleSlice = createSlice({
    name: 'role',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(createRole.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(createRole.fulfilled, (state, action) => {
                state.loading = false;
                state.roles.unshift(action.payload);
            })
            .addCase(createRole.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(getAllRoles.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getAllRoles.fulfilled, (state, action) => {
                state.loading = false;
                state.roles = action.payload;
            })
            .addCase(getAllRoles.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(deleteRole.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(deleteRole.fulfilled, (state, action) => {
                state.loading = false;
                const {
                    arg: { id },
                } = action.meta;
                if (id) {
                    state.roles = state.roles.filter((item) => item.id !== id);
                }
            })
            .addCase(deleteRole.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(updateRole.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(updateRole.fulfilled, (state, action) => {
                state.loading = false;
                const {
                    arg: { id },
                } = action.meta;
                if (id) {
                    state.roles = state.roles.map((item) => (item.id === id ? { ...item, ...action.payload } : item));
                }
            })
            .addCase(updateRole.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            });
    },
});

const { reducer } = roleSlice;
export default reducer;
