import { useEffect } from 'react';
import { useNodesInitialized, useReactFlow, useStore, getNodesBounds } from 'reactflow';
import clone from 'rfdc/default';
import { fixPoolPosition, getSourceHandlePosition, getTargetHandlePosition, isNumInRange } from '../utils';
import d3Hierarchy from './algorithms/d3-heirarchy';
import {
    DEFAULT_LANE_HEIGHT,
    DEFAULT_LANE_WIDTH,
    DEFAULT_PADDING,
    DEFAULT_POOL_HEIGHT,
    DEFAULT_POOL_WIDTH,
    POOL_BOTTOM_PADDING,
} from '../constant';
import { NODE_TYPES } from '../plugins/constant';

const options = { direction: 'LR', spacing: [70, 20] };

function useAutoLayout() {
    const { setNodes, setEdges } = useReactFlow();
    const nodesInitialized = useNodesInitialized();
    // Here we are storing a map of the nodes and edges in the flow. By using a
    // custom equality function as the second argument to `useStore`, we can make
    // sure the layout algorithm only runs when something has changed that should
    // actually trigger a layout change.
    const elements = useStore(
        (state) => ({
            nodeMap: state.nodeInternals,
            edgeMap: state.edges.reduce((acc, edge) => acc.set(edge.id, edge), new Map()),
        }),
        // The compare elements function will only update `elements` if something has
        // changed that should trigger a layout. This includes changes to a node's
        // dimensions, the number of nodes, or changes to edge sources/targets.
        compareElements
    );

    useEffect(() => {
        // Only run the layout if there are nodes and they have been initialized with
        // their dimensions
        if (!nodesInitialized || elements.nodeMap.size === 0) {
            return;
        }

        // The callback passed to `useEffect` cannot be `async` itself, so instead we
        // create an async function here and call it immediately afterwards.
        const runLayout = async () => {
            const allNodes = clone([...elements.nodeMap.values()]);
            const pools = clone(allNodes).filter((node) => node.type === 'pool');
            const lanes = clone(allNodes).filter((node) => node.type === 'lane');
            const poolsAndLanesIds = [...pools, ...lanes].map((x) => x.id);
            const nodes = clone(allNodes)
                .filter((node) => !poolsAndLanesIds.includes(node.id))
                .map((node) => {
                    delete node.parentNode;
                    return node;
                });
            const edges = clone([...elements.edgeMap.values()]);

            const { nodes: nextNodes, edges: nextEdges } = await d3Hierarchy(nodes, edges, options);

            // Mutating the nodes and edges directly here is fine because we expect our
            // layouting algorithms to return a new array of nodes/edges.
            let startPosition = null;
            const nodesBounds = getNodesBounds(nextNodes);
            let { width: boundsWidth } = nodesBounds || { width: 0 };
            if (boundsWidth >= DEFAULT_LANE_WIDTH) {
                boundsWidth = boundsWidth + DEFAULT_PADDING;
            }
            const maxLaneWidth = Math.max(boundsWidth, DEFAULT_LANE_WIDTH);
            for (const node of nextNodes) {
                if (node?.data?.nodeType === NODE_TYPES.START_NODE) {
                    startPosition = node.position;
                }
                const initialNodeObj = allNodes.find((n) => n.id === node.id);
                node.parentNode = initialNodeObj.parentNode;
                node.style = { ...node.style, opacity: 1 };
                node.sourcePosition = getSourceHandlePosition(options.direction);
                node.targetPosition = getTargetHandlePosition(options.direction);
                delete node.draggingOver;
            }

            for (const edge of edges) {
                edge.style = { ...edge.style, opacity: 1 };
            }

            const updatedNodes = allNodes.map((node) => {
                if (node.type === NODE_TYPES.LANE) {
                    const nodes = nextNodes.filter((n) => n.parentNode === node.id);
                    if (nodes?.length) {
                        const bounds = getNodesBounds(nodes || []);
                        let { height } = bounds || { height: 0 };
                        if (height >= DEFAULT_LANE_HEIGHT) {
                            height = height + DEFAULT_PADDING;
                        }
                        node.style = {
                            ...node.style,
                            height: Math.max(height, DEFAULT_LANE_HEIGHT),
                        };
                    }
                } else if (![NODE_TYPES.POOL, NODE_TYPES.LANE].includes(node.type)) {
                    const nextNode = nextNodes.find((n) => n.id === node.id);
                    if (nextNode) {
                        return clone(nextNode);
                    }
                }
                return clone(node);
            });

            updatedNodes
                .filter((n) => n.type === NODE_TYPES.POOL)
                .forEach((pool, poolIndex) => {
                    const lanes = updatedNodes.filter((n) => n.parentNode === pool.id);
                    if (lanes?.length) {
                        lanes.forEach((lane, laneIndex) => {
                            lane.style.width = maxLaneWidth + startPosition.x;
                            if (laneIndex > 0) {
                                const lastLane = lanes[laneIndex - 1];
                                lane.position = {
                                    ...lastLane.position,
                                    y: lastLane.style.height + lastLane.position.y,
                                };
                            }
                            const elements = updatedNodes.filter((n) => n.parentNode === lane.id);
                            if (elements?.length) {
                                const firstElement = elements[0];
                                const elementsBounds = getNodesBounds(elements);
                                let offsetY = Math.max(
                                    Math.max(elementsBounds.height ?? 0, lane.style.height ?? 0),
                                    DEFAULT_LANE_HEIGHT
                                );
                                offsetY = offsetY / 2;
                                let elementPositionY =
                                    firstElement.position.y +
                                    (firstElement.style.height || firstElement.style.minHeight) / 2 +
                                    offsetY;
                                let isNodeInRange = isNumInRange(elementPositionY, offsetY, 5);
                                while (!isNodeInRange) {
                                    const difference = offsetY - Math.abs(elementPositionY);
                                    elementPositionY += difference;
                                    isNodeInRange = isNumInRange(elementPositionY, offsetY, 5);
                                    offsetY += difference / 2;
                                }
                                for (const element of elements) {
                                    const nodesOnSameAxis = updatedNodes
                                        .filter((node) => ![NODE_TYPES.POOL, NODE_TYPES.LANE].includes(node.type))
                                        .filter((node) => element.position.x === node.position.x);
                                    if (nodesOnSameAxis?.length > 1) {
                                        const sameLaneNodes = nodesOnSameAxis.filter((node) => node.parentNode === lane.id);
                                        for (const [index, n] of sameLaneNodes.entries()) {
                                            let posY = 20;
                                            if (index > 0) {
                                                const lastElement = sameLaneNodes[index - 1];
                                                if (lastElement) {
                                                    posY = lastElement?.position?.y + lastElement?.height + DEFAULT_PADDING;
                                                }
                                            }
                                            n.position = {
                                                x: n.position.x,
                                                y: posY
                                            }
                                        }
                                        continue;
                                    }
                                    let posY = element.position.y + offsetY;
                                    element.position = {
                                        x: element.position.x,
                                        y: posY,
                                    };
                                }
                            }
                        });
                        const bounds = getNodesBounds(lanes || []);
                        pool.style.height = Math.max((bounds.height ?? 0) + POOL_BOTTOM_PADDING, DEFAULT_POOL_HEIGHT);
                        pool.style.width = Math.max(
                            (maxLaneWidth ?? 0) + startPosition.x + DEFAULT_PADDING,
                            DEFAULT_POOL_WIDTH
                        );
                    }
                });

            fixPoolPosition(updatedNodes);

            setNodes(updatedNodes);
            setEdges(nextEdges);
        };

        runLayout();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nodesInitialized, elements, setNodes, setEdges]);
}

export default useAutoLayout;

function compareElements(xs, ys) {
    return compareNodes(xs.nodeMap, ys.nodeMap) && compareEdges(xs.edgeMap, ys.edgeMap);
}

function compareNodes(xs, ys) {
    // the number of nodes changed, so we already know that the nodes are not equal
    if (xs.size !== ys.size) return false;

    for (const [id, x] of xs.entries()) {
        const y = ys.get(id);

        // the node doesn't exist in the next state so it just got added
        if (!y) return false;
        // We don't want to force a layout change while a user might be resizing a
        // node, so we only compare the dimensions if the node is not currently
        // being resized.
        //
        // We early return here instead of using a `continue` because there's no
        // scenario where we'd want nodes to start moving around *while* a user is
        // trying to resize a node or move it around.
        if (x.resizing || x.dragging) return true;
        if (x.width !== y.width || x.height !== y.height) return false;
        if (x.draggingOver !== y.draggingOver) return false;
    }

    return true;
}

function compareEdges(xs, ys) {
    // the number of edges changed, so we already know that the edges are not equal
    if (xs.size !== ys.size) return false;

    for (const [id, x] of xs.entries()) {
        const y = ys.get(id);

        // the edge doesn't exist in the next state so it just got added
        if (!y) return false;
        if (x.source !== y.source || x.target !== y.target) return false;
        if (x?.sourceHandle !== y?.sourceHandle) return false;
        if (x?.targetHandle !== y?.targetHandle) return false;
    }

    return true;
}
