import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from 'antd';
import { AddSelectField } from '../../../../../components/fields/add-select';
import { createDashboardTab } from '../../slices/process-details';
import { useState } from 'react';

export const DashboardVisibility = ({ nodeData, onChange }) => {
    const { 'dashboard-settings': dashboardVisibility } = nodeData;
    const [showOnDashboard, setShowOnDashboard] = useState(dashboardVisibility?.showOnDashboard || false);
    const [tabName, setTabName] = useState(dashboardVisibility?.tabName || '');

    const dispatch = useDispatch();
    const { dashboardTabs, process } = useSelector((state) => state.processDetails);

    const dashboardTabsOptions = dashboardTabs?.map((tab) => ({ label: tab.name, value: tab.name }));

    const triggerChange = (changedValue) => {
        onChange({ showOnDashboard, tabName, ...changedValue });
    };

    const onAddNewTab = async (selectedTabName) => {
        await dispatch(createDashboardTab({ processId: process.id, name: selectedTabName }));
        triggerChange({ tabName: selectedTabName });
    };

    const onShowOnDashboardChange = () => {
        const checkboxValue = !showOnDashboard;
        setShowOnDashboard(checkboxValue);
        const payload = {
            showOnDashboard: checkboxValue
        }
        if (!checkboxValue) {
            payload.tabName = '';
        }
        triggerChange(payload);
    };

    const onSelect = (selectedTabName) => {
        setTabName(selectedTabName);
        triggerChange({ tabName: selectedTabName });
    };

    return (
        <>
            <Checkbox onChange={onShowOnDashboardChange} checked={showOnDashboard}>
                Show on Dashboard
            </Checkbox>
            <br />
            {showOnDashboard && (
                <AddSelectField
                    options={dashboardTabsOptions}
                    onAdd={onAddNewTab}
                    onSelect={onSelect}
                    initialValue={tabName}
                />
            )}
        </>
    );
};
