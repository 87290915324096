import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

const Breadcrumb = (props) => {
    return (
        <Row className="d-flex breadcrumb">
            <Col className="col-6 d-flex align-items-center">
                <h4 className="mb-0">{props.breadcrumbItem}</h4>
            </Col>
            <Col className="col-6 d-flex justify-content-end  align-items-baseline">{props.children}</Col>
        </Row>
    );
};

Breadcrumb.propTypes = {
    breadcrumbItem: PropTypes.string,
    title: PropTypes.string,
};

export default Breadcrumb;
