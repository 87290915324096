import { Form, Input, Modal, Radio, Select, Row, Col } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Label } from 'reactstrap';
import { extractOperatorAndValue } from '../../utils';
import { useSelector } from 'react-redux';

export const ConditionModal = ({
    showModal = false,
    handleOk = () => false,
    handleCancel = () => false,
    gatewayAttribute = {},
    edgeData = null,
    edgeConditions = [],
}) => {
    const [form] = Form.useForm();

    const [radioValue, setRadioValue] = useState(edgeData?.condition || 'default');
    const [comparisionType, setComparisionType] = useState('');
    const initialValues = {
        condition: edgeData?.condition || 'default',
        attribute: edgeData?.attribute || '',
        ...extractOperatorAndValue(edgeData),
    };

    const operatorValue = Form.useWatch('operator', form);
    const [isDefaultDisabled, setIsDefaultDisabled] = useState(false);

    const { attributes } = useSelector((state) => state.processDetails);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const gatewayAttributeObj = useCallback(
        attributes?.find((a) => a.id === gatewayAttribute),
        [gatewayAttribute]
    );

    const validateAndSubmit = useCallback(async () => {
        try {
            await form.validateFields();
            const data = form.getFieldValue();
            const edgeConditionValidation = edgeConditions.some(
                (condition) =>
                    (edgeData ? condition?.id !== edgeData?.id : false) &&
                    condition?.condition === data?.condition &&
                    condition?.operator === data?.operator &&
                    condition?.attribute === data?.attribute &&
                    condition?.value === data?.value
            );
            if (edgeConditionValidation) {
                alert('Edge with similar condition already exists. Please select a new condition.');
                return false;
            }
            handleOk(data);
        } catch (error) {
            console.error('Validation error', error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    const onConditionRadioChange = useCallback(
        (e) => {
            form.setFieldsValue({
                operator: '',
                attribute: '',
                value: '',
            });
            setRadioValue(e.target.value);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [form]
    );

    const onValueTypeRadioChange = useCallback(
        (e) => {
            const value = e.target.value;
            const fieldsToUpdate = value === 'value' ? { attribute: '' } : value === 'attribute' ? { value: '' } : {};
            form.setFieldsValue(fieldsToUpdate);
            setComparisionType(value);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [form]
    );

    const attributesList = attributes.reduce(
        (acc, obj) => {
            const groupName = obj.attributeGroupName || 'Others';
            const groupData = acc.find((a) => a.label === groupName);
            if (groupData) {
                groupData.options.push({
                    label: obj.name,
                    value: obj.id,
                });
            } else {
                acc.push({
                    label: obj.attributeGroupName,
                    options: [
                        {
                            label: obj.name,
                            value: obj.id,
                        },
                    ],
                });
            }
            return acc;
        },
        [
            {
                label: 'Others',
                options: [],
            },
        ]
    );

    attributesList.push(
        ...attributesList.splice(
            attributesList.findIndex((v) => v.label === 'Others'),
            1
        )
    );

    useEffect(() => {
        if (edgeData) {
            let comparisionTypeLocal = '';
            if (edgeData?.attribute) {
                comparisionTypeLocal = 'attribute';
            }
            if (edgeData?.value) {
                comparisionTypeLocal = 'value';
            }
            setComparisionType(comparisionTypeLocal);
        }
    }, [edgeData]);

    useEffect(() => {
        if (edgeConditions?.length && edgeData?.conditionType !== 'default') {
            const isDefaultExists = edgeConditions.find((cond) => cond.conditionType === 'default');
            if (isDefaultExists) {
                setIsDefaultDisabled(isDefaultExists);
                form.setFieldValue('conditionType', 'condition');
                setRadioValue('condition');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [edgeConditions]);

    let condition = '';
    if (initialValues?.condition === 'default') {
        condition = '(Default)';
    } else if (initialValues?.attribute) {
        const attribute = attributes.find((a) => a.id === initialValues?.attribute);
        condition = `(${initialValues.operator} ${attribute.name})`;
    } else if (initialValues.value) {
        condition = `(${initialValues.operator} ${initialValues.value})`;
    }
    const modalTitle = `Condition ${condition}`;

    const onChangeOperator = (val) => {
        const value = ['contains', 'not-contains'].includes(val) ? [] : '';
        form.setFieldValue('value', value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const getOperator = () => {
        if (!['select', 'checkbox'].includes(gatewayAttributeObj?.type)) {
            return (
                <Select style={{ width: '100%' }} name="operator">
                    <Select.Option value=">">&gt;</Select.Option>
                    <Select.Option value=">=">&gt;=</Select.Option>
                    <Select.Option value="=">=</Select.Option>
                    <Select.Option value="<=">&lt;=</Select.Option>
                    <Select.Option value="<">&lt;</Select.Option>
                    <Select.Option value="null">null</Select.Option>
                    <Select.Option value="not null">not null</Select.Option>
                </Select>
            );
        } else {
            return (
                <Select style={{ width: '100%' }} name="operator" onChange={onChangeOperator}>
                    <Select.Option value="contains">Contains</Select.Option>
                    <Select.Option value="not-contains">Not Contains</Select.Option>
                    <Select.Option value="equals">Equals</Select.Option>
                </Select>
            );
        }
    };

    const getValue = () => {
        if (!['select', 'checkbox'].includes(gatewayAttributeObj?.type)) {
            return <Input type={gatewayAttributeObj?.type} disabled={comparisionType !== 'value'} />;
        } else {
            const { option } = gatewayAttributeObj?.selectDataSource;
            const mode = ['contains', 'not-contains'].includes(operatorValue) ? 'multiple' : '';
            return (
                <Select style={{ width: '100%' }} name="operator" disabled={comparisionType !== 'value'} mode={mode}>
                    {option.map((o) => (
                        <Select.Option value={o.value}>{o.label}</Select.Option>
                    ))}
                </Select>
            );
        }
    };

    return (
        <Modal
            title={modalTitle}
            open={showModal}
            onOk={validateAndSubmit}
            onCancel={handleCancel}
            destroyOnClose
            maskClosable={false}
        >
            <Form form={form} initialValues={initialValues} layout="vertical">
                <Form.Item name="condition">
                    <Radio.Group onChange={onConditionRadioChange} style={{ width: '100%' }}>
                        <div className="d-flex flex-column">
                            <Radio value="condition">Condition</Radio>
                            {radioValue === 'condition' && (
                                <>
                                    <Row>
                                        <Col span={24}>
                                            <Label className="form-label">Attribute</Label>
                                            <Select
                                                style={{ width: '100%' }}
                                                disabled
                                                options={attributesList}
                                                value={gatewayAttribute}
                                            ></Select>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16]}>
                                        <Col span={12}>
                                            <Form.Item
                                                label="Operator"
                                                name="operator"
                                                rules={[
                                                    {
                                                        required: radioValue === 'condition',
                                                        message: 'Operator is required.',
                                                    },
                                                ]}
                                            >
                                                {getOperator()}
                                            </Form.Item>
                                        </Col>

                                        <Col span={12}>
                                            <Radio.Group
                                                onChange={onValueTypeRadioChange}
                                                value={comparisionType}
                                                style={{ width: '100%' }}
                                            >
                                                <div className="d-flex flex-column">
                                                    <Radio value="value" style={{ padding: '0 0 8px' }}>
                                                        Value
                                                    </Radio>
                                                    <Form.Item
                                                        name="value"
                                                        style={{ width: '100%' }}
                                                        rules={[
                                                            {
                                                                required: comparisionType === 'value',
                                                                message: 'Value is required.',
                                                            },
                                                        ]}
                                                    >
                                                        {getValue()}
                                                    </Form.Item>
                                                    <Radio value="attribute" style={{ padding: '0 0 8px' }}>
                                                        Attribute
                                                    </Radio>
                                                    <Form.Item
                                                        name="attribute"
                                                        rules={[
                                                            {
                                                                required: comparisionType === 'attribute',
                                                                message: 'Attribute is required.',
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            disabled={comparisionType !== 'attribute'}
                                                            options={attributesList}
                                                        ></Select>
                                                    </Form.Item>
                                                </div>
                                            </Radio.Group>
                                        </Col>
                                    </Row>
                                </>
                            )}
                            <Radio value="default" disabled={isDefaultDisabled}>
                                Default
                            </Radio>
                        </div>
                    </Radio.Group>
                </Form.Item>
            </Form>
        </Modal>
    );
};
