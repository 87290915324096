import { Modal, Form } from 'antd';
import FormMaker from '../../../components/form-maker';
import { useSelector, useDispatch } from 'react-redux';
import { createAttributes } from '../details/slices/process-details';
import { showError } from '../../../helpers/common';
import { useState } from 'react';

const AttributeModal = ({ showAddAttribute, setShowAddAttribute }) => {
    const [initialValues, setInitialValues] = useState({});
    const dispatch = useDispatch();
    const [attributeForm] = Form.useForm();
    const { process: processDetail, attributes } = useSelector((state) => state.processDetails);

    const onAddAttributes = async () => {
        try {
            const values = attributeForm.getFieldsValue();
            const selectedAttributes = values?.excelUpload;
            if (!selectedAttributes?.length) {
                attributeForm.setFields([
                    {
                        name: 'excelUpload',
                        errors: ['Attributes selection required!'],
                    },
                ]);
                return false;
            }
            const nSelectedAttributes = selectedAttributes.map((att) => {
                let error = {};
                if (!att.name) error.name = 'Attribute Name Required';
                if (att.name) {
                    const isDuplicateExist = [...selectedAttributes, ...attributes].findIndex(
                        (a) => a.name === att.name && a.id !== att.id
                    );
                    if (isDuplicateExist > -1) error.name = 'Attribute Already Exist';
                }
                if (!att.type) error.type = 'Attribute Type Required';

                if (att.type === 'select' && !att?.selectDataSource && !Object.keys(att?.selectDataSource || {}).length)
                    error.dataSourceInfo = 'Data Source Selection Required!';

                return {
                    ...att,
                    error,
                };
            });
            attributeForm.setFieldValue('excelUpload', [...nSelectedAttributes]);
            const isErrorPresent = nSelectedAttributes.findIndex((att) => {
                return Object.keys(att?.error).length;
            });
            if (isErrorPresent > -1) {
                return false;
            }

            if (selectedAttributes?.length) {
                const payload = selectedAttributes.map((sAttribute) => {
                    const payload = {
                        name: sAttribute?.name,
                        type: sAttribute?.type,
                        comments: sAttribute?.comments,
                        attributeGroupId: sAttribute?.attributeGroupId,
                    };
                    if (sAttribute?.selectDataSource) {
                        if (sAttribute?.selectDataSource?.dataSourceType === 'staticList') {
                            payload.selectDataSource = {
                                dataSourceType: sAttribute?.selectDataSource?.dataSourceType,
                                option: sAttribute?.selectDataSource?.option,
                            };
                        }
                        if (sAttribute?.selectDataSource?.dataSourceType === 'selectDS') {
                            payload.selectDataSource = {
                                dataSourceType: sAttribute?.selectDataSource?.dataSourceType,
                                dataSourceName: sAttribute?.selectDataSource?.name,
                                label: sAttribute?.selectDataSource?.label,
                                value: sAttribute?.selectDataSource?.value,
                            };
                        }
                    }
                    return payload;
                });
                dispatch(
                    createAttributes({
                        id: processDetail.id,
                        payload,
                    })
                );
            }
            setShowAddAttribute(false);
            

        } catch (err) {
            showError(err?.message);
        }
    };

    return (
        <Modal
            title="Add Attribute"
            maskClosable={false}
            open={showAddAttribute}
            onOk={() => attributeForm.submit()}
            onCancel={() => {
                setShowAddAttribute(false);
                setInitialValues({});
            }}
            width={1220}
            destroyOnClose
        >
            <FormMaker
                form={attributeForm}
                initialValues={initialValues}
                // below prop will be used for sending additional information to form maker
                formInfo={{ processId: processDetail.id }}
                fields={[
                    {
                        type: 'excel',
                        hideFetchAttributes: true,
                        name: 'excelUpload',
                        defaultAttributes: attributes,
                        showAddIcon: true,
                    },
                ]}
                onFinish={onAddAttributes}
                onFinishFailed={(res) => {
                    console.log('res', res);
                }}
            />
        </Modal>
    );
};

export default AttributeModal;
