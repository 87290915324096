import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Card,
    Col,
    Form,
    FormFeedback,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Spinner,
    UncontrolledTooltip,
} from 'reactstrap';
import * as Yup from 'yup';
import Breadcrumbs from '../../components/breadcrumb/Breadcrumb';
import { DeleteModal } from '../../components/delete-modal/DeleteModal';
import Grid from '../../components/table/ag-grid';
import { createRole, deleteRole, getAllRoles, updateRole } from '../../store/slices/role';

function RolePage() {
    const { t } = useTranslation();
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedRole, setSelectedRole] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [modal, setModal] = useState(false);
    const [searchText, setSearchText] = useState('');

    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            name: selectedRole?.name || '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required(t('error-message.role-name-required')),
        }),
        onSubmit: (values) => {
            const payload = {
                name: values.name,
            };
            if (isEdit && selectedRole?.id) {
                // update role
                dispatch(updateRole({ id: selectedRole?.id, payload, toggle }));
            } else {
                // create new role
                dispatch(createRole({ payload, toggle }));
            }
        },
    });

    const dispatch = useDispatch();
    const { roles, loading } = useSelector((state) => state.role);

    useEffect(() => {
        dispatch(getAllRoles());
    }, [dispatch]);

    const handleDeleteRole = () => {
        if (selectedRole?.id) {
            dispatch(deleteRole({ id: selectedRole.id }));
            setDeleteModal(false);
        }
    };

    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);
            setSelectedRole(null);
        } else {
            setModal(true);
        }
        validation.resetForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modal]);

    const handleRoleClick = useCallback(
        (arg) => {
            const isEditMode = arg ? true : false;
            setSelectedRole(arg);
            setIsEdit(isEditMode);
            toggle();
        },
        [toggle]
    );

    const onClickDelete = (role) => {
        setSelectedRole(role);
        setDeleteModal(true);
    };

    const [columnDefs] = useState([
        {
            headerName: t('role.name'),
            field: 'name',
        },
        {
            headerName: t('action.action'),
            cellRenderer: (props) => {
                const { data } = props;
                return (
                    <div className="d-flex ag-action-cell">
                        <p onClick={() => handleRoleClick(data)} className="text-primary">
                            <i className="fas fa-edit" id="edittooltip" />
                            <UncontrolledTooltip placement="top" target="edittooltip">
                                {t('action.edit')}
                            </UncontrolledTooltip>
                        </p>
                        <p onClick={() => onClickDelete(data)} className="mx-2 text-danger">
                            <i className="fas fa-trash" id="deletetooltip" />
                            <UncontrolledTooltip placement="top" target="deletetooltip">
                                {t('action.delete')}
                            </UncontrolledTooltip>
                        </p>
                    </div>
                );
            },
        },
    ]);

    return (
        <React.Fragment>
            <DeleteModal
                showModal={deleteModal}
                handleOk={handleDeleteRole}
                handleCancel={() => setDeleteModal(false)}
            />

            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title={t('role.role')} breadcrumbItem={t('role.role')}>
                        <>
                            <Input
                                type="text"
                                className="form-control bg-light border-light rounded w-50"
                                placeholder={`${t('action.search')}...`}
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                            <Button color="primary" onClick={() => handleRoleClick()}>
                                {t('role.add-role')}
                            </Button>
                        </>
                    </Breadcrumbs>
                    <Card>
                        <Row>
                            <Col xs={12}>
                                <Grid columnDefs={columnDefs} rowData={roles} quickFilterText={searchText} />
                            </Col>
                        </Row>
                    </Card>
                    <Modal isOpen={modal} toggle={toggle} autoFocus={false}>
                        <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? t('role.edit-role') : t('role.add-role')}
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <Row>
                                    <Col className="col-12">
                                        <div className="mb-3">
                                            <Label className="form-label">{t('role.name')}</Label>
                                            <Input
                                                name="name"
                                                type="text"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.name || ''}
                                                invalid={
                                                    validation.touched.name && validation.errors.name ? true : false
                                                }
                                                autoFocus
                                            />
                                            {validation.touched.name && validation.errors.name ? (
                                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <Button type="submit" color="success" disabled={loading}>
                                                {!loading && t('action.save')}
                                                {loading && <Spinner size="sm"></Spinner>}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
        </React.Fragment>
    );
}

export default RolePage;
