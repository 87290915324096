import { useCallback, useEffect, useRef, useState } from "react";
import { useNodesInitialized, useReactFlow } from "reactflow";
import { useDispatch, useSelector } from "react-redux";
import { saveFlow } from "../slices/process-details";
import { debounce } from "lodash";

function NodeLabel({ id = '', placeholder = '', name = '', className = 'node-label' }) {
    const [defaultName, setDefaultName] = useState(name || '');
    const inputRef = useRef(null);
    const { setNodes } = useReactFlow();
    const dispatch = useDispatch();
    const { isInteractionDisabled } = useSelector((state) => state.processDetails);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSubmit = useCallback(debounce((label) => {
        setNodes((nodes) =>
            nodes.map((node) => {
                if (node.id === id) {
                    return {
                        ...node,
                        data: {
                            ...node.data,
                            name: label,
                        },
                    };
                }

                return node;
            })
        );
        setTimeout(() => {
            dispatch(saveFlow());
        });
    }, 500), []);

    const handleInput = (e) => {
        const newValue = e.target.value;
        setDefaultName(newValue);
        debouncedSubmit(newValue);
    };

    const updateNameValue = () =>{
        setDefaultName(name);
    };

    useEffect(() => {
        updateNameValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name]);

    useEffect(() => {
        inputRef.current.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useNodesInitialized()]);

    return (
        <input
            type="text"
            className={className}
            placeholder={placeholder}
            value={defaultName}
            onChange={handleInput}
            ref={inputRef}
            disabled={isInteractionDisabled}
        />
    );
}

export default NodeLabel;