import { toast } from "react-toastify";
import i18next from 'i18next';
import moment from "moment";

const DATE_FORMAT = 'DD.MM.YYYY';
const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm:ss';
const CURRENCY_FORMAT_OPTION = {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
};

export const showSuccess = (successKey = null) => {
    const successMessage = `success-message.${successKey}`;
    toast.success(i18next.t([successMessage, 'success-message.save-success']));
};

export const showError = (errorKey) => {
    const errorMessage = `api-error.${errorKey}`;
    toast.error(i18next.t([errorMessage, 'api-error.SOMETHING_WENT_WRONG']));
};

export const toggleSidebar = () => {
    var body = document.body;
    var windowSize = document.documentElement.clientWidth;

    body.classList.toggle('vertical-collpsed');
    body.classList.toggle('sidebar-enable');

    if (windowSize > 991) {
        body.getAttribute('data-sidebar-size') === 'sm'
            ? body.setAttribute('data-sidebar-size', 'lg')
            : body.setAttribute('data-sidebar-size', 'sm');
    }
}

export const getFormattedDate = (inputDate, containTime = false) => {
    if (!inputDate) {
        return '';
    }
    let outputDateFormat = containTime ? DATE_TIME_FORMAT : DATE_FORMAT;
    return moment(new Date(inputDate)).format(outputDateFormat);
};

export const formatValueToLocale = (data, isCurrency = false, minimumFractionDigits = 2) => {
    const num = Number.parseFloat(data);
    if (Number.isNaN(num)) {
        return '';
    }
    return num.toLocaleString('de-DE', isCurrency ? CURRENCY_FORMAT_OPTION : {
        minimumFractionDigits,
    });
};