export const SHOW_FORM_OPTIONS = {
    DASHBOARD_FORM: 'dashboard-form',
    TASK_LIST: 'task-list',
    POPUP_WINDOW: 'popup-window',
};

export const CANCEL_BEHAVIOUR_OPTIONS = [{
    key: 'cancel-task',
    value: 'node-details.cancel-option-1'
}, {
    key: 'next-step',
    value: 'node-details.cancel-option-2'
}]