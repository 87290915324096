import BaseNode from "../BaseNode";

class LaneNode extends BaseNode {
    constructor() {
        super();
        this.displayName = 'Lane';
        this.description = '';
        this.name = 'lane';
    }
}

export { LaneNode as default };