import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { process } from '../../services';
import { showError } from '../../helpers/common';

const initialState = {
    processes: [],
    loading: false,
    error: '',
};

export const createProcess = createAsyncThunk('process/create', async ({ payload }, { rejectWithValue, dispatch }) => {
    try {
        const res = await process.addProcess(payload);
        dispatch(getAllProcesses());
        return res.data;
    } catch (error) {
        const message = error.message || error.toString();
        showError(message);
        return rejectWithValue(message);
    }
});

export const getAllProcesses = createAsyncThunk('process/all', async (_, { rejectWithValue }) => {
    try {
        const res = await process.getAllProcess();
        return res.data;
    } catch (error) {
        const message = error.message || error.toString();
        return rejectWithValue(message);
    }
});

export const deleteProcess = createAsyncThunk('process/delete', async ({ id }, { rejectWithValue }) => {
    try {
        await process.deleteProcess(id);
        return true;
    } catch (error) {
        const message = error.message || error.toString();
        return rejectWithValue(message);
    }
});

export const updateProcess = createAsyncThunk('process/update', async ({ id, payload }, { rejectWithValue }) => {
    try {
        await process.updateProcess(id, payload);
        return payload;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

const processSlice = createSlice({
    name: 'process',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(createProcess.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(createProcess.fulfilled, (state, action) => {
                state.loading = false;
                state.processes.unshift(action.payload);
            })
            .addCase(createProcess.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(getAllProcesses.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getAllProcesses.fulfilled, (state, action) => {
                state.loading = false;
                state.processes = action.payload;
            })
            .addCase(getAllProcesses.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(deleteProcess.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(deleteProcess.fulfilled, (state, action) => {
                state.loading = false;
                const {
                    arg: { id },
                } = action.meta;
                if (id) {
                    state.processes = state.processes.filter((item) => item.id !== id);
                }
            })
            .addCase(deleteProcess.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(updateProcess.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(updateProcess.fulfilled, (state, action) => {
                state.loading = false;
                const {
                    arg: { id },
                } = action.meta;
                if (id) {
                    state.processes = state.processes.map((item) =>
                        item.id === id ? { ...item, ...action.payload } : item
                    );
                }
            })
            .addCase(updateProcess.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            });
    },
});

const { reducer } = processSlice;
export default reducer;
