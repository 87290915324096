/* eslint-disable import/no-anonymous-default-export */
import request from './request';

const getAllProcess = () => request.get('processes');
const getProcess = (id) => request.get(`processes/${id}`);
const addProcess = (payload) => request.post('processes', payload);
const updateProcess = (id, payload) => request.put(`processes/${id}`, payload);
const deleteProcess = (id) => request.delete(`processes/${id}`);

const getProcessAttributeList = (id) => request.get(`process-attribute-list/${id}`);
const addAttributes = (processId, payload) => request.post(`attributes/${processId}`, payload);
const deleteAttribute = (attributeId) => request.delete(`attributes/${attributeId}`);
const updateAttribute = (attributeId, payload) => request.put(`attributes/${attributeId}`, payload);
const getAttribute = (attributeId) => request.get(`attributes/${attributeId}`);

const getTabData = (id) => request.get(`dashboard-tabs/${id}`);
const postTabData = (payload) => request.post('/dashboard-tabs', payload);

const getAttributeList = (id) => request.get(`process-attribute-list/${id}`);

const addAttributeGroup = (payload) => request.post('attribute-group', payload);
const getAttributeGroupList = (processId) => request.get(`attribute-group/${processId}`);
const deleteAttributeGroup = (attributeGroupId) => request.delete(`attribute-group/${attributeGroupId}`);
const updateAttributeGroup = (attributeGroupId, payload) => request.put(`/attribute-group/${attributeGroupId}`, payload)

const addViews = (payload) => request.post('/views', payload);
const getViewsList = (processId) => request.get(`/views/${processId}`) 
const getViewById = (id) => request.get(`/viewsById/${id}`) 
const updateView = (id, payload) => request.put(`/views/${id}`, payload) 
const deleteView = (id) => request.delete(`/views/${id}`) 

export default {
    getAllProcess,
    getProcess,
    addProcess,
    updateProcess,
    deleteProcess,
    getProcessAttributeList,
    addAttributes,
    getTabData,
    postTabData,
    getAttributeList,
    updateAttribute,
    deleteAttribute,
    getAttribute,
    addAttributeGroup,
    getAttributeGroupList,
    deleteAttributeGroup,
    updateAttributeGroup,
    addViews,
    getViewsList,
    getViewById,
    updateView,
    deleteView
};
