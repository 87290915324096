import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createTenant, deleteTenant, getAllTenants, updateTenant } from '../../store/slices/tenant';
import { DeleteModal } from '../../components/delete-modal/DeleteModal';
import Breadcrumbs from '../../components/breadcrumb/Breadcrumb';
import {
    Button,
    Card,
    Col,
    Form,
    FormFeedback,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Spinner,
    UncontrolledTooltip,
} from 'reactstrap';
import Grid from '../../components/table/ag-grid';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

function TenantPage() {
    const { t } = useTranslation();
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedTenant, setSelectedTenant] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [modal, setModal] = useState(false);
    const [searchText, setSearchText] = useState('');

    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            name: selectedTenant?.name || '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required(t('error-message.tenant-name-required')),
        }),
        onSubmit: (values) => {
            const payload = {
                name: values.name,
            };
            if (isEdit && selectedTenant?.id) {
                // update tenant
                dispatch(updateTenant({ id: selectedTenant?.id, payload, toggle }));
            } else {
                // create new tenant
                dispatch(createTenant({ payload, toggle }));
            }
        },
    });

    const dispatch = useDispatch();
    const { tenants, loading } = useSelector((state) => state.tenant);

    useEffect(() => {
        dispatch(getAllTenants());
    }, [dispatch]);

    const handleDeleteTenant = () => {
        if (selectedTenant?.id) {
            dispatch(deleteTenant({ id: selectedTenant.id }));
            setDeleteModal(false);
        }
    };

    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);
            setSelectedTenant(null);
        } else {
            setModal(true);
        }
        validation.resetForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modal]);

    const handleTenantClick = useCallback(
        (arg) => {
            const isEditMode = arg ? true : false;
            setSelectedTenant(arg);
            setIsEdit(isEditMode);
            toggle();
        },
        [toggle]
    );

    const onClickDelete = (tenant) => {
        setSelectedTenant(tenant);
        setDeleteModal(true);
    };

    const [columnDefs] = useState([
        {
            headerName: t('tenant.name'),
            field: 'name',
        },
        {
            headerName: t('action.action'),
            cellRenderer: (props) => {
                const { data } = props;
                return (
                    <div className="d-flex ag-action-cell">
                        <p onClick={() => handleTenantClick(data)} className="text-primary">
                            <i className="fas fa-edit" id="edittooltip" />
                            <UncontrolledTooltip placement="top" target="edittooltip">
                                {t('action.edit')}
                            </UncontrolledTooltip>
                        </p>
                        <p onClick={() => onClickDelete(data)} className="mx-2 text-danger">
                            <i className="fas fa-trash" id="deletetooltip" />
                            <UncontrolledTooltip placement="top" target="deletetooltip">
                                {t('action.delete')}
                            </UncontrolledTooltip>
                        </p>
                    </div>
                );
            },
        },
    ]);

    return (
        <React.Fragment>
            <DeleteModal
                showModal={deleteModal}
                handleOk={handleDeleteTenant}
                handleCancel={() => setDeleteModal(false)}
            />

            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title={t('tenant.tenant')} breadcrumbItem={t('tenant.tenant')}>
                        <>
                            <Input
                                type="text"
                                className="form-control bg-light border-light rounded w-50"
                                placeholder={`${t('action.search')}...`}
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                            <Button color="primary" onClick={() => handleTenantClick()}>
                                {t('tenant.add-tenant')}
                            </Button>
                        </>
                    </Breadcrumbs>
                    <Card>
                        <Row>
                            <Col xs={12}>
                                <Grid columnDefs={columnDefs} rowData={tenants} quickFilterText={searchText} />
                            </Col>
                        </Row>
                    </Card>
                    <Modal isOpen={modal} toggle={toggle} autoFocus={false}>
                        <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? t('tenant.edit-tenant') : t('tenant.add-tenant')}
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <Row>
                                    <Col className="col-12">
                                        <div className="mb-3">
                                            <Label className="form-label">{t('tenant.name')}</Label>
                                            <Input
                                                name="name"
                                                type="text"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.name || ''}
                                                invalid={
                                                    validation.touched.name && validation.errors.name ? true : false
                                                }
                                                autoFocus
                                            />
                                            {validation.touched.name && validation.errors.name ? (
                                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <Button type="submit" color="success" disabled={loading}>
                                                {!loading && t('action.save')}
                                                {loading && <Spinner size="sm"></Spinner>}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
        </React.Fragment>
    );
}

export default TenantPage;
