import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
const { Header, Content } = Layout;

export const ProcessDetails = () => {
    const { t } = useTranslation();
    const { process: processDetail } = useSelector((state) => state.processDetails);

    return (
        <Layout>
            <Header className="info-header">{t('sidebar.process-details')}</Header>
            <Content className="info-content-container">
                <div className="d-flex">
                    <div className="info-label">{t('sidebar.name')}:</div>
                    <div>{processDetail.name}</div>
                </div>
            </Content>
        </Layout>
    );
};
