import { AgGridReact } from 'ag-grid-react';
import { useMemo, useRef } from 'react';

function Grid(props) {
    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 25vh)' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);

    const { columnDefs, rowData, quickFilterText } = props;
    const defaultColDef = {
        flex: 1,
    };

    return (
        <div style={containerStyle}>
            <div style={gridStyle} className="ag-theme-alpine">
                <AgGridReact
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    cacheQuickFilter={true}
                    quickFilterText={quickFilterText}
                />
            </div>
        </div>
    );
}

export default Grid;
