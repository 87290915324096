import { NodeResizer, useReactFlow } from 'reactflow';
import useAddLane from '../../hooks/useAddLane';
import useAddPool from '../../hooks/useAddPool';
import NodeLabel from '../../node-edge-label/label';
import ActionsToolbar from '../toolbar/actions';
import { Button } from 'antd';
import { useSelector } from 'react-redux';

function Pool({ id, selected, data }) {
    const onAddPool = useAddPool(id);
    const onAddLane = useAddLane(id);

    const { deleteElements } = useReactFlow();
    const { isInteractionDisabled } = useSelector((state) => state.processDetails);

    const onNodeAction = (action) => {
        if (action === 'delete') {
            deleteElements({ nodes: [{ id }] });
        }
    };

    return (
        <>
            <ActionsToolbar nodeId={id} onNodeAction={onNodeAction} />
            <NodeLabel placeholder={'Pool'} name={data?.name} id={id}className="pool-label" />
            <NodeResizer color="#ff0071" isVisible={selected} shouldResize={() => false} />
            <Button type="text" onClick={() => onAddPool()} className="btn-add-pool" disabled={isInteractionDisabled}>
                + Add Pool
            </Button>
            <Button type="text" onClick={() => onAddLane(id)} className="btn-add-lane" disabled={isInteractionDisabled}>
                + Add Lane
            </Button>
        </>
    );
}

export default Pool;
