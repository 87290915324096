import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const ProfileMenu = (props) => {
    const { t } = useTranslation();
    const [menu, setMenu] = useState(false);
    const [username, setusername] = useState('Admin');

    useEffect(() => {
        if (localStorage.getItem('authUser')) {
            const obj = JSON.parse(localStorage.getItem('authUser'));
            setusername(`${obj.firstName} ${obj.lastName}`);
        }
    }, [props.success]);

    return (
        <React.Fragment>
            <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
                <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                    <i className="fas fa-user"></i>
                    <span className="d-none d-xl-inline-block ms-1 fw-medium font-size-15">{username}</span>{' '}
                    <i className="fas fa-angle-down d-none d-xl-inline-block font-size-15"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem tag="a" href="/profile">
                        {' '}
                        <i className="fas fa-user-circle me-1 text-muted"></i>
                        {t('action.view-profile')}{' '}
                    </DropdownItem>
                    <div className="dropdown-divider" />
                    <Link to="/logout" className="dropdown-item">
                        <i className="fas fa-sign-out-alt me-1 text-muted"></i>
                        <span>{t('action.logout')}</span>
                    </Link>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default ProfileMenu;
